import React, { useEffect } from "react";
import ProfileNavbar from "../../components/Navbars/ProfileNavbar";
import { Box, Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import RemoveIcon from "@mui/icons-material/Remove";
import Sidebar from "../../components/Profile/Sidebar";
import { Typography } from "antd";
import { Search } from "@mui/icons-material";
import Colors from "../../helpers/Colors";
import SearchResults from "../../components/Profile/SearchResults";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, getDetails } from "../../features/auth/authSlice";
import { ClipLoader } from "react-spinners";
import { getGallery } from "../../features/gallery/gallerySlice";
import AcceptPopModal from "../../components/Profile/AcceptPopModal";
import {
  getBlacklists,
  getFavouriteList,
  getFriends,
  getLikeList,
  getRequestsRecieved,
  getRequestsSent,
  requestsRecieved,
  requestsSent,
} from "../../features/friends/friendsSlice";
import { useState } from "react";
import { getAllMessage } from "../../features/mail/mailSlice";

const numbersArray = Array.from({ length: 63 }, (_, index) => index + 18);

const Main = () => {
  const { user, isLoading, isError, message } = useSelector(
    (state) => state.auth
  );
  const [city, setCity] = useState();
  const [ageFrom, setAgeFrom] = useState(18);
  const [ageTo, setAgeTo] = useState(80);
  const [filteredCities, setFilteredCities] = useState([]);
  const { cities } = useSelector((state) => state.cities);
  const [hometownId, setHomeTownId] = useState();
  const [filter, setFilter] = useState();
  const [searcheadName, setSearchedName] = useState("");
  const [isOnlineChecked, setIsOnlineChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsOnlineChecked(event.target.checked);
  };

  const dispatch = useDispatch();

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setCity(searchTerm);

    const filteredCities = cities?.data?.filter((city) =>
      city?.englishName?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredCities(filteredCities);
  };

  const handleCitySelect = (city, id) => {
    setCity(city);
    setHomeTownId(id);
    setFilteredCities([]); // Clear the filtered cities list
  };

  const handleSearch = () => {
    setFilter({
      ageFrom,
      ageTo,
      hometownId,
    });
  };

  useEffect(() => {
    if (!user || (user && user?.data?.accptedTos === false)) {
      dispatch(getDetails());
      dispatch(getGallery());
    }
    dispatch(getAllUsers());
    dispatch(getAllMessage());
    dispatch(getFriends());
    dispatch(getRequestsSent());
    dispatch(getRequestsRecieved());
    dispatch(getBlacklists());
    dispatch(getFavouriteList());
    dispatch(getLikeList());
  }, [user]);

  return (
    <>
      <ProfileNavbar />
      {user && !user?.data?.accptedTos && <AcceptPopModal />}

      <Box className="bg-[#eae9e7] h-min-screen">
        <Box className="sm:w-[84%] m-auto w-[94%] py-12">
          {isLoading ? (
            <Box className="flex items-center h-screen flex-col justify-center">
              <ClipLoader
                color={Colors.primary2}
                loading={isLoading}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </Box>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={9}>
                {/* Menu search fields */}
                <Box className="sm:hidden block">
                  <Accordion className="bg-[#eae9e7]" disableGutters>
                    <AccordionSummary
                      expandIcon={<RemoveIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="bg-[#eae9e7]">
                      <Box className="flex items-center space-x-2">
                        <Search style={{ color: Colors.primary2 }} />
                        <Typography>אפשרויות חיפוש</Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box>
                        <Box className="bg-[#f4f2ef] p-2 flex space-x-2 sm:space-y-0 space-y-2 flex-wrap items-center justify-end">
                          <button
                            onClick={handleSearch}
                            className="py-1 px-7 shadow-md rounded-sm hover:bg-[#D71D6D] text-white"
                            style={{ background: Colors.primary2 }}>
                            מצאו סידור!
                          </button>
                          <Box className="w-[100%]">
                            <input
                              type="text"
                              value={city}
                              name="hometown"
                              onChange={handleSearchChange}
                              className="w-full sm:py-1.5 py-3 border-none px-5 text-[13px]"
                              placeholder="התחל להקליד ..."
                            />
                            {filteredCities?.length > 0 && (
                              <Box className="bg-white z-10 rounded-md absolute  p-2 items-center justify-center flex-col">
                                <ul className="space-y-2 h-[30vh] overflow-y-scroll">
                                  <li
                                    className="cursor-pointer"
                                    onClick={() => handleCitySelect("", "")}>
                                    reset
                                  </li>
                                  {filteredCities?.map((city, index) => (
                                    <li
                                      className="text-black cursor-pointer capitalize text-[12px]"
                                      key={index}
                                      onClick={() =>
                                        handleCitySelect(
                                          city.englishName,
                                          city.name
                                        )
                                      }>
                                      {city.englishName} ({city.name})
                                    </li>
                                  ))}
                                </ul>
                              </Box>
                            )}
                          </Box>
                          {/* Age to */}
                          <select
                            className="sm:w-[11%] w-[30%]"
                            name="age to"
                            onChange={(e) => setAgeTo(e.target.value)}
                            id=""
                            dir="btt">
                            {numbersArray.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <Typography>עד</Typography>
                          {/* Age From */}
                          <select
                            className="sm:w-[11%] w-[30%]"
                            name="age from"
                            onChange={(e) => setAgeFrom(e.target.value)}
                            id=""
                            dir="btt">
                            {numbersArray.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <Typography>בגילאי</Typography>
                        </Box>
                        <Box className="bg-[#f4f2ef] p-2 flex space-x-2 sm:space-y-0 space-y-2 flex-wrap items-center justify-end">
                          <Box className="flex items-center space-x-2">
                            <Typography className="-mt-1">
                              אונליין בלבד
                            </Typography>
                            <input
                              type="checkbox"
                              name="online"
                              className="sm:py-2 py-3 border-none bg-none px-5 text-[13px]"
                              placeholder="חיפוש לפי שם"
                              style={{ accentColor: Colors.primary2 }}
                              checked={isOnlineChecked}
                              onChange={handleCheckboxChange}
                            />
                          </Box>
                          <Box className="bg-white flex space-x-4 items-center px-2">
                            <Search
                              style={{
                                fontSize: "20px",
                                color: Colors.primary2,
                              }}
                            />
                            <input
                              type="text"
                              name="hometown"
                              onChange={(e) => setSearchedName(e.target.value)}
                              className="sm:w-[100%] w-[100%] sm:py-2 py-3 border-none bg-none px-5 text-[13px]"
                              placeholder="חיפוש לפי שם"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box className="sm:block hidden">
                  <Box className="bg-[#f4f2ef] p-2  flex space-x-4 sm:space-y-0 space-y-2 flex-wrap items-center justify-end">
                    <button
                      onClick={handleSearch}
                      className="py-1 px-7 shadow-md rounded-sm hover:bg-[#D71D6D] text-white"
                      style={{ background: Colors.primary2 }}>
                      מצאו סידור!
                    </button>
                    <Box className="sm:w-[20%] w-[100%]">
                      <input
                        type="text"
                        value={city}
                        name="hometown"
                        onChange={handleSearchChange}
                        className=" sm:py-1.5 py-3 border-none px-5 text-[13px]"
                        placeholder="התחל להקליד ..."
                      />
                      {filteredCities?.length > 0 && (
                        <Box className="bg-white z-10 rounded-md absolute  p-2 items-center justify-center flex-col">
                          <ul className="space-y-2 h-[30vh] overflow-y-scroll">
                            <li
                              className="cursor-pointer"
                              onClick={() => handleCitySelect("", "")}>
                              reset
                            </li>
                            {filteredCities?.map((city, index) => (
                              <li
                                className="text-black cursor-pointer capitalize text-[12px]"
                                key={index}
                                onClick={() =>
                                  handleCitySelect(city.englishName, city.id)
                                }>
                                {city.englishName} ({city.name})
                              </li>
                            ))}
                          </ul>
                        </Box>
                      )}
                    </Box>
                    <Typography>שגרים ב</Typography>
                    {/* Age to */}
                    <select
                      className="sm:w-[11%] w-[30%]"
                      name="year"
                      onChange={(e) => setAgeTo(e.target.value)}
                      id=""
                      dir="btt">
                      {numbersArray.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <Typography>עד </Typography>
                    {/* Age from*/}
                    <select
                      className="sm:w-[11%] w-[30%]"
                      name="year"
                      onChange={(e) => setAgeFrom(e.target.value)}
                      id=""
                      dir="btt">
                      {numbersArray.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <Typography>גיל מ </Typography>
                  </Box>
                  <Box className="bg-[#f4f2ef] p-2 flex space-x-2 sm:space-y-0 space-y-2 flex-wrap items-center justify-end">
                    <Box className="flex items-center space-x-2">
                      <Typography className="-mt-1">אונליין בלבד</Typography>
                      <input
                        type="checkbox"
                        name="online"
                        className="sm:py-2 py-3 border-none bg-none px-5 text-[13px]"
                        placeholder="חיפוש לפי שם"
                        style={{ accentColor: Colors.primary2 }}
                        checked={isOnlineChecked}
                        onChange={handleCheckboxChange}
                      />
                    </Box>

                    <Box className="bg-white flex space-x-4 items-center px-2">
                      <Search
                        style={{ fontSize: "20px", color: Colors.primary2 }}
                      />
                      <input
                        type="text"
                        name="hometown"
                        onChange={(e) => setSearchedName(e.target.value)}
                        className="sm:w-[100%] w-[100%] sm:py-2 py-3 border-none bg-none px-5 text-[13px]"
                        placeholder="חיפוש לפי שם"
                      />
                    </Box>
                  </Box>
                </Box>
                {/* End of Menu search fields */}

                <Box className="bg-white  p-2">
                  <SearchResults
                    filter={filter}
                    searcheadName={searcheadName}
                    isOnlineChecked={isOnlineChecked}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={3} className="sm:block hidden">
                <Sidebar />
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Main;
