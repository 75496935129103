import { Box } from "@mui/material";
import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import { Typography } from "antd";
import { CompleteRegmenuItems, menuItems } from "../../utils";
import { Link, useNavigate } from "react-router-dom";

const CompleteRegSideBar = ({ isSidebar, isMobileSideBar }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("registration");
    navigate("/");
    console.log("smmi");
  };

  return (
    <Box className="lg:h-[90vh] h-[100vh] sm:bg-[#fff] bg-[#383737]  absolute md:w-[30%] lg:w-[17%] w-[79%] top-0 right-0 overflow-y-scroll ">
      {CompleteRegmenuItems.map((item, index) => (
        <>
          {item.title == "התנתק" ? (
            <Box key={index} onClick={handleLogout} className="cursor-pointer">
              <Box className="sm:bg-[#fff] bg-[#383737] border-b border-gray-200 flex items-center px-2 py-2.5 space-x-1 justify-end">
                <Typography className="sm:text-[#000] text-white text-[14px] ">
                  {item.title}
                </Typography>
                {item.icon}
              </Box>
            </Box>
          ) : (
            <Link
              key={index}
              to={item.link}
              onClick={item.title == "" ? handleLogout : ""}
            >
              <Box className="sm:bg-[#fff] bg-[#383737] border-b border-gray-200 flex items-center px-2 py-2.5 space-x-1 justify-end">
                <Typography className="sm:text-[#000] text-white text-[14px] ">
                  {item.title}
                </Typography>
                {item.icon}
              </Box>
            </Link>
          )}
        </>
      ))}
    </Box>
  );
};

export default CompleteRegSideBar;
