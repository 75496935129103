import { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Colors from "../helpers/Colors";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function Loader({ loading, size }) {
  let [color, setColor] = useState("#ffffff");

  return (
    <div className="">
      <ClipLoader
        color={Colors.primary}
        loading={true}
        cssOverride={override}
        size={size ? size : 150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default Loader;
