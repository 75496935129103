import axios from "axios";

const API_URL = "https://api.datingnow-il.com";

const termsOfCondition = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(`${API_URL}/user/tos/accept`, "", config);

  return response.data;
};

const reportUser = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(`${API_URL}/user/report`, data, config);

  return response.data;
};

const createTicket = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(`${API_URL}/ticket`, data, config);

  return response.data;
};

const createAnonymousTicket = async (data) => {
  const response = await axios.post(`${API_URL}/ticket/anonymous`, data);

  return response.data;
};

const authService = {
  termsOfCondition,
  reportUser,
  createTicket,
  createAnonymousTicket,
};

export default authService;
