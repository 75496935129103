import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Colors from "../../helpers/Colors";
import { useFormik } from "formik";
import { ClipLoader } from "react-spinners";
import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  addGallery,
  deleteGallery,
  getGallery,
  reset,
} from "../../features/gallery/gallerySlice";
import { useState } from "react";
import { ImageViewer } from "react-image-viewer-dv";
import { Typography } from "antd";
import { DeleteOutline } from "@mui/icons-material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AddGallery() {
  const [value, setValue] = React.useState(0);
  const [isPhotos, setPhotos] = React.useState(false);
  const dispatch = useDispatch();
  const { gallery, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.gallery
  );

  const [openModal, setOpenModal] = useState(false);
  const [photoIdToDelete, setPhotoIdToDelete] = useState(null);

  const [Error, setIsError] = React.useState();
  const [success, setIsSuccess] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const validationSchema = Yup.object().shape({
    pictures: Yup.string().required("תמונות נדרשות"),
    description: Yup.string().required("תיאור נדרש"),
    privacy: Yup.string().required("פרטיות נדרשת"),
  });

  const handleDelete = (photoId) => {
    dispatch(deleteGallery(photoId));
    setOpenModal(false);
  };

  const openDeleteModal = (photoId) => {
    setPhotoIdToDelete(photoId);
    setOpenModal(true);
  };

  const closeDeleteModal = () => {
    setOpenModal(false);
  };

  const formik = useFormik({
    initialValues: {
      pictures: [],
      description: "", // New field for description
      privacy: "", // New field for privacy
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      Array.from(values.pictures).forEach((picture) => {
        formData.append("Photos", picture);
      });
      const data = {
        description: values.description,
        privacy: values.privacy,
      };

      dispatch(addGallery({ formData, data }));
    },
  });

  const handleFileChange = (event) => {
    formik.setFieldValue("pictures", event.currentTarget.files);
    setPhotos(event.currentTarget.files);
  };

  React.useEffect(() => {
    if (isError) {
      setIsError(message);
    }

    if (isSuccess) {
      window.scrollTo({ top: 50, behavior: "smooth" });
      formik.setFieldValue("pictures", []);
      setIsError("");
      setPhotos(null);
      dispatch(reset());
    }

    if (isSuccess && message === "photo deleted") {
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    }

    dispatch(reset());
  }, [isError, isSuccess, dispatch, message]);

  return (
    <Box className="relative">
      {isLoading && (
        <Box className="absolute w-full  flex items-center justify-center z-100">
          <ClipLoader
            color={Colors.primary2}
            loading={isLoading}
            size={40}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </Box>
      )}

      <Box sx={{ width: "100%" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box className="flex flex-wrap items-center justify-end">
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="flex items-center justify-end"
            >
              <Tabs
                sx={{
                  "& .MuiTab-root.Mui-selected": {
                    color: "#fff",
                    background: Colors.gray,
                    fontSize: "13px",
                    borderRadius: "5px",
                    padding: "2px 2px",
                  },
                  "& .MuiTabs-indicator": { bgcolor: "#000" },
                  "& .MuiTab-root": {
                    color: "#000",
                    fontSize: "17px",
                    borderRadius: "5px",
                  },
                }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="הוסף תמונות" {...a11yProps(1)} />
                {/* <Tab label="תמונה" {...a11yProps(1)} /> */}
                <Tab label="כל" {...a11yProps(0)} />
              </Tabs>
            </Box>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <>
              <Box className="mb-2">
                <label
                  htmlFor="picture-upload"
                  className="py-2 px-6 mb-2 rounded-md text-[14px]"
                  style={{ border: "1px solid #D71D6D", cursor: "pointer" }}
                >
                  הוסף תמונה
                </label>
                <input
                  id="picture-upload"
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  multiple
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </Box>
              <Grid container>
                <Box>
                  {Error && (
                    <Box>
                      <Typography
                        style={{ color: Colors.primary2, fontSize: "12px" }}
                      >
                        {Error}
                      </Typography>
                    </Box>
                  )}
                  {success && (
                    <Box>
                      <Typography style={{ color: "green", fontSize: "12px" }}>
                        הגלריה עודכנה בהצלחה
                      </Typography>
                    </Box>
                  )}
                </Box>
                {Array.from(formik.values.pictures).map((picture, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    key={index}
                    spacing={2}
                    className="py-2 sm:space-x-2 space-x-0 rounded-md"
                  >
                    <img
                      src={URL.createObjectURL(picture)}
                      alt={`Picture ${index}`}
                      className="rounded-md w-full"
                    />
                  </Grid>
                ))}
                {isPhotos && (
                  <>
                    <Box className="">
                      <Box>
                        <input
                          type="text"
                          name="description"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Description"
                          className="my-2 px-4 sm:py-2 py-3 border text-[12px] w-full rounded-md "
                          style={{ border: "1px solid gray" }}
                        />
                        {formik.touched.description &&
                          formik.errors.description && (
                            <h6
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: -1.5,
                              }}
                            >
                              {formik.errors.description}
                            </h6>
                          )}
                      </Box>
                      <Box>
                        <select
                          name="privacy"
                          value={formik.values.privacy}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="my-2 px-4 sm:py-2 py-4 border rounded-md w-full text-[12px]"
                          style={{ border: "1px solid gray" }}
                        >
                          <option value="" selected disabled>
                            פרטיות (מי אתה רוצה שיראה את זה)
                          </option>
                          <option value="Public">ציבורי</option>
                          <option value="Friends">חברים</option>
                          <option value="JustMe">פרטי</option>
                        </select>
                        {formik.touched.privacy && formik.errors.privacy && (
                          <h6
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: -1.5,
                            }}
                          >
                            {formik.errors.privacy}
                          </h6>
                        )}
                      </Box>
                      <Box className="mt-2">
                        <Button
                          type="submit"
                          style={{
                            background: Colors.primary2,
                            color: "#fff",
                            fontSize: "10px",
                            marginTop: 3,
                          }}
                        >
                          {isLoading ? (
                            <ClipLoader
                              color={Colors.white}
                              loading={isLoading}
                              size={10}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          ) : (
                            "סיימת!"
                          )}
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </Grid>
            </>
          </CustomTabPanel>
        </form>
        {/* <CustomTabPanel value={value} index={1}>
         
        </CustomTabPanel> */}
        <CustomTabPanel value={value} index={1}>
          <Grid container spacing={2}>
            {gallery?.length > 0 ? (
              <>
                {console.log(gallery)}
                {gallery?.map((item, index) => {
                  const img = `https://api.datingnow-il.com/${item?.url}`;

                  return (
                    <Grid item xs={6} md={4} key={index} spacing={2}>
                      <Box className="bg-white border p-2 rounded-md">
                        <Box className="h-[20vh]">
                          <ImageViewer>
                            <img
                              src={img}
                              alt="user photos"
                              className="w-full rounded-md "
                            />
                          </ImageViewer>
                        </Box>
                        <Box className="flex items-center justify-between">
                          <Box>
                            <Typography className="text-[8px] opacity-60">
                              תיאור:{" "}
                              <span className="font-bold">
                                {item?.description}
                              </span>
                            </Typography>
                            <Typography className="text-[8px] opacity-60">
                              פרטיות:{" "}
                              <span className="font-bold">
                                {item?.privacy.includes("Public")
                                  ? "ציבורי"
                                  : item?.privacy.includes("Friends")
                                  ? "חברים"
                                  : "פרטי"}
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <DeleteOutline
                              color="red"
                              style={{
                                fontSize: 15,
                                color: Colors.secondary,
                                cursor: "pointer",
                              }}
                              onClick={() => openDeleteModal(item.id)}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </>
            ) : (
              <Typography>לא הועלו תמונות</Typography>
            )}

            <Dialog open={openModal} onClose={closeDeleteModal}>
              <DialogTitle>אשר מחיקה</DialogTitle>
              <DialogContent>
                האם אתה בטוח שברצונך למחוק את התמונה הזו?
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDeleteModal} color="primary">
                  ביטול
                </Button>
                <Button
                  onClick={() => handleDelete(photoIdToDelete)}
                  color="secondary"
                >
                  מחיקה
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
