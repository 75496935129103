import React, { useEffect, useState } from "react";
import CompleteRegNavbar from "../../components/Navbars/CompleteRegNavbar";
import { Box } from "@mui/material";
import { Typography } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import Colors from "../../helpers/Colors";
import { useNavigate } from "react-router-dom";
import GeneralFooter from "../../components/Navbars/GeneralFooter";
import { useDispatch, useSelector } from "react-redux";
import { reset, update } from "../../features/auth/authSlice";
import { ClipLoader } from "react-spinners";

const validationSchema = Yup.object().shape({
  occupation: Yup.string().required("העיסוק חובה"),
  sponsor: Yup.string().required("סוג הספונסור חובה"),
  relationshipPurpose: Yup.string().required("מטרת הקשר חובה"),
  Availabilty: Yup.string().required("רמת הזמינות חובה"),
  Income: Yup.string().required("הכנסה חובה"),
  Motto: Yup.string()
    .min(10, "המוטו חייב להיות לפחות 10 תווים")
    .max(100, "המוטו יכול להכיל מקסימום 100 תווים")
    .required("הכנס מוטו"),
  AoutYou: Yup.string()
    .min(100, "התיאור על עצמך חייב להיות לפחות 100 תווים")
    .max(2000, "התיאור על עצמך יכול להכיל מקסימום 2000 תווים")
    .required("הכנס תיאור עצמי"),
  hobbies: Yup.array().min(1, "בחר לפחות תחביב אחד"),
  languages: Yup.array().min(1, "בחר לפחות שפה אחת"),
  Education: Yup.string().required("הכנס תחום לימודים"),
  BodyStructure: Yup.string().required("הכנס מבנה גוף"),
  Height: Yup.string().required("הכנס גובה"),
  HairColor: Yup.string().required("הכנס צבע שיער"),
  EyeColor: Yup.string().required("הכנס צבע עיניים"),
  Religion: Yup.string().required("הכנס דת"),
  RelationshipStatus: Yup.string().required("הכנס מצב משפחתי"),
  SmokingHabits: Yup.string().required("הכנס תורם עישון"),
  DrinkingHabits: Yup.string().required("הכנס תורם שתייה"),
  HaveChildren: Yup.boolean().required("הכנס האם יש לך ילדים"),
});

const CompleteReg = () => {
  const navigate = useNavigate();
  const [firstForm, setFirstForm] = useState(true);
  const [userData, setUserData] = useState();
  const dispatch = useDispatch();
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );
  const [error, setIsError] = useState();

  useEffect(() => {
    const fetchdata = JSON.parse(localStorage.getItem("registration"));
    if (fetchdata) {
      setUserData(fetchdata);
    } else {
      navigate("/");
    }

    if (user?.status === true) {
      navigate("/login");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      occupation: "",
      sponsor: "",
      relationshipPurpose: "",
      Availabilty: "",
      Income: "",
      Motto: "",
      AoutYou: "",
      hobbies: [],
      languages: [],
      Education: "",
      BodyStructure: "",
      Height: "",
      HairColor: "",
      EyeColor: "",
      Religion: "",
      RelationshipStatus: "",
      SmokingHabits: "",
      DrinkingHabits: "",
      HaveChildren: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = {
        username: userData.username || userData.data.username,
        email: userData.email || userData.data.email,
        motto: values.Motto,
        about: values.AoutYou,
        haveChildren: values.HaveChildren == "false" ? false : true,
        education: values.Education,
        availability: values.Availabilty,
        eyeColor: values.EyeColor,
        hairColor: values.HairColor,
        relationshipStatus: values.RelationshipStatus,
        languages: values.languages,
        religion: values.Religion,
        smokingHabit: values.SmokingHabits,
        height: values.Height,
        occupation: values.occupation,
        bodyStructure: values.BodyStructure,
        relationshipPurpose: values.relationshipPurpose,
        drinkingHabit: values.DrinkingHabits,
        income: values.Income,
        sponsorKind: values.sponsor,
        hobbies: values.hobbies,
      };

      console.log(data);

      dispatch(update(data));
    },
  });
  const handleNext = () => {
    const { errors, values } = formik;

    const fieldsWithError = [
      "occupation",
      "sponsor",
      "relationshipPurpose",
      "Availabilty",
      "Income",
      "Motto",
      "AoutYou",
      "hobbies",
      "languages",
    ];

    const hasErrors = fieldsWithError.some((field) => errors[field]);
    const allFieldsFilled = fieldsWithError.every((field) => values[field]);

    if (!hasErrors && allFieldsFilled) {
      setFirstForm(false);
    }
  };

  React.useEffect(() => {
    if (isError) {
      setIsError(message);
    }

    if (isSuccess && user?.status == true) {
      dispatch(reset());
      navigate("/login", {});
    }

    if (isSuccess && user?.status == false) {
      setIsError(user?.message);
    }

    dispatch(reset());
  }, [isError, isSuccess, dispatch, message]);

  return (
    <>
      <CompleteRegNavbar />
      <Box className="h-min-screen h-min-screen sm:py-10 py-16  verifyScreen">
        <Box className="sm:w-[45%] w-[90%] m-auto bg-[#eae9e7]">
          <Box className="bg-white p-2 text-center">
            <Typography className="font-bold text-[13px]">
              השקיעו 30 שניות להשלמת הפרטים, ותזכו להופיע בתוצאות החיפוש!
            </Typography>
            {error && (
              <Typography className="text-[11px] text-red-500 my-2">
                {error}
              </Typography>
            )}
          </Box>
          <Box
            className="mt-5 p-4 space-y-3"
            component="form"
            onSubmit={formik.handleSubmit}
          >
            {firstForm ? (
              <Box className="space-y-3">
                <div className="relative">
                  <select
                    name="occupation"
                    id=""
                    className="w-full text-[13px]"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.occupation}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * העיסוק שלי
                    </option>
                    <option value="Advocate">עו״ד</option>
                    <option value="Doctor">רופא</option>
                    <option value="BusinessMan">איש עסקים</option>
                    <option value="RealEstate">נדל״ן</option>
                    <option value="Engineer">מהנדס</option>
                    <option value="InsuranceAgent">סוכן ביטוח</option>
                    <option value="PermanentPersonnel">איש קבע</option>
                    <option value="Sales">מכירות</option>
                    <option value="Politics">פוליטיקה</option>
                    <option value="Professional">בעל מקצוע</option>
                    <option value="StockExchange">בורסה</option>
                    <option value="HiTech">היי-טק</option>
                  </select>
                  {formik.touched.occupation && formik.errors.occupation ? (
                    <span className=" text-[12px] " style={{ color: "red" }}>
                      (*) {formik.errors.occupation}
                    </span>
                  ) : null}
                </div>

                {/* sponsor field */}
                <div className="relative">
                  <select
                    name="sponsor"
                    id=""
                    className="w-full text-[13px]"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sponsor}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * איזה סוג ספונסר/ית אני?
                    </option>
                    <option value="Basic">
                      צרכים בסיסיים כתשלום שכ״ד וכו׳
                    </option>
                    <option value="BasicAndGifts">
                      צרכים בסיסיים + מתנות{" "}
                    </option>
                    <option value="Philanthropist">נדבני</option>
                    <option value="Extravagant">אורח חיים בזבזני</option>
                  </select>
                  {formik.touched.sponsor && formik.errors.sponsor ? (
                    <span className=" text-[12px] " style={{ color: "red" }}>
                      (*) {formik.errors.sponsor}
                    </span>
                  ) : null}
                </div>

                {/* Relationship purpose */}
                <div className="relative">
                  <select
                    name="relationshipPurpose"
                    id=""
                    className="w-full text-[13px]"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.relationshipPurpose}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * מטרת הקשר
                    </option>
                    <option value="Flowing">זורם/ת. לאן שנגיע-נגיע</option>
                    <option value="Friendship">חברות, מתנות ובילויים </option>
                    <option value="Connection">מוגבל לקשר בלבד</option>
                    <option value="Marriage">קשר למטרת נישואין</option>
                  </select>
                  {formik.touched.relationshipPurpose &&
                  formik.errors.relationshipPurpose ? (
                    <span className=" text-[12px] " style={{ color: "red" }}>
                      (*) {formik.errors.relationshipPurpose}
                    </span>
                  ) : null}
                </div>

                {/* Availabilty  */}
                <div className="relative">
                  <select
                    name="Availabilty"
                    id=""
                    className="w-full text-[13px]"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Availabilty}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * רמת הזמינות שלי
                    </option>
                    <option value="Mornings">בקרים</option>
                    <option value="AllDay">כל היום </option>
                    <option value="Evening">שעות הערב</option>
                    <option value="Weekends">סופ״שים</option>
                  </select>
                  {formik.touched.Availabilty && formik.errors.Availabilty ? (
                    <span className=" text-[12px] " style={{ color: "red" }}>
                      (*) {formik.errors.Availabilty}
                    </span>
                  ) : null}
                </div>

                {/* Income  */}
                <div className="relative">
                  <select
                    name="Income"
                    id=""
                    className="w-full text-[13px]"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Income}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * ההכנסה שלי
                    </option>
                    <option value="K_10_Or_Less">₪ 10,000 או פחות</option>
                    <option value="K_10_15">₪10,000-15,000 </option>
                    <option value="K_15_30">₪15,000-30,000 </option>
                    <option value="K_30_More">יותר מ- 30,000₪</option>

                    <option value="VeryRich">אני מאוד עשיר/ה</option>
                  </select>
                  {formik.touched.Income && formik.errors.Income ? (
                    <span className=" text-[12px] " style={{ color: "red" }}>
                      (*) {formik.errors.Income}
                    </span>
                  ) : null}
                </div>

                {/* Motto input */}
                <div>
                  <input
                    type="text"
                    name="Motto"
                    className="w-full text-[13px] px-4 py-1.5 text-[13px] rounded-md outline-none"
                    placeholder="* מוטו בחיים"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Motto}
                  />
                  {formik.touched.Motto && formik.errors.Motto ? (
                    <span className=" text-[12px] " style={{ color: "red" }}>
                      (*) {formik.errors.Motto}
                    </span>
                  ) : null}
                </div>

                {/* Textarea for AoutYou */}
                <div>
                  <textarea
                    name="AoutYou"
                    id=""
                    cols="30"
                    rows="3"
                    className="px-5 py-2 text-[13px] w-full rounded-md"
                    placeholder="* קצת עלי - מה החבר הכי טוב שלכם יספר עליכם (הרשו לעצמכם להתרברב אנחנו לא נישפוט :-) )"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.AoutYou}
                  ></textarea>
                  {formik.touched.AoutYou && formik.errors.AoutYou ? (
                    <span className=" text-[12px] " style={{ color: "red" }}>
                      (*) {formik.errors.AoutYou}
                    </span>
                  ) : null}
                </div>
                {/* Checkboxes for hobbies */}
                <div>
                  <Typography className="text-[15px] font-semibold text-gray-500">
                    * תחביבים:
                  </Typography>
                  <div className="grid grid-cols-1 mt-2 md:grid-cols-2 lg:grid-cols-3 gap-y-2">
                    {[
                      "Trekking",
                      "Internet",
                      "FlightsAndTripsAbroad",
                      "Sport",
                      "SexAndErotica",
                      "Cinema",
                      "Sailing",
                      "Tracks",
                      "Politics",
                      "LuxuryCars",
                      "Clubbing",
                    ].map((hobby, index) => (
                      <label
                        key={index}
                        className="text-[11px] flex items-center space-x-2"
                      >
                        <input
                          type="checkbox"
                          name="hobbies"
                          style={{ accentColor: Colors.primary2, fontSize: 14 }}
                          value={hobby}
                          checked={formik.values.hobbies.includes(hobby)}
                          onChange={formik.handleChange}
                        />
                        <span>{hobby}</span>
                      </label>
                    ))}
                  </div>
                  {formik.touched.hobbies && formik.errors.hobbies ? (
                    <span className=" text-[12px] " style={{ color: "red" }}>
                      (*) {formik.errors.hobbies}
                    </span>
                  ) : null}
                </div>

                {/* Checkboxes for languages */}
                <div>
                  <Typography className="text-[15px] font-semibold text-gray-500">
                    * שפות:
                  </Typography>
                  <div className="grid grid-cols-1 mt-2 md:grid-cols-2 lg:grid-cols-3 gap-y-2">
                    {[
                      "English",
                      "French",
                      "Hebrew",
                      "Russian",
                      "Spanish",
                      "Arabic",
                      "Other",
                    ].map((language, index) => (
                      <label
                        key={index}
                        className="text-[11px] flex items-center space-x-2"
                      >
                        <input
                          type="checkbox"
                          name="languages"
                          style={{ accentColor: Colors.primary2 }}
                          value={language}
                          checked={formik.values.languages.includes(language)}
                          onChange={formik.handleChange}
                        />
                        <span>{language}</span>
                      </label>
                    ))}
                  </div>
                  {formik.touched.languages && formik.errors.languages ? (
                    <span className=" text-[12px] " style={{ color: "red" }}>
                      (*) {formik.errors.languages}
                    </span>
                  ) : null}
                </div>

                {/* Submit button */}
                <button
                  type=""
                  onClick={handleNext}
                  className="w-full py-2 mt-5 rounded-md px-4"
                  style={{ background: Colors.primary2 }}
                >
                  המשך הרישום
                </button>
              </Box>
            ) : (
              <>
                <Box className="gap-4 grid grid-cols-2 grid-cols-2">
                  {/* Education */}
                  <div className="relative">
                    <select
                      name="Education"
                      id=""
                      className="py-2.5 w-full text-[13px]"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Education}
                    >
                      <option
                        value=""
                        selected
                        disabled
                        className="text-gray-400"
                      >
                        * השכלה
                      </option>
                      <option value="HighSchool">תיכון</option>
                      <option value="College">מכללה</option>
                      <option value="Student">סטודנט/ית</option>
                      <option value="BachelorDegree">תואר ראשון</option>
                      <option value="CollegeDegree">תואר מכללה</option>
                      <option value="Masters">תואר שני </option>
                      <option value="PhD">תואר שלישי </option>
                    </select>
                    {formik.touched.Education && formik.errors.Education ? (
                      <span className=" text-[12px] " style={{ color: "red" }}>
                        (*) {formik.errors.Education}
                      </span>
                    ) : null}
                  </div>

                  {/* Body Structure */}
                  <div className="relative">
                    <select
                      name="BodyStructure"
                      id=""
                      className="py-2.5 w-full text-[13px]"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.BodyStructure}
                    >
                      <option
                        value=""
                        selected
                        disabled
                        className="text-gray-400"
                      >
                        * מבנה גוף
                      </option>
                      <option value="Thin">רזה</option>
                      <option value="Average">ממוצע</option>
                      <option value="Athletic">אתלטי/ית</option>
                      <option value="Shapely">חטוב/ה </option>
                      <option value="Muscular">שרירי/ת</option>
                      <option value="ExtraKills">יש מספר קיל׳ עודפים </option>
                      <option value="GrowingAndLoving"> גדול/ה ואוהב/ת </option>
                    </select>
                    {formik.touched.BodyStructure &&
                    formik.errors.BodyStructure ? (
                      <span className=" text-[12px] " style={{ color: "red" }}>
                        (*) {formik.errors.BodyStructure}
                      </span>
                    ) : null}
                  </div>

                  {/* Height */}
                  <div className="relative">
                    <select
                      name="Height"
                      id=""
                      className="py-2.5 w-full text-[13px]"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Height}
                    >
                      <option
                        value=""
                        selected
                        disabled
                        className="text-gray-400"
                      >
                        * גובה
                      </option>
                      <option value="CM_140_Below">ס״מ ומטה 140</option>
                      <option value="CM_141_145">141-145 ס״מ</option>
                      <option value="CM_146_150">146-150 ס״מ</option>
                      <option value="CM_151_155">151-155 ס״מ </option>
                      <option value="CM_156_160">156-160 ס״מ</option>
                      <option value="CM_161_165">161-165 ס״מ </option>
                      <option value="CM_166_170"> 166-170 ס״מ </option>
                      <option value="CM_171_175">171-175 ס״מ </option>
                      <option value="CM_176_180">176-180 ס״מ</option>
                      <option value="CM_181_185">181-185 ס״מ</option>
                      <option value="CM_186_190">186-190 ס״מ</option>
                      <option value="CM_191_Above">191 ס״מ או יותר </option>
                    </select>
                    {formik.touched.Height && formik.errors.Height ? (
                      <span className=" text-[12px] " style={{ color: "red" }}>
                        (*) {formik.errors.Height}
                      </span>
                    ) : null}
                  </div>

                  {/* HairColor */}
                  <div className="relative">
                    <select
                      name="HairColor"
                      id=""
                      className="py-2.5 w-full text-[13px]"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.HairColor}
                    >
                      <option
                        value=""
                        selected
                        disabled
                        className="text-gray-400"
                      >
                        * שיער
                      </option>
                      <option value="Black">שחור</option>
                      <option value="Brown">חום </option>
                      <option value="Auburn">חום בהיר</option>
                      <option value="Red"> ג׳ינג׳י </option>
                      <option value="Blond">בלונדיני</option>
                      <option value="White">לבן/אפור</option>
                      <option value="Chestnut">קירח </option>
                      <option value="Other">אחר</option>
                    </select>
                    {formik.touched.HairColor && formik.errors.HairColor ? (
                      <span className=" text-[12px] " style={{ color: "red" }}>
                        (*) {formik.errors.HairColor}
                      </span>
                    ) : null}
                  </div>

                  {/* EyeColor */}

                  <div className="relative">
                    <select
                      name="EyeColor"
                      id=""
                      className="py-2.5 w-full text-[13px]"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.EyeColor}
                    >
                      <option
                        value=""
                        selected
                        disabled
                        className="text-gray-400"
                      >
                        * צבע עיניים
                      </option>
                      <option value="Brown"> שחור</option>
                      <option value="Blue">כחול </option>
                      <option value="Hazel">חום </option>
                      <option value="Green">ירוק </option>
                      <option value="Gray">אפור </option>
                    </select>
                    {formik.touched.EyeColor && formik.errors.EyeColor ? (
                      <span className=" text-[12px] " style={{ color: "red" }}>
                        (*) {formik.errors.EyeColor}
                      </span>
                    ) : null}
                  </div>

                  {/* Religion */}
                  <div className="relative">
                    <select
                      name="Religion"
                      id=""
                      className="py-2.5 w-full text-[13px]"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Religion}
                    >
                      <option
                        value=""
                        selected
                        disabled
                        className="text-gray-400"
                      >
                        * דת
                      </option>
                      <option value="Jew">יהודי</option>
                      <option value="Secular">חילוני</option>
                      <option value="Christian">נוצרי</option>
                      <option value="Messianic">משיחי </option>
                      <option value="Ransom">כופר </option>
                      <option value="Muslim">מוסלמי </option>
                      <option value="Buddhist">בודהיסט </option>
                      <option value="Catholic">קתולי </option>
                      <option value="Orthodox">אורטודוקסי</option>
                      <option value="Atheism">אתאיזם</option>
                      <option value="Other">אחר</option>
                    </select>
                    {formik.touched.Religion && formik.errors.Religion ? (
                      <span className=" text-[12px] " style={{ color: "red" }}>
                        (*) {formik.errors.Religion}
                      </span>
                    ) : null}
                  </div>

                  {/* Relationship Status */}
                  <div className="relative">
                    <select
                      name="RelationshipStatus"
                      id=""
                      className="py-2.5 w-full text-[13px]"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.RelationshipStatus}
                    >
                      <option
                        value=""
                        selected
                        disabled
                        className="text-gray-400"
                      >
                        * סטטוס משפחתי
                      </option>
                      <option value="Single">רווק/ה</option>
                      <option value="Divorced">גרוש/ה</option>
                      <option value="Widower">אלמן/ה</option>
                      <option value="HappilyMarried">נשוי/אה באושר </option>
                      <option value="Dating">נשוי/אה אך מחפש/ת ריגושים</option>
                      <option value="Complicated">פרוד/ה</option>
                      <option value="OpenRelationship">יחסים פתוחים</option>
                      <option value="Other">אחר</option>
                    </select>
                    {formik.touched.RelationshipStatus &&
                    formik.errors.RelationshipStatus ? (
                      <span className=" text-[12px] " style={{ color: "red" }}>
                        (*) {formik.errors.RelationshipStatus}
                      </span>
                    ) : null}
                  </div>

                  {/* SmokingHabits */}
                  <div className="relative">
                    <select
                      name="SmokingHabits"
                      id=""
                      className="py-2.5 w-full text-[13px]"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.SmokingHabits}
                    >
                      <option
                        value=""
                        selected
                        disabled
                        className="text-gray-400"
                      >
                        * עישון
                      </option>
                      <option value="Never">אף פעם</option>
                      <option value="Smoker">מעשן/ת</option>
                      <option value="Company">בחברה</option>
                      <option value="HereAndThere">פה ושם </option>
                      <option value="TryingToStop">מנסה להפסיק</option>
                      <option value="Stopped">הפסקתי </option>
                    </select>
                    {formik.touched.SmokingHabits &&
                    formik.errors.SmokingHabits ? (
                      <span className=" text-[12px] " style={{ color: "red" }}>
                        (*) {formik.errors.SmokingHabits}
                      </span>
                    ) : null}
                  </div>

                  {/* DrinkingHabits */}
                  <div className="relative">
                    <select
                      name="DrinkingHabits"
                      id=""
                      className="py-2.5 w-full text-[13px]"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.DrinkingHabits}
                    >
                      <option
                        value=""
                        selected
                        disabled
                        className="text-gray-400"
                      >
                        * עישון
                      </option>
                      <option value="Never">אף פעם</option>
                      <option value="Rarely">בנדידות</option>
                      <option value="Company">בחברה</option>
                      <option value="Often">פה ושם </option>
                      <option value="TryingToStop">מנסה להפסיק</option>
                      <option value="Stopped">הפסקתי </option>
                    </select>
                    {formik.touched.DrinkingHabits &&
                    formik.errors.DrinkingHabits ? (
                      <span className=" text-[12px] " style={{ color: "red" }}>
                        (*) {formik.errors.DrinkingHabits}
                      </span>
                    ) : null}
                  </div>

                  {/* HaveChildren */}
                  <div className="relative">
                    <select
                      name="HaveChildren"
                      id=""
                      className="py-2.5 w-full text-[13px]"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.HaveChildren}
                    >
                      <option
                        value=""
                        selected
                        disabled
                        className="text-gray-400"
                      >
                        * האם יש לך ילדים?
                      </option>
                      <option value="true">כן</option>
                      <option value="false">לא</option>
                    </select>
                    {formik.touched.HaveChildren &&
                    formik.errors.HaveChildren ? (
                      <span className=" text-[12px] " style={{ color: "red" }}>
                        (*) {formik.errors.HaveChildren}
                      </span>
                    ) : null}
                  </div>
                </Box>
                {/* Submit button */}
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full py-2 mt-5 rounded-md px-4"
                  style={{ background: Colors.primary2 }}
                >
                  {isLoading ? (
                    <ClipLoader
                      color={Colors.white}
                      loading={isLoading}
                      size={10}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  ) : (
                    " סיימת!"
                  )}
                </button>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <GeneralFooter />
    </>
  );
};

export default CompleteReg;
