export const TranslateGender = [
  {
    hebrew: "גבר",
    english: "Male",
  },
  {
    hebrew: "אני",
    english: "Female",
  },
];

export const TranslateGenderInterest = [
  {
    hebrew: "נשים",
    english: "Women",
  },
  {
    hebrew: "גברים",
    english: "Men",
  },
  {
    hebrew: "שניהם",
    english: "Both",
  },
];

export const occupation = [
  { english: "Advocate", hebrew: "עורך דין" },
  { english: "Doctor", hebrew: "רופא" },
  { english: "BusinessMan", hebrew: "איש עסקים" },
  { english: "RealEstate", hebrew: "נדל" },
  { english: "Engineer", hebrew: "מהנדס" },
  { english: "InsuranceAgent", hebrew: "סוכן ביטוח" },
  { english: "PermanentPersonnel", hebrew: "קבע" },
  { english: "Sales", hebrew: "מכירות" },
  { english: "Politics", hebrew: "פוליטיקה" },
  { english: "Professional", hebrew: "מקצועי" },
  { english: "StockExchange", hebrew: "בורסה" },
  { english: "HiTech", hebrew: "הייטק" },
];

export const sponsorKinds = [
  { english: "Basic", hebrew: "בסיסי" },
  { english: "BasicAndGifts", hebrew: "בסיסי ומתנות" },
  { english: "Philanthropist", hebrew: "פילנתרופי" },
  { english: "Extravagant", hebrew: "שווא" },
];

export const relationshipPurpose = [
  { english: "Flowing", hebrew: "זורמת" },
  { english: "Friendship", hebrew: "חברות" },
  { english: "Connection", hebrew: "חיבור" },
  { english: "Marriage", hebrew: "נישואים" },
];

export const availabilityOptions = [
  { english: "Mornings", hebrew: "בוקר" },
  { english: "AllDay", hebrew: "כל היום" },
  { english: "Evening", hebrew: "ערב" },
  { english: "Weekends", hebrew: "סופי שבוע" },
];

export const income = [
  { english: "K_10_Or_Less", hebrew: "עד ₪10,000" },
  { english: "K_10_15", hebrew: "₪10,000-₪15,000" },
  { english: "K_15_30", hebrew: "₪15,000-₪30,000" },
  { english: "K_30_More", hebrew: "₪30,000 ומעלה" },
  { english: "VeryRich", hebrew: "עשיר מאוד" },
];

export const hobbies = [
  { english: "Trekking", hebrew: "טיולי טרקים" },
  { english: "Internet", hebrew: "גלישה ברשת" },
  { english: "FlightsAndTripsAbroad", hebrew: "טיסות וטיולים לחו" },
  { english: "Sport", hebrew: "פעילות ספורטיבית" },
  { english: "SexAndErotica", hebrew: "דיבורים על תשוקה" },
  { english: "Cinema", hebrew: "צפייה בסרטים" },
  { english: "Sailing", hebrew: "שייט" },
  { english: "Tracks", hebrew: "רכיבה על אופניים" },
  { english: "Politics", hebrew: "פעילות פוליטית" },
  { english: "LuxuryCars", hebrew: "רכבים יוקרתיים" },
  { english: "Clubbing", hebrew: "חיי לילה" },
];

export const educationLevels = [
  { english: "HighSchool", hebrew: "תיכון" },
  { english: "College", hebrew: "קולג' " },
  { english: "Student", hebrew: "סטודנט" },
  { english: "BachelorDegree", hebrew: "תואר ראשון" },
  { english: "CollegeDegree", hebrew: "תואר עם" },
  { english: "Masters", hebrew: "תואר מאסטר" },
  { english: "PhD", hebrew: "דוקטורט" },
];

export const bodyTypes = [
  { english: "Thin", hebrew: "רזה" },
  { english: "Average", hebrew: "ממוצע" },
  { english: "Athletic", hebrew: "ספורטיבי" },
  { english: "Shapely", hebrew: "מעוצב" },
  { english: "Muscular", hebrew: "שרירי" },
  { english: "ExtraKills", hebrew: "עם משקולות נוספות" },
  { english: "GrowingAndLoving", hebrew: "גובר ואוהב" },
];

export const heightRanges = [
  { english: "CM_140_Below", hebrew: "140 סמ ומטה" },
  { english: "CM_141_145", hebrew: "141 עד 145 סמ" },
  { english: "CM_146_150", hebrew: "146 עד 150 סמ" },
  { english: "CM_151_155", hebrew: "151 עד 155 סמ" },
  { english: "CM_156_160", hebrew: "156 עד 160 סמ" },
  { english: "CM_161_165", hebrew: "161 עד 165 סמ" },
  { english: "CM_166_170", hebrew: "166 עד 170 סמ" },
  { english: "CM_171_175", hebrew: "171 עד 175 סמ" },
  { english: "CM_176_180", hebrew: "176 עד 180 סמ" },
  { english: "CM_181_185", hebrew: "181 עד 185 סמ" },
  { english: "CM_186_190", hebrew: "186 עד 190 סמ" },
  { english: "CM_191_Above", hebrew: "191 סמ ומעלה" },
];

export const hairColors = [
  { english: "Black", hebrew: "שחור" },
  { english: "Brown", hebrew: "חום" },
  { english: "Auburn", hebrew: "אדמדם" },
  { english: "Red", hebrew: "אדום" },
  { english: "Blond", hebrew: "בלונד" },
  { english: "White", hebrew: "לבן" },
  { english: "Gray", hebrew: "אפור" },
  { english: "Chestnut", hebrew: "כתום עץ" },
  { english: "Other", hebrew: "אחר" },
];

export const eyeColors = [
  { english: "Brown", hebrew: "חום" },
  { english: "Blue", hebrew: "כחול" },
  { english: "Green", hebrew: "ירוק" },
  { english: "Hazel", hebrew: "בצלזל" },
  { english: "Gray", hebrew: "אפור" },
  { english: "Amber", hebrew: "אמבר" },
];

export const relationshipStatuses = [
  { english: "Single", hebrew: "רווק/ה" },
  { english: "Dating", hebrew: "בתקופת היכרויות" },
  { english: "Complicated", hebrew: "מורכב" },
  { english: "HappilyMarried", hebrew: "נשוי/ה באושר" },
  { english: "Divorced", hebrew: "גרוש/ה" },
  { english: "OpenRelationship", hebrew: "קשר פתוח" },
  { english: "Widower", hebrew: "אלמן/ה" },
  { english: "Other", hebrew: "אחר" },
];

export const smokingStatuses = [
  { english: "Never", hebrew: "לעולם לא" },
  { english: "Smoker", hebrew: "מעשן/ת" },
  { english: "Company", hebrew: "מעשן בחברת אחרים" },
  { english: "HereAndThere", hebrew: "מעשן פה ושם" },
  { english: "TryingToStop", hebrew: "מנסה להפסיק" },
  { english: "Stopped", hebrew: "הפסיק/ה" },
];

export const drinkingStatuses = [
  { english: "Never", hebrew: "לעולם לא" },
  { english: "Company", hebrew: "בחברת אחרים" },
  { english: "Rarely", hebrew: "בנדיבות" },
  { english: "Often", hebrew: "בתדירות" },
  { english: "TryingToStop", hebrew: "מנסה להפסיק" },
  { english: "Stopped", hebrew: "הפסיק/ה" },
];

export const hasChildren = [
  { english: true, hebrew: "כן" },
  { english: false, hebrew: "לא" },
];

export const ticketType = [
  { english: "TechnicalSupport", hebrew: "תמיכה טכנית" },
  { english: "CancelSubscription", hebrew: "ביטול מנוי" },
  { english: "Report", hebrew: "דיווח" },
  { english: "CustomerService", hebrew: "שירות לקוחות" },
];
