import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Lock } from "@mui/icons-material";
import AddGallery from "../AddGallery";
import { Typography } from "antd";
import Colors from "../../../helpers/Colors";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { reset, updateAboutInfo } from "../../../features/auth/authSlice";
import { ClipLoader } from "react-spinners";

const validationSchema = yup.object({
  Education: yup.string().required("Education is required"),
  BodyStructure: yup.string().required("Body Structure is required"),
  Height: yup.string().required("Height is required"),
  HairColor: yup.string().required("Hair Color is required"),
  EyeColor: yup.string().required("Eye Color is required"),
  Religion: yup.string().required("Religion is required"),
  RelationshipStatus: yup.string().required("Relationship Status is required"),
  SmokingHabits: yup.string().required("Smoking Habits is required"),
  DrinkingHabits: yup.string().required("Drinking Habits is required"),
  HaveChildren: yup.string().required("Have Children is required"),
  bdsmRelation: yup.string().required("BDSM Relation is required"),
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Pestle() {
  const { user, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.auth
  );
  const [Error, setError] = React.useState();
  const [Success, setSuccess] = React.useState();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formik = useFormik({
    initialValues: {
      Education: user?.data?.education || "",
      BodyStructure: user?.data?.bodyStructure || "",
      Height: user?.data?.height || "",
      HairColor: user?.data?.hairColor || "",
      EyeColor: user?.data?.eyeColor || "",
      Religion: user?.data?.religion || "",
      RelationshipStatus: user?.data?.relationshipStatus || "",
      SmokingHabits: user?.data?.smokingHabit || "",
      DrinkingHabits: user?.data?.drinkingHabit || "",
      HaveChildren: user?.data?.haveChildren ? "true" : "false" || "",
      bdsmRelation: user?.data?.bdsmRelation || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const data = {
        education: values.Education,
        bodyStructure: values.BodyStructure,
        height: values.Height,
        hairColor: values.HairColor,
        eyeColor: values.EyeColor,
        religion: values.Religion,
        relationshipStatus: values.RelationshipStatus,
        smokingHabit: values.SmokingHabits,
        drinkingHabit: values.DrinkingHabits,
        bdsmRelation: values.bdsmRelation,
        haveChildren: true,
      };
      dispatch(updateAboutInfo(data));
    },
  });

  React.useEffect(() => {
    if (isError) {
      setError(message);
    }

    if (isSuccess && message === "Account Info Updated Successfully") {
      setError("");
      setSuccess("מידע החשבון עודכן בהצלחה");
      setInterval(() => {
        setSuccess("");
        dispatch(reset());
      }, 3000);
    }

    dispatch(reset());

    // Cleanup function
    return () => {
      // Reset state on component unmount as well
      dispatch(reset());
    };
  }, [isError, isSuccess, dispatch, message]);

  return (
    <Box className="px-1 w-full">
      <Box sx={{ width: "100%" }} className="py-4">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
          className="flex  items-center justify-end"
        >
          <Tabs
            value={value}
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: "#fff",
                background: "#000",
                fontSize: "17px",
                borderRadius: "5px",
                padding: "2px 2px",
              },
              "& .MuiTabs-indicator": { bgcolor: "#000" },
              "& .MuiTab-root": {
                color: "#000",

                fontSize: "17px",
                borderRadius: "5px",
              },
            }}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="פרופיל" {...a11yProps(1)} />
            <Tab label="גלריה" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box className="bg-white sm:p-4 p-2">
            <form onSubmit={formik.handleSubmit}>
              <Box className="gap-4 grid grid-cols-2 grid-cols-2">
                {/* Education */}
                <div className="relative">
                  <select
                    name="Education"
                    id=""
                    className="py-2.5 w-full text-[13px]"
                    style={{ border: "1px solid gray" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Education}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * השכלה
                    </option>
                    <option value="HighSchool">תיכון</option>
                    <option value="College">מכללה</option>
                    <option value="Student">סטודנט/ית</option>
                    <option value="BachelorDegree">תואר ראשון</option>
                    <option value="CollegeDegree">תואר מכללה</option>
                    <option value="Masters">תואר שני </option>
                    <option value="PhD">תואר שלישי </option>
                  </select>
                  {formik.touched.Education && formik.errors.Education ? (
                    <span className=" text-[9px] " style={{ color: "red" }}>
                      (*) {formik.errors.Education}
                    </span>
                  ) : null}
                </div>

                {/* Body Structure */}
                <div className="relative">
                  <select
                    name="BodyStructure"
                    id=""
                    className="py-2.5 w-full text-[13px]"
                    style={{ border: "1px solid gray" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.BodyStructure}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * מבנה גוף
                    </option>
                    <option value="Thin">רזה</option>
                    <option value="Average">ממוצע</option>
                    <option value="Athletic">אתלטי/ית</option>
                    <option value="Shapely">חטוב/ה </option>
                    <option value="Muscular">שרירי/ת</option>
                    <option value="ExtraKills">יש מספר קיל׳ עודפים </option>
                    <option value="GrowingAndLoving"> גדול/ה ואוהב/ת </option>
                  </select>
                  {formik.touched.BodyStructure &&
                  formik.errors.BodyStructure ? (
                    <span className=" text-[9px] " style={{ color: "red" }}>
                      (*) {formik.errors.BodyStructure}
                    </span>
                  ) : null}
                </div>

                {/* Body Structure */}
                <div className="relative">
                  <select
                    name="bdsmRelation"
                    id=""
                    className="py-2.5 w-full text-[13px]"
                    style={{ border: "1px solid gray" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.bdsmRelation}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * מבנה גוף
                    </option>
                    <option value="NotAboutIT ">לא על IT</option>
                    <option value="OpenToSuggestion "> פתוח להצעות</option>
                    <option value="AboutExtra ">על נוסף</option>
                    <option value="About "> אני בקשר אליו </option>
                  </select>
                  {formik.touched.bdsmRelation && formik.errors.bdsmRelation ? (
                    <span className=" text-[9px] " style={{ color: "red" }}>
                      (*) {formik.errors.bdsmRelation}
                    </span>
                  ) : null}
                </div>

                {/* Height */}
                <div className="relative">
                  <select
                    name="Height"
                    id=""
                    className="py-2.5 w-full text-[13px]"
                    style={{ border: "1px solid gray" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Height}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * גובה
                    </option>
                    <option value="CM_140_Below">ס״מ ומטה 140</option>
                    <option value="CM_141_145">141-145 ס״מ</option>
                    <option value="CM_146_150">146-150 ס״מ</option>
                    <option value="CM_151_155">151-155 ס״מ </option>
                    <option value="CM_156_160">156-160 ס״מ</option>
                    <option value="CM_161_165">161-165 ס״מ </option>
                    <option value="CM_166_170"> 166-170 ס״מ </option>
                    <option value="CM_171_175">171-175 ס״מ </option>
                    <option value="CM_176_180">176-180 ס״מ</option>
                    <option value="CM_181_185">181-185 ס״מ</option>
                    <option value="CM_186_190">186-190 ס״מ</option>
                    <option value="CM_191_Above">191 ס״מ או יותר </option>
                  </select>
                  {formik.touched.Height && formik.errors.Height ? (
                    <span className=" text-[9px] " style={{ color: "red" }}>
                      (*) {formik.errors.Height}
                    </span>
                  ) : null}
                </div>

                {/* HairColor */}
                <div className="relative">
                  <select
                    name="HairColor"
                    id=""
                    className="py-2.5 w-full text-[13px]"
                    style={{ border: "1px solid gray" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.HairColor}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * שיער
                    </option>
                    <option value="Black">שחור</option>
                    <option value="Brown">חום </option>
                    <option value="Auburn">חום בהיר</option>
                    <option value="Red"> ג׳ינג׳י </option>
                    <option value="Blond">בלונדיני</option>
                    <option value="White">לבן/אפור</option>
                    <option value="Chestnut">קירח </option>
                    <option value="Other">אחר</option>
                  </select>
                  {formik.touched.HairColor && formik.errors.HairColor ? (
                    <span className=" text-[9px] " style={{ color: "red" }}>
                      (*) {formik.errors.HairColor}
                    </span>
                  ) : null}
                </div>

                {/* EyeColor */}

                <div className="relative">
                  <select
                    name="EyeColor"
                    id=""
                    className="py-2.5 w-full text-[13px]"
                    style={{ border: "1px solid gray" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.EyeColor}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * צבע עיניים
                    </option>
                    <option value="Brown"> שחור</option>
                    <option value="Blue">כחול </option>
                    <option value="Hazel">חום </option>
                    <option value="Green">ירוק </option>
                    <option value="Gray">אפור </option>
                  </select>
                  {formik.touched.EyeColor && formik.errors.EyeColor ? (
                    <span className=" text-[9px] " style={{ color: "red" }}>
                      (*) {formik.errors.EyeColor}
                    </span>
                  ) : null}
                </div>

                {/* Religion */}
                <div className="relative">
                  <select
                    name="Religion"
                    id=""
                    className="py-2.5 w-full text-[13px]"
                    style={{ border: "1px solid gray" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Religion}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * דת
                    </option>
                    <option value="Jew">יהודי</option>
                    <option value="Secular">חילוני</option>
                    <option value="Christian">נוצרי</option>
                    <option value="Messianic">משיחי </option>
                    <option value="Ransom">כופר </option>
                    <option value="Muslim">מוסלמי </option>
                    <option value="Buddhist">בודהיסט </option>
                    <option value="Catholic">קתולי </option>
                    <option value="Orthodox">אורטודוקסי</option>
                    <option value="Atheism">אתאיזם</option>
                    <option value="Other">אחר</option>
                  </select>
                  {formik.touched.Religion && formik.errors.Religion ? (
                    <span className=" text-[9px] " style={{ color: "red" }}>
                      (*) {formik.errors.Religion}
                    </span>
                  ) : null}
                </div>

                {/* Relationship Status */}
                <div className="relative">
                  <select
                    name="RelationshipStatus"
                    id=""
                    className="py-2.5 w-full text-[13px]"
                    style={{ border: "1px solid gray" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.RelationshipStatus}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * סטטוס משפחתי
                    </option>
                    <option value="Single">רווק/ה</option>
                    <option value="Divorced">גרוש/ה</option>
                    <option value="Widower">אלמן/ה</option>
                    <option value="HappilyMarried">נשוי/אה באושר </option>
                    <option value="Dating">נשוי/אה אך מחפש/ת ריגושים</option>
                    <option value="Complicated">פרוד/ה</option>
                    <option value="OpenRelationship">יחסים פתוחים</option>
                    <option value="Other">אחר</option>
                  </select>
                  {formik.touched.RelationshipStatus &&
                  formik.errors.RelationshipStatus ? (
                    <span className=" text-[9px] " style={{ color: "red" }}>
                      (*) {formik.errors.RelationshipStatus}
                    </span>
                  ) : null}
                </div>

                {/* SmokingHabits */}
                <div className="relative">
                  <select
                    name="SmokingHabits"
                    id=""
                    className="py-2.5 w-full text-[13px]"
                    style={{ border: "1px solid gray" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.SmokingHabits}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * עישון
                    </option>
                    <option value="Never">אף פעם</option>
                    <option value="Smoker">מעשן/ת</option>
                    <option value="Company">בחברה</option>
                    <option value="HereAndThere">פה ושם </option>
                    <option value="TryingToStop">מנסה להפסיק</option>
                    <option value="Stopped">הפסקתי </option>
                  </select>
                  {formik.touched.SmokingHabits &&
                  formik.errors.SmokingHabits ? (
                    <span className=" text-[9px] " style={{ color: "red" }}>
                      (*) {formik.errors.SmokingHabits}
                    </span>
                  ) : null}
                </div>

                {/* DrinkingHabits */}
                <div className="relative">
                  <select
                    name="DrinkingHabits"
                    id=""
                    className="py-2.5 w-full text-[13px]"
                    style={{ border: "1px solid gray" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.DrinkingHabits}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * עישון
                    </option>
                    <option value="Never">אף פעם</option>
                    <option value="Rarely">בנדידות</option>
                    <option value="Company">בחברה</option>
                    <option value="Often">פה ושם </option>
                    <option value="TryingToStop">מנסה להפסיק</option>
                    <option value="Stopped">הפסקתי </option>
                  </select>
                  {formik.touched.DrinkingHabits &&
                  formik.errors.DrinkingHabits ? (
                    <span className=" text-[9px] " style={{ color: "red" }}>
                      (*) {formik.errors.DrinkingHabits}
                    </span>
                  ) : null}
                </div>

                {/* HaveChildren */}
                <div className="relative">
                  <select
                    name="HaveChildren"
                    id=""
                    className="py-2.5 w-full text-[13px]"
                    style={{ border: "1px solid gray" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.HaveChildren}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * האם יש לך ילדים?
                    </option>
                    <option value="true">כן</option>
                    <option value="false">לא</option>
                  </select>
                  {formik.touched.HaveChildren && formik.errors.HaveChildren ? (
                    <span className=" text-[9px] " style={{ color: "red" }}>
                      (*) {formik.errors.HaveChildren}
                    </span>
                  ) : null}
                </div>
              </Box>
              <Box className="mt-2">
                <button
                  type="submit"
                  className="py-2 px-5 rounded-md text-white"
                  style={{ background: Colors.secondary }}
                >
                  {isLoading ? (
                    <ClipLoader
                      color={Colors.white}
                      loading={isLoading}
                      size={10}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  ) : (
                    "שמירה"
                  )}
                </button>
                <Link to={`/userProfile`}>
                  <button
                    className="py-2 px-5 rounded-md bg-none text-white"
                    style={{ color: Colors.secondary }}
                  >
                    חזרה
                  </button>
                </Link>
              </Box>
            </form>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box className="bg-white p-2">
            <AddGallery />
          </Box>
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
