import axios from "axios";

const API_URL = "https://api.datingnow-il.com";

const sendMessage = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/message?Text=${data?.text}&UserId=${data?.userId}`,
    "",
    config
  );

  return response.data;
};

const sendMessagePhoto = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const formdata = new FormData();
  formdata.append("Photo", data.photo);

  const response = await axios.post(
    `${API_URL}/message?UserId=${data?.userId}`,
    formdata,
    config
  );

  console.log(response.data);
  return response.data;
};

const getAllMessage = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}/message`, config);
  if (response) {
    localStorage.setItem("messages", JSON.stringify(response.data.data));
  }

  return response.data.data;
};

const authService = {
  sendMessage,
  getAllMessage,
  sendMessagePhoto,
};

export default authService;
