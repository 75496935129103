import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Edit } from "@mui/icons-material";
import Colors from "../../helpers/Colors";
import AddGallery from "./AddGallery";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  TranslateGender,
  availabilityOptions,
  bodyTypes,
  drinkingStatuses,
  educationLevels,
  eyeColors,
  hairColors,
  hasChildren,
  heightRanges,
  hobbies,
  income,
  occupation,
  relationshipPurpose,
  relationshipStatuses,
  smokingStatuses,
  sponsorKinds,
} from "../../utils/Translations";
import { ClipLoader } from "react-spinners";
import { CustomTabPanel, a11yProps } from "../../helpers";

export default function UserDetails() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { user } = useSelector((state) => state.auth);
  const { cities } = useSelector((state) => state.cities);
  const [city, setCity] = React.useState();

  const userHobbies = user?.data?.hobbies || [];
  const selectedHobbies = userHobbies.filter((hobby) =>
    hobbies.some((item) => item.english === hobby)
  );
  const additionalValues = selectedHobbies.map(
    (hobby) => hobbies.find((item) => item.english === hobby)?.hebrew || ""
  );

  const hobbiesString = additionalValues.join(", ");

  const AccountDetails = [
    {
      title: "העיסוק שלי",
      value: occupation.find((item) => item.english === user?.data?.occupation)
        ?.hebrew,
    },
    {
      title: "איזה סוג ספונסר/ית אני?",
      value: sponsorKinds.find(
        (item) => item.english === user?.data?.sponsorKind
      )?.hebrew,
    },
    {
      title: "מטרת הקשר",
      value: relationshipPurpose.find(
        (item) => item.english === user?.data?.relationshipPurpose
      )?.hebrew,
    },
    {
      title: "כל היום",
      value: availabilityOptions.find(
        (item) => item.english === user?.data?.availability
      )?.hebrew,
    },
    {
      title: "ההכנסה שלי",
      value: income.find((item) => item.english === user?.data?.income)?.hebrew,
    },
    {
      title: "מוטו בחיים",
      value: user?.data?.motto,
    },
    {
      title: "קצת עלי",
      value: user?.data?.about,
    },
    {
      title: "תחביבים",
      value: hobbiesString,
    },
    {
      title: "שפות",
      value: user?.data?.languages.map((item) => item).join(", "),
    },
  ];

  const Pestles = [
    {
      title: "השכלה",
      value: educationLevels.find(
        (item) => item.english === user?.data?.education
      )?.hebrew,
    },
    {
      title: "מבנה גוף",
      value: bodyTypes.find(
        (item) => item.english === user?.data?.bodyStructure
      )?.hebrew,
    },
    {
      title: "גובה",
      value: heightRanges.find((item) => item.english === user?.data?.height)
        ?.hebrew,
    },
    {
      title: "שיער",
      value: hairColors.find((item) => item.english === user?.data?.hairColor)
        ?.hebrew,
    },
    {
      title: "צבע עיניים",
      value: eyeColors.find((item) => item.english === user?.data?.eyeColor)
        ?.hebrew,
    },
    {
      title: "דת",
      value: user?.data?.religion,
    },
    {
      title: "סטטוס משפחת",
      value: relationshipStatuses.find(
        (item) => item.english === user?.data?.relationshipStatus
      )?.hebrew,
    },
    {
      title: "עישון",
      value: smokingStatuses.find(
        (item) => item.english === user?.data?.smokingHabit
      )?.hebrew,
    },
    {
      title: "הרגלי שתיה",
      value: drinkingStatuses.find(
        (item) => item.english === user?.data?.smokingHabit
      )?.hebrew,
    },
  ];

  cities?.data?.forEach((item) => {
    if (item.id === user?.data?.city && !city) {
      setCity(item);
    }
  });

  return (
    <>
      <Box className="px-1 h-min-screen w-full">
        <Box sx={{ width: "100%" }} className="py-4">
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
            className="flex  items-center justify-end"
          >
            <Tabs
              value={value}
              sx={{
                "& .MuiTab-root.Mui-selected": {
                  color: "#fff",
                  background: "#000",
                  fontSize: "17px",
                  borderRadius: "5px",
                  padding: "2px 2px",
                },
                "& .MuiTabs-indicator": { bgcolor: "#000" },
                "& .MuiTab-root": {
                  color: "#000",

                  fontSize: "17px",
                  borderRadius: "5px",
                },
              }}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="פרופיל" {...a11yProps(1)} />
              <Tab label="גלריה" {...a11yProps(0)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Box className="bg-white p-2 w-full">
              <Box>
                <Box className="flex items-center justify-between border-b border-gray-200 py-2">
                  <Link to="/userProfile/personal/7">
                    <Box className="flex items-center">
                      <Typography
                        style={{ color: Colors.secondary, fontSize: "18px" }}
                      >
                        עריכה
                      </Typography>
                      <Edit
                        style={{ fontSize: "18px", color: Colors.secondary }}
                      />
                    </Box>
                  </Link>
                  <Typography
                    className="font-bold"
                    style={{ color: Colors.secondary, fontSize: "18px" }}
                  >
                    מידע אישי
                  </Typography>
                </Box>
                <Box
                  className="flex items-center justify-end border-gray-200 border-b py-2"
                  style={{ fontSize: "14px" }}
                >
                  <Typography>
                    {" "}
                    {
                      TranslateGender.find(
                        (item) => item.english === user?.data?.gender
                      )?.hebrew
                    }
                  </Typography>
                  <Typography className="w-[40%] text-right text-[#777]">
                    :אני
                  </Typography>
                </Box>
                {/* <Box
                  className="flex items-center justify-end border-gray-200 border-b py-2"
                  style={{ fontSize: "14px" }}
                >
                  <Typography>אישה, בגיל 18 - 80</Typography>
                  <Typography className="w-[40%] text-right text-[#777]">
                    :מחפש
                  </Typography>
                </Box> */}
                <Box
                  className="flex items-center justify-end border-gray-200 border-b py-2"
                  style={{ fontSize: "14px" }}
                >
                  <Typography>{user?.data?.username}</Typography>
                  <Typography className="w-[40%] text-right text-[#777]">
                    :כינוי
                  </Typography>
                </Box>
                <Box
                  className="flex items-center justify-end border-gray-200 border-b py-2"
                  style={{ fontSize: "14px" }}
                >
                  <Typography>{user?.data?.dob}</Typography>
                  <Typography className="w-[40%] text-right text-[#777]">
                    :תאריך לידה
                  </Typography>
                </Box>
                <Box
                  className="flex items-center justify-end border-gray-200 border-b py-2"
                  style={{ fontSize: "14px" }}
                >
                  <Typography className="capitalize">
                    {user?.data?.city}
                  </Typography>
                  <Typography className="w-[40%] text-right text-[#777]">
                    :עיר מגורים
                  </Typography>
                </Box>
              </Box>
              {/* Account details */}
              <Box>
                <Box className="flex items-center justify-between border-b border-gray-200 py-2">
                  <Link to="/userProfile/account/7">
                    <Box className="flex items-center">
                      <Typography
                        style={{ color: Colors.secondary, fontSize: "18px" }}
                      >
                        עריכה
                      </Typography>
                      <Edit
                        style={{ fontSize: "18px", color: Colors.secondary }}
                      />
                    </Box>
                  </Link>
                  <Typography
                    className="font-bold"
                    style={{ color: Colors.secondary, fontSize: "18px" }}
                  >
                    פרטי חשבון
                  </Typography>
                </Box>
                {AccountDetails?.map((item, index) => (
                  <Box
                    className="flex items-center justify-end border-gray-200 border-b py-2"
                    key={index}
                    style={{ fontSize: "14px" }}
                  >
                    <Typography>{item.value}</Typography>
                    <Typography
                      className="w-[40%] text-right text-[#777]"
                      style={{ fontSize: "14px" }}
                    >
                      :{item.title}
                    </Typography>
                  </Box>
                ))}
              </Box>
              {/* Pestles */}
              <Box>
                <Box className="flex items-center justify-between border-b border-gray-200 py-2">
                  <Link to="/userProfile/pestles/7">
                    <Box className="flex items-center">
                      <Typography
                        style={{ color: Colors.secondary, fontSize: "18px" }}
                      >
                        עריכה
                      </Typography>
                      <Edit
                        style={{ fontSize: "18px", color: Colors.secondary }}
                      />
                    </Box>
                  </Link>
                  <Typography
                    className="font-bold"
                    style={{ color: Colors.secondary, fontSize: "18px" }}
                  >
                    עלי
                  </Typography>
                </Box>
                {Pestles?.map((item, index) => (
                  <Box
                    className="flex items-center justify-end border-gray-200 border-b py-2"
                    key={index}
                    style={{ fontSize: "14px" }}
                  >
                    <Typography>{item.value}</Typography>
                    <Typography
                      className="w-[40%] text-right text-[#777]"
                      style={{ fontSize: "14px" }}
                    >
                      :{item.title}
                    </Typography>
                  </Box>
                ))}
              </Box>

              {/* spouse Details */}
              {/* <Box>
                <Box className="flex items-center justify-between border-gray-200 py-2">
                  <Link to="/userProfile/spouse/7">
                    <Box className="flex items-center">
                      <Typography
                        style={{ color: Colors.secondary, fontSize: "18px" }}
                      >
                        עריכה
                      </Typography>
                      <Edit
                        style={{ fontSize: "18px", color: Colors.secondary }}
                      />
                    </Box>
                  </Link>
                  <Typography
                    className="font-bold"
                    style={{ color: Colors.secondary, fontSize: "18px" }}
                  >
                    פרטי בן/בת הזוג
                  </Typography>
                </Box>
              </Box> */}
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Box className="bg-white p-2">
              <AddGallery />
            </Box>
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
}
