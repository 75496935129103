import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

import logo from "../../assets/images/logo.png";
import Colors from "../../helpers/Colors";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import LoginModal from "../../pages/Auth/LoginModal";

const GeneralNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 567 && isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navigate = useNavigate();

  const handleHome = () => {
    navigate("/");
  };

  return (
    <Box
      className="sticky top-0  z-30 right-0"
      style={{
        backgroundColor: Colors.black,
        position: "sticky !important",
        top: "0px",
        right: "0px",
        width: "100%",
      }}
    >
      <Box className="px-7 bg-[#000] relative  sm:block flex items-center">
        <Box className="absolute">
          {openModal && (
            <LoginModal
              handleClose={handleClose}
              handleOpen={handleOpen}
              setOpenModal={setOpenModal}
              openModal={openModal}
            />
          )}
        </Box>
        <Box className="flex sm:flex-row flex-col z-30 items-center  justify-between">
          <Box className="sm:w-[64%] w-[74%] z-100 flex sm:flex-row flex-col sm:justify-start  justify-center items-center space-x-4 ">
            <img
              src={logo}
              alt="Logo"
              className="sm:w-[33%] w-[100%] cursor-pointer"
              onClick={handleHome}
            />
            <Box className="sm:flex hidden items-center space-x-6 ">
              <Link to="/how_it_works">
                <Typography
                  variant="h6"
                  className="text-[13px] text-white hover:underline "
                >
                  {/* How it works */} איך זה עובד
                </Typography>
              </Link>
              <Link to="/about">
                <Typography
                  variant="h6"
                  className="text-[13px] text-white hover:underline"
                >
                  {/* About Sugar Daddy */} אודות שוגר דדי
                </Typography>
              </Link>
            </Box>
          </Box>
          <Box className="sm:flex hidden flex-col items-end spaxe-y-2 py-2">
            <Link to="/register">
              <button
                className="py-1 rounded-md px-6 text-white text-[13px]"
                style={{ backgroundColor: Colors.secondary }}
              >
                {/* Free Registeration */} הרשמה חינם
              </button>{" "}
            </Link>
            <button
              className="py-1 rounded-md px-6 text-white text-[13px] bg-none border-none"
              onClick={handleOpen}
            >
              {/* Entrance */} כניסה
            </button>
          </Box>
        </Box>
        <Box className="sm:hidden z-30 ">
          {isMenuOpen ? (
            <CloseIcon
              style={{ color: Colors.white, fontSize: "30px" }}
              onClick={toggleMenu}
            />
          ) : (
            <MenuIcon
              style={{ color: Colors.white, fontSize: "30px" }}
              onClick={toggleMenu}
            />
          )}
        </Box>
      </Box>

      {/* Mobile */}
      <Box
        className={`sm:hidden block z-0 h-screen bg-[#000] mobile-screen ${
          isMenuOpen ? "open mobile-screen" : "mobile-screen"
        }`}
      >
        <Box className="flex flex-col items-center justify-center mt-20 space-y-4">
          <Box className="text-center">
            <Typography variant="h6" className="text-[15px] text-white">
              כיצד זה פועל
            </Typography>
            <Typography variant="h6" className="text-[15px] text-white">
              אודות Sugar Daddy
            </Typography>
          </Box>
          <Box className="flex  flex-col items-center space-y-2 py-2">
            <Link to="/register">
              <button
                className="py-1 rounded-md px-6 text-white text-[13px]"
                style={{ backgroundColor: Colors.secondary }}
              >
                רישום חינם
              </button>
            </Link>{" "}
            <button
              className="py-1 rounded-md px-6 text-white text-[13px] bg-none border-none"
              onClick={handleOpen}
            >
              התחבר
            </button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralNavbar;
