import { Avatar, Box, Paper } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import dummyImg from "../../../assets/images/vision6.png";
import { Typography } from "antd";
import { ArrowBack, CameraAlt, Close, Send } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  reset,
  sendMessage,
  sendMessagePhoto,
} from "../../../features/mail/mailSlice";
import { useLocation } from "react-router-dom";
import { useSignalGlobalContext } from "../../../context";

const ChatScreen = ({
  filteredChats,
  activeId,
  setShowChat,
  activeUsername,
}) => {
  const { mails, isSuccess, isLoading, message } = useSelector(
    (state) => state.mail
  );
  const { friends } = useSelector((state) => state.friends);
  const { user } = useSelector((state) => state.auth);
  const [isFriend, setIsfriend] = useState(false);
  const { messageFunctions } = useSignalGlobalContext();

  const [text, setText] = useState("");
  const [photo, setPhoto] = useState(null); // Initialize photo state as null
  const dispatch = useDispatch();

  useEffect(() => {
    messageFunctions();
  }, []);

  const handleSend = () => {
    if (text.trim() !== "") {
      const data = { text, userId: activeId };
      setText("");

      dispatch(sendMessage(data));
    } else {
      console.log(photo);
      const formData = new FormData();
      formData.append("Photo", photo);
      const data = { photo, userId: activeId };
      dispatch(sendMessagePhoto(data));
    }
  };

  const boxRef = useRef(null);
  const location = useLocation();
  const id = location?.state?.activeId;

  useEffect(() => {
    const checkFriend = friends.find(
      (friend) => friend.id == activeId || friend.id == id
    );
    if (checkFriend) {
      setIsfriend(true);
    }

    if (boxRef.current) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
    }

    if (isSuccess && message === "message sent") {
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
      setText("");
      setPhoto(null); // Reset photo state to null
      dispatch(reset());
    }
  }, [isSuccess, isLoading, filteredChats]);
  const compareCreatedAt = (a, b) => {
    return new Date(a.createdAt) - new Date(b.createdAt);
  };

  const sorted = filteredChats?.messages?.sort(compareCreatedAt);

  function groupMessagesByDay(messages) {
    const groupedMessages = [];

    const currentDate = new Date();

    currentDate.setHours(0, 0, 0, 0);

    messages?.forEach((message) => {
      const createdAt = new Date(message?.createdAt);

      createdAt.setHours(0, 0, 0, 0);

      const timeDiff = Math.abs(currentDate.getTime() - createdAt.getTime());
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

      let dayLabel;
      if (diffDays === 0) {
        dayLabel = "Today";
      } else if (diffDays === 1) {
        dayLabel = "Yesterday";
      } else if (diffDays === 2) {
        dayLabel = "Saturday";
      } else if (diffDays === 3) {
        dayLabel = "Friday";
      } else {
        const options = { month: "long", day: "numeric" };
        dayLabel = createdAt?.toLocaleDateString("en-US", options);
      }

      let found = false;
      for (let i = 0; i < groupedMessages?.length; i++) {
        if (groupedMessages[i].day === dayLabel) {
          groupedMessages[i].messages.push(message);
          found = true;
          break;
        }
      }

      if (!found) {
        groupedMessages.push({ day: dayLabel, messages: [message] });
      }
    });

    return groupedMessages;
  }

  const groupedMessages = groupMessagesByDay(sorted);

  return (
    <Box className="sticky top-20">
      <Box className="p-3 bg-black shadow-md shadow-black flex items-center md:justify-end justify-between">
        <Box className="md:hidden block">
          <ArrowBack
            style={{ color: "#fff" }}
            onClick={() => {
              setShowChat(false);
              messageFunctions();
            }}
          />
        </Box>
        <Box className="flex items-center space-x-4">
          <Typography className="text-white">{activeUsername}</Typography>
          <Avatar src={dummyImg} />
        </Box>
      </Box>

      <Box className="chatbg">
        <Box
          ref={boxRef}
          style={{ overflowY: "scroll" }}
          className="px-5 md:h-[65vh] h-[72vh] py-10">
          {groupedMessages?.map((message) => {
            return (
              <>
                <Typography className="text-center font-black my-3">
                  {message.day}
                </Typography>
                {message?.messages.map((mail) => {
                  const img = `https://api.datingnow-il.com/${mail?.photoUrl}`;
                  const img2 = `https://api.datingnow-il.com/${mail?.user?.photoUrl}`;
                  const imgSelf = `https://api.datingnow-il.com/${user?.data?.photoUrl}`;

                  return (
                    <div key={mail.id} className="space-y-4 mt-3">
                      {mail?.fromId !== mail?.user?.id ? (
                        <Box className="flex items-center justify-end mb-5">
                          <Box className="bg-white p-4 md:w-[35%] w-[75%] rounded-t-lg relative rounded-l-lg">
                            <Box className="absolute -top-2 -left-4">
                              <Avatar
                                src={imgSelf || dummyImg}
                                sizes="large"
                                sx={{ width: 45, height: 45 }}
                              />
                            </Box>
                            <Box className="flex items-center justify-end">
                              <Box className="w-[90%] ">
                                {mail?.text ? (
                                  <Typography>{mail?.text}</Typography>
                                ) : (
                                  <img
                                    src={img}
                                    alt="Received"
                                    className="w-[80%]"
                                  />
                                )}
                              </Box>
                            </Box>
                            <Box className="absolute bottom-1 right-1">
                              <Typography className="font-bold text-[11px]">
                                {new Date(mail?.createdAt).toLocaleTimeString(
                                  "en-US",
                                  {
                                    hour12: false,
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box className="bg-white p-4 md:w-[35%] w-[75%] mb-5 rounded-t-lg relative rounded-r-lg">
                          <Box className="absolute -top-2 -right-2">
                            <Avatar
                              src={img2 || dummyImg}
                              sizes="large"
                              sx={{ width: 45, height: 45 }}
                            />
                          </Box>
                          <Box className="w-[90%]">
                            {mail?.text ? (
                              <Typography>{mail?.text}</Typography>
                            ) : (
                              <img src={img} alt="Sent" className="w-[80%]" />
                            )}
                          </Box>
                          <Box className="absolute bottom-1 right-1">
                            <Typography className="font-bold text-[11px]">
                              {new Date(mail?.createdAt).toLocaleTimeString(
                                "en-US",
                                {
                                  hour12: false,
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </div>
                  );
                })}
              </>
            );
          })}
          {/* {!isFriend && activeId && (
            <Box className="bg-gray-700 flex justify-end p-3 rounded">
              <Typography className="text-white">
                You are no longer friends with this person
              </Typography>
            </Box>
          )} */}
          {!isFriend && !activeId && (
            <Box className="bg-gray-700 flex justify-end p-3 rounded">
              <Typography className="text-white">
                למי אתה רוצה לשוחח איתו?
              </Typography>
            </Box>
          )}
        </Box>
        {photo && (
          <Box className="p-3 ">
            <Box className="border-2 relative border-white">
              {" "}
              <img
                src={URL.createObjectURL(photo)}
                alt="Selected"
                className="w-[30%] rounded-md"
              />
              <Box className="absolute top-3 right-2">
                <Close onClick={() => setPhoto(null)} />
              </Box>
            </Box>
          </Box>
        )}
        <Box className="border-t-4 border-b-4 p-2 space-x-2 border-white flex justify-around items-center">
          <label htmlFor="file-upload">
            <CameraAlt style={{ fontSize: "33px" }} />
          </label>
          <input
            id="file-upload"
            hidden
            type="file"
            onChange={(e) => setPhoto(e.target.files[0])}
          />

          <input
            type="text"
            value={text}
            disabled={
              (!photo ? false : true) ||
              !activeId ||
              (user?.data?.gender == "Male" && !user?.data?.premium)
            }
            className="outline-none md:py-2 py-4 rounded-sm px-3 w-[85%]"
            placeholder="כתוב הודעה..."
            onChange={(e) => setText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSend(); // Call your function here when Enter key is pressed
              }
            }}
          />

          <Box
            className="p-1 rounded-md bg-[#777] cursor-pointer hover:bg-[#775]"
            onClick={handleSend}>
            <Send style={{ fontSize: "33px" }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatScreen;
