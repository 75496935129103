import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("דרוש"),
  nickname: Yup.string().required("דרוש"),
  password: Yup.string().required("דרוש"),
  hometown: Yup.string().required("דרוש"),
  date: Yup.string().required("דרוש"),
  month: Yup.string().required("דרוש"),
  year: Yup.string().required("דרוש"),
});

export const loginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email"),
  username: Yup.string(),
  password: Yup.string().required("דרוש"),
});
