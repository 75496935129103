import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import tosService from "./tosService";
import { logoutError } from "../auth/authService";
const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  tos: null,
};

export const termsOfCondition = createAsyncThunk(
  "tos/termsOfCondition",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await tosService.termsOfCondition(token);
      if (data.status === false) {
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const reportUser = createAsyncThunk(
  "tos/reportUser",
  async (reportData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await tosService.reportUser(token, reportData);
      console.log(data);
      if (data.status === false) {
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createTicket = createAsyncThunk(
  "tos/createTicket",
  async (ticketData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await tosService.createTicket(token, ticketData);
      if (data.status === false) {
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createAnonymousTicket = createAsyncThunk(
  "tos/createAnonymousTicket",
  async (ticketData, thunkAPI) => {
    try {
      const data = await tosService.createAnonymousTicket(ticketData);
      if (data.status === false) {
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const tosSlice = createSlice({
  name: "tos",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(termsOfCondition.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(termsOfCondition.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "terms accepted";
        state.tos = action.payload;
      })
      .addCase(termsOfCondition.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(reportUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(reportUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "report sent";
      })
      .addCase(reportUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createTicket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createTicket.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "ticket sent";
      })
      .addCase(createTicket.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createAnonymousTicket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAnonymousTicket.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "anonymous ticket sent";
      })
      .addCase(createAnonymousTicket.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = tosSlice.actions;
export default tosSlice.reducer;
