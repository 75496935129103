import { Box, Grid } from "@mui/material";
import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import Colors from "../../helpers/Colors";
import { Close, LocationOn } from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import dummyImg from "../../assets/images/vision6.png";
import dummyFemaleImg from "../../assets/images/feIMG.jpg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { acceptFriend } from "../../features/friends/friendsSlice";
import Loader2 from "../Loader2";
import { ClipLoader } from "react-spinners";

const SearchedFriends = ({ users, type }) => {
  const dispatch = useDispatch();
  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.friends
  );
  const [loading, setLoading] = useState(false);

  const { cities } = useSelector((state) => state.cities);

  const handleAccept = (userId) => {
    setLoading(true);
    dispatch(acceptFriend({ userId: userId }));
  };

  useEffect(() => {
    if (isSuccess && message.message === "Success") {
      setLoading(false);
      window.location.reload();
    }
  }, [isSuccess]);
  return (
    <Box>
      <Box className="flex items-center space-x-2">
        <input
          type="search"
          placeholder="הזן מילת חיפוש..."
          className="py-2 px-2 rounded-md text-[13px]"
          style={{ border: "1px solid gray" }}
        />
        <button
          className="border py-2 px-4 rounded-md text-white text-[13px]"
          style={{ background: Colors.secondary }}
        >
          חיפוש
        </button>
      </Box>
      <Grid container spacing={1}>
        {users?.map((user, index) => {
          let city = "";
          cities.data?.forEach((item) => {
            if (item.id === user?.city && !city) {
              city = item.englishName;
            }
          });

          const img = `https://api.datingnow-il.com/${user?.photoUrl}`;
          return (
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              key={index}
              style={{ marginTop: 15 }}
            >
              <Link to={`/ouserprofile/${user?.id}`}>
                <Box className="p-1 relative border rounded-md">
                  {type === "sent" && (
                    <Close
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        fontSize: "18px",
                        border: "1px solid gray",
                        borderRadius: "2px",
                        background: "#fafafa",
                      }}
                    />
                  )}

                  {user?.photoUrl ? (
                    <img src={img} alt="" className="w-full rounded-md" />
                  ) : (
                    <img
                      src={
                        user?.data?.gender === "Male"
                          ? dummyImg
                          : dummyFemaleImg
                      }
                      alt=""
                      className="w-full rounded-md"
                    />
                  )}
                </Box>

                <Box className="flex justify-end mt-3">
                  <Typography className="text-[13px] text-gray-500">
                    {new Date(user?.lastSeen).toLocaleString()}
                  </Typography>
                </Box>
                <Box className="flex justify-end">
                  <Typography className="text-[13px] text-gray-500">
                    <span
                      className="font-semibold"
                      style={{ color: Colors.secondary }}
                    >
                      {" "}
                      {user?.username}
                    </span>
                    , {user?.age}
                  </Typography>
                </Box>
                <Box className="flex justify-end">
                  <Box className="flex items-center space-x-1">
                    <Typography className="text-[13px] text-gray-500">
                      <span className="font-semibold">{user?.city}</span>
                    </Typography>
                    <LocationOn style={{ fontSize: "13px" }} />
                  </Box>
                </Box>
                {type === "recieved" && (
                  <Box className="p-2 mt-2">
                    <button
                      disabled={loading}
                      onClick={() => handleAccept(user?.id)}
                      className="py-2 px-3 text-[11px] w-full text-[#D71D6D] rounded-md"
                      style={{ border: "1px solid #D71D6D" }}
                    >
                      הצג בקשת חברות
                    </button>
                  </Box>
                )}
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default SearchedFriends;
