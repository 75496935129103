import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Box from "@mui/material/Box";
import { Edit } from "@mui/icons-material";
import Colors from "../../helpers/Colors";
import AddGallery from "./AddGallery";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import { ClipLoader } from "react-spinners";
import { useState } from "react";
import {
  logout,
  reset,
  updateEmail,
  updatePassword,
} from "../../features/auth/authSlice";
import { Typography } from "antd";
import { CustomTabPanel, a11yProps } from "../../helpers";
import CreateTicket from "./CreateTicket";

export default function UserDetails() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  const { user, users, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );
  const [ErrorX, setError] = useState();
  const [Success, setSuccess] = useState();
  const [SuccessEmail, setSuccessEmail] = useState();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (isError) {
      setError(message);
      setTimeout(() => {
        setError("");
      }, 3000);
      setLoading(false);
    }

    if (isSuccess && message === "Password Updated") {
      setError("");
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
      setLoading(false);
    }

    if (isSuccess && message === "Email Sent") {
      setError("");
      setSuccessEmail(true);
      setTimeout(() => {
        setSuccess(false);
        dispatch(logout());
      }, 3000);
      setLoading(false);
    }

    // Cleanup function
    return () => {
      // Reset state on component unmount as well
      dispatch(reset());
    };
  }, [isError, isSuccess, dispatch, message]);

  const formikPassword = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("נדרשת הסיסמה הישנה"),
      newPassword: Yup.string()
        .required("נדרשת הסיסמה החדשה")
        .min(8, "הסיסמה חייבת להכיל לפחות 8 תווים"),
      confirmNewPassword: Yup.string()
        .required("נדרש לאשר את הסיסמה החדשה")
        .oneOf([Yup.ref("newPassword"), null], "הסיסמאות חייבות להתאים"),
    }),
    onSubmit: (values) => {
      const data = {
        password: values.oldPassword,
        newPassword: values.newPassword,
      };
      dispatch(updatePassword(data));
    },
  });

  const formikEmail = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("נדרש כתובת דוא"),
    }),
    onSubmit: (values) => {
      const data = {
        email: values.email,
      };
      dispatch(updateEmail(data));
    },
  });

  return (
    <>
      <Box className="px-1 h-min-screen w-full">
        <Box sx={{ width: "100%" }} className="py-4">
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
            className="flex  items-center justify-end">
            <Tabs
              value={value}
              sx={{
                "& .MuiTab-root.Mui-selected": {
                  color: "#fff",
                  background: "#000",
                  fontSize: "17px",
                  borderRadius: "5px",
                  padding: "2px 2px",
                },
                "& .MuiTabs-indicator": { bgcolor: "#000" },
                "& .MuiTab-root": {
                  color: "#000",

                  fontSize: "17px",
                  borderRadius: "5px",
                },
              }}
              onChange={handleChange}
              aria-label="basic tabs example">
              <Tab label="איפוס דואר אלקטרוני" {...a11yProps(1)} />
              <Tab label="שינוי סיסמה" {...a11yProps(0)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Box className="px-1 h-min-screen w-full">
              <Box className="bg-white p-2">
                <Typography className="text-[15px] font-semibold mb-3">
                  איפוס דוא"ל
                </Typography>{" "}
                {ErrorX && (
                  <Typography className="text-red-500 text-[11px]">
                    {ErrorX}
                  </Typography>
                )}
                {SuccessEmail && (
                  <Typography className="text-green-500 text-[11px]">
                    נשלח קישור לאימות כתובת הדוא"ל
                  </Typography>
                )}
                <form onSubmit={formikEmail.handleSubmit}>
                  <div>
                    <label className="text-[11px]" htmlFor="email">
                      דוא"ל
                    </label>
                    <input
                      type="email"
                      className="w-full border py-2 rounded-md px-4 text-[11px]"
                      style={{ border: "1px solid gray" }}
                      id="email"
                      name="email"
                      value={formikEmail.values.email}
                      onChange={formikEmail.handleChange}
                      onBlur={formikEmail.handleBlur}
                    />
                    {formikEmail.errors.email && (
                      <span className="text-red-500 text-[10px]">
                        {formikEmail.errors.email}
                      </span>
                    )}
                  </div>

                  {isLoading ? (
                    <button
                      className="px-3 py-1 text-[12px] mt-3 text-white rounded-md"
                      style={{ background: Colors.primary2 }}
                      disabled>
                      <ClipLoader
                        color={Colors.white}
                        loading={isLoading}
                        size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </button>
                  ) : (
                    <Box className="space-x-2">
                      <button
                        type="submit"
                        className="px-3 py-1 text-[12px] mt-3 text-white rounded-md"
                        style={{ background: Colors.primary2 }}>
                        שליחה
                      </button>
                      <Link to={`/userProfile`}>
                        <button
                          type="submit"
                          className="px-3 py-1 text-[12px] mt-3 text-white rounded-md"
                          style={{ background: Colors.secondary }}>
                          חזרה
                        </button>
                      </Link>
                    </Box>
                  )}
                </form>
              </Box>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Box className="px-1 h-min-screen w-full">
              <Box className="bg-white p-2">
                <Typography className="text-[15px] font-semibold mb-3">
                  עדכון סיסמה
                </Typography>{" "}
                {ErrorX && (
                  <Typography className="text-red-500 text-[11px]">
                    {ErrorX}
                  </Typography>
                )}
                {Success && (
                  <Typography className="text-green-500 text-[11px]">
                    הסיסמה עודכנה בהצלחה
                  </Typography>
                )}
                <form onSubmit={formikPassword.handleSubmit}>
                  <div>
                    <label className="text-[11px]" htmlFor="oldPassword">
                      סיסמה ישנה
                    </label>
                    <input
                      type="password"
                      className="w-full border py-2 rounded-md px-4 text-[11px]"
                      style={{ border: "1px solid gray" }}
                      id="oldPassword"
                      name="oldPassword"
                      value={formikPassword.values.oldPassword}
                      onChange={formikPassword.handleChange}
                      onBlur={formikPassword.handleBlur}
                    />
                    {formikPassword.errors.oldPassword && (
                      <span className="text-red-500 text-[10px]">
                        {formikPassword.errors.oldPassword}
                      </span>
                    )}
                  </div>
                  <div>
                    <label className="text-[11px]" htmlFor="newPassword">
                      סיסמה חדשה
                    </label>
                    <input
                      type="password"
                      className="w-full border py-2 rounded-md px-4 text-[11px]"
                      style={{ border: "1px solid gray" }}
                      id="newPassword"
                      name="newPassword"
                      value={formikPassword.values.newPassword}
                      onChange={formikPassword.handleChange}
                      onBlur={formikPassword.handleBlur}
                    />
                    {formikPassword.errors.newPassword && (
                      <span className="text-red-500 text-[10px]">
                        {formikPassword.errors.newPassword}
                      </span>
                    )}
                  </div>
                  <div>
                    <label className="text-[11px]" htmlFor="confirmNewPassword">
                      אימות סיסמה חדשה
                    </label>
                    <input
                      type="password"
                      className="w-full border py-2 rounded-md px-4 text-[11px]"
                      style={{ border: "1px solid gray" }}
                      id="confirmNewPassword"
                      name="confirmNewPassword"
                      value={formikPassword.values.confirmNewPassword}
                      onChange={formikPassword.handleChange}
                      onBlur={formikPassword.handleBlur}
                    />
                    {formikPassword.errors.confirmNewPassword && (
                      <span className="text-red-500 text-[10px]">
                        {formikPassword.errors.confirmNewPassword}
                      </span>
                    )}
                  </div>
                  {isLoading ? (
                    <button
                      className="px-3 py-1 text-[12px] mt-3 text-white rounded-md"
                      style={{ background: Colors.primary2 }}
                      disabled>
                      <ClipLoader
                        color={Colors.white}
                        loading={isLoading}
                        size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </button>
                  ) : (
                    <Box className="space-x-2">
                      <button
                        type="submit"
                        className="px-3 py-1 text-[12px] mt-3 text-white rounded-md"
                        style={{ background: Colors.primary2 }}>
                        שליחה
                      </button>
                      <Link to={`/userProfile`}>
                        <button
                          type="submit"
                          className="px-3 py-1 text-[12px] mt-3 text-white rounded-md"
                          style={{ background: Colors.secondary }}>
                          חזרה
                        </button>
                      </Link>
                    </Box>
                  )}
                </form>
              </Box>
            </Box>
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
}
