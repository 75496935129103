import { Box } from "@mui/material";
import { Typography } from "antd";
import React from "react";
import { Chat, Lock } from "@mui/icons-material";
import Colors from "../../helpers/Colors";

const SingleBlogTab = () => {
  return (
    <Box>
      <Box className="bg-white my-2 rounded-md py-5 px-5">
        <Typography className="text-[13px] font-bold">
          7 טיפים לזוגיות להתחלת קשר מוצלחת במיוחד
        </Typography>
        <Typography className="text-[10px] text-[#777]">
          4:49, 30/11/2021
        </Typography>
        <Typography className="text-[12px] my-2">
          כאשר אתם בתחילתה של זוגיות רומנטית בוגרת ומאפשרת, הערבים הם הזמן לחגוג
          בו להתקרב ולבלות ולא סתם להעביר את הזמן זאת הזדמנות אמת להכיר טוב יותר
          וללמוד אחד את השני לעומק לראות צדדים חיובים ומצחיקים ובעיקר להיות
          רומנטיים ויצירתיים. החוכמה היא באמת לבלות זמן איכות אמיתי ומעניין ובלי
          שזה יראה מתאמץ מדי ומלאכותי.
          <br />
          כאשר אתם בתחילתה של זוגיות רומנטית בוגרת ומאפשרת, הערבים הם הזמן לחגוג
          בו להתקרב ולבלות ולא סתם להעביר את הזמן זאת הזדמנות אמת להכיר טוב יותר
          וללמוד אחד את השני לעומק לראות צדדים חיובים ומצחיקים ובעיקר להיות
          רומנטיים ויצירתיים. החוכמה היא באמת לבלות זמן איכות אמיתי ומעניין ובלי
          שזה יראה מתאמץ מדי ומלאכותי.
          <br />
          כאשר אתם בתחילתה של זוגיות רומנטית בוגרת ומאפשרת, הערבים הם הזמן לחגוג
          בו להתקרב ולבלות ולא סתם להעביר את הזמן זאת הזדמנות אמת להכיר טוב יותר
          וללמוד אחד את השני לעומק לראות צדדים חיובים ומצחיקים ובעיקר להיות
          רומנטיים ויצירתיים. החוכמה היא באמת לבלות זמן איכות אמיתי ומעניין ובלי
          שזה יראה מתאמץ מדי ומלאכותי.
          <br />
          כאשר אתם בתחילתה של זוגיות רומנטית בוגרת ומאפשרת, הערבים הם הזמן לחגוג
          בו להתקרב ולבלות ולא סתם להעביר את הזמן זאת הזדמנות אמת להכיר טוב יותר
          וללמוד אחד את השני לעומק לראות צדדים חיובים ומצחיקים ובעיקר להיות
          רומנטיים ויצירתיים. החוכמה היא באמת לבלות זמן איכות אמיתי ומעניין ובלי
          שזה יראה מתאמץ מדי ומלאכותי.
          <br />
          כאשר אתם בתחילתה של זוגיות רומנטית בוגרת ומאפשרת, הערבים הם הזמן לחגוג
          בו להתקרב ולבלות ולא סתם להעביר את הזמן זאת הזדמנות אמת להכיר טוב יותר
          וללמוד אחד את השני לעומק לראות צדדים חיובים ומצחיקים ובעיקר להיות
          רומנטיים ויצירתיים. החוכמה היא באמת לבלות זמן איכות אמיתי ומעניין ובלי
          שזה יראה מתאמץ מדי ומלאכותי.
          <br />
          כאשר אתם בתחילתה של זוגיות רומנטית בוגרת ומאפשרת, הערבים הם הזמן לחגוג
          בו להתקרב ולבלות ולא סתם להעביר את הזמן זאת הזדמנות אמת להכיר טוב יותר
          וללמוד אחד את השני לעומק לראות צדדים חיובים ומצחיקים ובעיקר להיות
          רומנטיים ויצירתיים. החוכמה היא באמת לבלות זמן איכות אמיתי ומעניין ובלי
          שזה יראה מתאמץ מדי ומלאכותי.
          <br />
          כאשר אתם בתחילתה של זוגיות רומנטית בוגרת ומאפשרת, הערבים הם הזמן לחגוג
          בו להתקרב ולבלות ולא סתם להעביר את הזמן זאת הזדמנות אמת להכיר טוב יותר
          וללמוד אחד את השני לעומק לראות צדדים חיובים ומצחיקים ובעיקר להיות
          רומנטיים ויצירתיים. החוכמה היא באמת לבלות זמן איכות אמיתי ומעניין ובלי
          שזה יראה מתאמץ מדי ומלאכותי.
          <br />
          כאשר אתם בתחילתה של זוגיות רומנטית בוגרת ומאפשרת, הערבים הם הזמן לחגוג
          בו להתקרב ולבלות ולא סתם להעביר את הזמן זאת הזדמנות אמת להכיר טוב יותר
          וללמוד אחד את השני לעומק לראות צדדים חיובים ומצחיקים ובעיקר להיות
          רומנטיים ויצירתיים. החוכמה היא באמת לבלות זמן איכות אמיתי ומעניין ובלי
          שזה יראה מתאמץ מדי ומלאכותי.
          <br />
          כאשר אתם בתחילתה של זוגיות רומנטית בוגרת ומאפשרת, הערבים הם הזמן לחגוג
          בו להתקרב ולבלות ולא סתם להעביר את הזמן זאת הזדמנות אמת להכיר טוב יותר
          וללמוד אחד את השני לעומק לראות צדדים חיובים ומצחיקים ובעיקר להיות
          רומנטיים ויצירתיים. החוכמה היא באמת לבלות זמן איכות אמיתי ומעניין ובלי
          שזה יראה מתאמץ מדי ומלאכותי.
          <br />
        </Typography>
      </Box>
      <Box>
        <Typography className="text-[18px] mb-2">תגובות (0)</Typography>
        <Box className="space-x-2">
          <button className="py-2 px-5 rounded-md border bg-[#D71D6D] border-[#D71D6D] bg-none text-[#fff]">
            פרסם תגובה
          </button>
          <button className="py-2 px-5 rounded-md border border-[#D71D6D] bg-none text-[#D71D6D]">
            שלח/י
          </button>
          <Box className="flex mt-3 items-center space-x-1">
            <Lock style={{ fontSize: "11px", color: "#777" }} />
            <Typography className="text-[11px] text-[#777]">
              מנויי פרימיום יכולים להגיב לתמונות
            </Typography>
          </Box>
          <Typography className="mt-2 text-[#D71D6D]">חזור לבלוג</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SingleBlogTab;
