import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import service from "./singleUsersService";
import { logoutError } from "../auth/authService";

const initialState = {
  user: null,
  gallery: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getUser = createAsyncThunk(
  "singleUser/getUser",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await service.getDetails(token, id);
      if (data.status === false) {
        // If status is false, return an error
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUserGallery = createAsyncThunk(
  "singleUser/getUserGallery",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await service.getUserGallery(token, id);
      if (data.status === false) {
        // If status is false, return an error
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const singleUserSlice = createSlice({
  name: "singleUser",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(getUserGallery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserGallery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.gallery = action.payload;
      })
      .addCase(getUserGallery.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.gallery = null;
      });
  },
});

export const { reset } = singleUserSlice.actions;
export default singleUserSlice.reducer;
