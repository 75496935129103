import { Box } from "@mui/material";
import React from "react";

import logo from "../../assets/images/logo.png";
import Colors from "../../helpers/Colors";
import { Link } from "react-router-dom";

const AuthNavbar = () => {
  return (
    <Box
      className="sticky top-0  z-30 right-0"
      style={{
        backgroundColor: Colors.black,
        position: "sticky !important",
        top: "0px",
        right: "0px",
        width: "100%",
      }}>
      <Link to="/" className="px-7 bg-[#000] flex items-center justify-center">
        <img
          src={logo}
          alt="Logo"
          className="sm:w-[22%] w-[80%] cursor-pointer"
        />
      </Link>
    </Box>
  );
};

export default AuthNavbar;
