import React from "react";
import { ClipLoader } from "react-spinners";
import Colors from "../helpers/Colors";

const Loader2 = () => {
  return (
    <div>
      <ClipLoader
        color={Colors.white}
        loading={true}
        size={10}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Loader2;
