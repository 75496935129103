import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Colors from "../../helpers/Colors";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { Grid } from "@mui/material";
import { ImageViewer } from "react-image-viewer-dv";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function OtherUsersGallery() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { gallery, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.singleUser
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Box className="flex flex-wrap items-center justify-end">
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className="flex items-center justify-end">
          <Tabs
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: "#fff",
                background: Colors.gray,
                fontSize: "13px",
                borderRadius: "5px",
                padding: "2px 2px",
              },
              "& .MuiTabs-indicator": { bgcolor: "#000" },
              "& .MuiTab-root": {
                color: "#000",
                fontSize: "17px",
                borderRadius: "5px",
              },
            }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            {/* <Tab label="מועדפים" {...a11yProps(2)} />
            <Tab label="תמונה" {...a11yProps(1)} /> */}
            <Tab label="כל" {...a11yProps(0)} />
          </Tabs>
        </Box>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {isLoading && (
          <>
            <Box className="absolute w-full  flex items-center justify-center z-100">
              <ClipLoader
                color={Colors.primary2}
                loading={isLoading}
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </Box>
          </>
        )}

        <Grid container>
          {gallery?.data?.length > 0 ? (
            <>
              {gallery?.data.map((item, index) => {
                const img = `https://api.datingnow-il.com/${item?.url}`;

                return (
                  <Grid item xs={6} md={4} key={index} spacing={2}>
                    <Box className="bg-white p-2 rounded-md">
                      <ImageViewer>
                        <img
                          src={img}
                          alt="user photos"
                          className="w-full rounded-md sm:h-[30vh] h-[25vh]"
                        />
                      </ImageViewer>
                    </Box>
                  </Grid>
                );
              })}
            </>
          ) : (
            <Typography>לא הועלו תמונות</Typography>
          )}
        </Grid>
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={1}>
        תמונה
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        מועדפים
      </CustomTabPanel> */}
    </Box>
  );
}
