import React from "react";
import ProfileNavbar from "../../components/Navbars/ProfileNavbar";
import { Box, Grid } from "@mui/material";
import Sidebar from "../../components/Profile/Sidebar";
import { Typography } from "antd";
import CreateTicket from "../../components/Profile/CreateTicket";

const Tickets = () => {
  return (
    <>
      <ProfileNavbar />

      <Box className="sm:w-[95%] m-auto w-[94%] py-12">
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            className="h-[60vh] items-center justify-center flex flex-col"
          >
            <Box className="border bg-gray-200 rounded-md sm:w-[60%] w-[96%] m-auto p-5 shadow-md">
              <CreateTicket />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            className="sm:block hidden"
            style={{ marginTop: "20%" }}
          >
            <Sidebar />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Tickets;
