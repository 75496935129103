import { Box, Grid } from "@mui/material";
import { Typography } from "antd";
import React, { useEffect, useRef } from "react";
import Colors from "../../helpers/Colors";
import { LocationOn } from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import dummyImg from "../../assets/images/vision6.png";
import dummyFemaleImg from "../../assets/images/user1.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";

const SearchedUsers = ({ users }) => {
  const { cities } = useSelector((state) => state.cities);
  const [visibleUsers, setVisibleUsers] = useState([]);
  const [displayCount, setDisplayCount] = useState(10);

  const handleScroll = () => {
    const container = containerRef.current;
    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight
    ) {
      setDisplayCount((prevCount) => prevCount + 10);
    }
  };

  // Ref for the container element
  const containerRef = useRef(null);

  // Attach scroll event listener
  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Update visible users when displayCount changes

  return (
    <Box ref={containerRef}>
      <Grid container>
        {users?.map((user, index) => {
          let city = "";
          cities.data?.forEach((item) => {
            if (item.id === user?.city && !city) {
              city = item.englishName;
            }
          });

          const img = `https://api.datingnow-il.com/${user?.photoUrl}`;
          return (
            <Grid item xs={6} sm={6} md={3} key={index}>
              <Link to={`/ouserprofile/${user?.id}`}>
                <Box className="p-3 relative">
                  <FiberManualRecordIcon
                    style={{
                      position: "absolute",
                      top: "25px",
                      left: "20px",
                      fontSize: "14px",
                      color: user?.active === false ? "#fff" : "green",
                    }}
                  />

                  {user?.photoUrl ? (
                    <img src={img} alt="" className="w-full h-[25vh]" />
                  ) : (
                    <img
                      src={user?.gender === "Male" ? dummyImg : dummyFemaleImg}
                      alt=""
                      className="w-full h-[25vh]"
                    />
                  )}
                  <Box className="flex justify-end">
                    <Typography className="text-[13px] text-gray-500">
                      <span
                        className="font-semibold"
                        style={{ color: Colors.secondary }}>
                        {" "}
                        {user?.username}
                      </span>
                      , {user?.age}
                    </Typography>
                  </Box>
                  <Box className="flex justify-end">
                    <Box className="flex items-center space-x-1">
                      <Typography className="text-[13px] text-gray-500">
                        <span className="font-semibold">{user?.city}</span>{" "}
                      </Typography>
                      <LocationOn style={{ fontSize: "13px" }} />
                    </Box>
                  </Box>
                </Box>
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default SearchedUsers;
