import React, { useEffect } from "react";
import ProfileNavbar from "../../components/Navbars/ProfileNavbar";
import { Box, Grid } from "@mui/material";

import BlogTab from "../../components/blog/BlogTab";
import { useNavigate } from "react-router-dom";

const Blog = () => {
  const navigate = useNavigate();
  // useEffect(() => {
  //   const userData = JSON.parse(localStorage.getItem("registration"));

  //   if (user) {
  //     navigate("/main");
  //   }
  //   if (userData) {
  //     navigate("/complete-reg", {
  //       state: {
  //         userData,
  //       },
  //     });
  //   }
  // }, []);
  return (
    <>
      <ProfileNavbar />
      <Box className="bg-[#eae9e7] h-min-screen ">
        <Box className="sm:w-[94%] m-auto w-[98%] py-8">
          <Grid container className="space-y-4">
            <Grid
              xs={12}
              sm={12}
              md={3}
              className="border sm:px-6 px-0 w-full "
            ></Grid>

            <Grid xs={12} sm={12} md={9}>
              <BlogTab />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Blog;
