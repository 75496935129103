import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import How_It_Works from "./pages/How_it_works/How_It_Works";
import About from "./pages/About/About";
import Terms from "./pages/Terms/Terms";
import Registration from "./pages/Auth/Registration";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Main from "./pages/Profile/Main";
import PersonalProfile from "./pages/Profile/PersonalProfile";
import UpdatePersonalProfile from "./pages/Profile/UpdatePersonalProfile";
import UpdateAccountProfile from "./pages/Profile/UpdateAccountProfile";
import UpdatePestleProfile from "./pages/Profile/UpdatePestleProfile";
import UpdateSpouseProfile from "./pages/Profile/UpdateSpouseProfile";
import FriendList from "./pages/Profile/FriendList";
import FavouriteList from "./pages/Profile/FavouriteList";
import BlackList from "./pages/Profile/BlackList";
import Blog from "./pages/Blog/Blog";
import SingleBlog from "./pages/Blog/SingleBlog";
import LikeMe from "./pages/Profile/Like-me/LikeMe";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import VerifySent from "./pages/Auth/VerifySent";
import VerifyToken from "./pages/Auth/VerifyToken";
import CompleteReg from "./pages/Auth/CompleteReg";
import LoginPage from "./pages/Auth/LoginPage";
import OthersProfile from "./pages/Profile/OthersProfile ";
import UserSettings from "./pages/Profile/UserSettings";
import MailBox from "./pages/Profile/mail/MailBox";
import Text from "./pages/Text";
import { useSignalGlobalContext } from "./context";
import Contact from "./pages/Profile/Contact";
import Tickets from "./pages/Profile/Ticket";
import AnyTickets from "./pages/Tickets/AnyTickets";
import EnterNewPassword from "./pages/Auth/EnterNewPassword";
import EnterNewEmail from "./pages/Auth/EnterNewEmail";

export default function App() {
  const { startConnection } = useSignalGlobalContext();

  // useEffect(() => {
  //   if (navigator.onLine === true) {
  //     startConnection();
  //   }
  // }, [navigator.onLine]);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/test" element={<Text />} />
        <Route path="/how_it_works" element={<How_It_Works />} />
        <Route path="/tickets" element={<AnyTickets />} />
        <Route path="/about" element={<About />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/verification" element={<VerifySent />} />
        <Route path="/verification/:userId/:token" element={<VerifyToken />} />
        <Route
          path="/forgot-password/:userId/:token"
          element={<EnterNewPassword />}
        />
        <Route
          path="/email-reset/:userId/:token/:email"
          element={<EnterNewEmail />}
        />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/complete-reg" element={<CompleteReg />} />

        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:blogId" element={<SingleBlog />} />

        <Route element={<ProtectedRoutes />}>
          <Route path="/main" element={<Main />} />
          <Route path="/userProfile" element={<PersonalProfile />} />
          <Route path="/userProfile/settings" element={<UserSettings />} />
          <Route path="/ouserProfile/:userId" element={<OthersProfile />} />
          <Route
            path="/userProfile/personal/:userId"
            element={<UpdatePersonalProfile />}
          />
          <Route
            path="/userProfile/account/:userId"
            element={<UpdateAccountProfile />}
          />
          <Route path="/userProfile/ticket" element={<Tickets />} />
          <Route path="/profile/contact" element={<Contact />} />
          <Route path="/mailbox/inbox" element={<MailBox />} />
          <Route
            path="/userProfile/pestles/:userId"
            element={<UpdatePestleProfile />}
          />
          <Route
            path="/userProfile/spouse/:userId"
            element={<UpdateSpouseProfile />}
          />
          <Route path="/userProfile/friendList" element={<FriendList />} />
          <Route path="/userProfile/like-me" element={<LikeMe />} />
          <Route
            path="/userProfile/favouriteList"
            element={<FavouriteList />}
          />
          <Route path="/userProfile/blackList" element={<BlackList />} />
        </Route>
      </Routes>
    </Router>
  );
}
