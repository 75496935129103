import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Lock } from "@mui/icons-material";
import AddGallery from "../AddGallery";
import { Typography } from "antd";
import Colors from "../../../helpers/Colors";
import { Link } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const months = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const days = Array.from({ length: 31 }, (_, index) => index + 1);
const currentYear = new Date().getFullYear();
const years = Array.from(new Array(100), (val, index) => currentYear - index);

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const numbersArray = Array.from({ length: 63 }, (_, index) => index + 18);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Spouse() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className="px-1 w-full">
      <Box sx={{ width: "100%" }} className="py-4">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
          className="flex  items-center justify-end">
          <Tabs
            value={value}
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: "#fff",
                background: "#000",
                fontSize: "17px",
                borderRadius: "5px",
                padding: "2px 2px",
              },
              "& .MuiTabs-indicator": { bgcolor: "#000" },
              "& .MuiTab-root": {
                color: "#000",

                fontSize: "17px",
                borderRadius: "5px",
              },
            }}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab label="פרופיל" {...a11yProps(1)} />
            <Tab label="גלריה" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box className="bg-white sm:p-4 p-2">
            <form action="">
         
              <Box className="my-3">
                <Box>
                  <Typography className="text-[12px]">Smoking:</Typography>
                  <Box className="">
                    <select
                      name="interest"
                      id=""
                      className="w-full border py-2.5 mt-1"
                      style={{ border: "1px solid gray" }}>
                      <option value="">10,000 - 15,000</option>
                      <option value="">10,000 - 15,000</option>
                      <option value="">10,000 - 15,000</option>
                    </select>
                  </Box>
                </Box>
              </Box>
              <Box className="my-3">
                <Box>
                  <Typography className="text-[12px]">
                    Drinking Habits:
                  </Typography>
                  <Box className="">
                    <select
                      name="interest"
                      id=""
                      className="w-full border py-2.5 mt-1"
                      style={{ border: "1px solid gray" }}>
                      <option value="">10,000 - 15,000</option>
                      <option value="">10,000 - 15,000</option>
                      <option value="">10,000 - 15,000</option>
                    </select>
                  </Box>
                </Box>
              </Box>
            
            </form>
            <Box>
              <button
                className="py-2 px-5 rounded-md text-white"
                style={{ background: Colors.secondary }}>
                Save
              </button>
              <Link to="/userProfile/6">
                <button
                  className="py-2 px-5 rounded-md bg-none text-white"
                  style={{ color: Colors.secondary }}>
                  Return
                </button>
              </Link>
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box className="bg-white p-2">
            <AddGallery />
          </Box>
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
