import axios from "axios";

const API_URL = "https://api.datingnow-il.com";

const getDetails = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}/user/view/${id}`, config);

  return response.data;
};

const getUserGallery = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_URL}/user/gallery/photos/${id}`,
    config
  );

  return response.data;
};

const authService = {
  getDetails,
  getUserGallery,
};

export default authService;
