import React, { useEffect } from "react";
import ProfileNavbar from "../../components/Navbars/ProfileNavbar";
import { Typography } from "antd";
import { Box, Grid } from "@mui/material";
import { Refresh } from "@mui/icons-material";
// import { users } from "../../utils";

import UserDetails from "../../components/Profile/UserDetails";
import UserDetailsCard from "../../components/Profile/UserDetailsCard";
import {
  getUser,
  getUserGallery,
} from "../../features/singleUsers/singleUserSlice";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import Colors from "../../helpers/Colors";
import OtherUserDetailsCard from "../../components/Profile/OtherUserDetailsCard";
import OtherUserDetails from "../../components/Profile/OtherUserDetails";
import dummyImg from "../../assets/images/vision6.png";
import { Link, useParams } from "react-router-dom";
import {
  getBlacklists,
  getFriends,
  getRequestsRecieved,
  getRequestsSent,
  reset,
} from "../../features/friends/friendsSlice";

const OthersProfile = () => {
  const { user, isLoading } = useSelector((state) => state.singleUser);

  const { users } = useSelector((state) => state.auth);
  const { isSuccess, isError } = useSelector((state) => state.friends);
  const { userId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser(userId));
    dispatch(getUserGallery(userId));
    dispatch(getFriends());
    dispatch(getRequestsSent());
    dispatch(getRequestsRecieved());
    dispatch(getBlacklists());

    window.scrollTo({ top: 50, behavior: "smooth" });
  }, []);

  return (
    <>
      {" "}
      <ProfileNavbar />
      {isLoading ? (
        <Box className="flex items-center h-screen flex-col justify-center">
          <ClipLoader
            color={Colors.primary2}
            loading={isLoading}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </Box>
      ) : (
        <Box className="bg-[#eae9e7] h-min-screen ">
          <Box className="sm:w-[94%] m-auto w-[98%] py-8">
            <Grid container className="space-y-4">
              <Grid
                xs={12}
                sm={12}
                md={3}
                className="border sm:px-6 px-0 w-full "
              >
                <OtherUserDetailsCard user={user} />
              </Grid>

              <Grid xs={12} sm={12} md={6}>
                <OtherUserDetails user={user} />
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={3}
                className="sm:px-6 px-0 sm:block hidden "
              >
                <Box className="bg-white rounded-md p-3">
                  <Box className="flex items-center justify-end">
                    {/* <Refresh style={{ fontSize: "18px" }} /> */}
                    <Typography
                      style={{ fontSize: "14px", fontWeight: "bold" }}
                    >
                      משתמשים חדשים
                    </Typography>
                  </Box>
                  <Typography className="mt-2">
                    הכל שוגר בייבי שוגר בייב
                  </Typography>
                  <Box className="flex flex-wrap">
                    {users?.data?.slice(0, 12).map((item, index) => {
                      const img = `https://api.datingnow-il.com/${item?.photoUrl}`;

                      return (
                        <>
                          {item?.photoUrl ? (
                            <a
                              href={`/ouserprofile/${item?.id}`}
                              className="sm:w-[22%] w-[32%] mx-0.5 m-auto my-2 bg-gray-350"
                            >
                              <img
                                src={img || dummyImg}
                                alt=""
                                key={index}
                                className=""
                              />
                            </a>
                          ) : (
                            <a
                              href={`/ouserprofile/${item?.id}`}
                              className="sm:w-[22%] w-[32%] mx-0.5 m-auto my-2 bg-gray-350"
                            >
                              <img
                                src={dummyImg}
                                alt=""
                                key={index}
                                className=""
                              />
                            </a>
                          )}
                        </>
                      );
                    })}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default OthersProfile;
