import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const signalRLocal = JSON.parse(localStorage.getItem("signalR"));

const initialState = {
  signalR: signalRLocal ? signalRLocal : null,
};

export const updateSignalR = createAsyncThunk(
  "signalR/updateSignalR",
  async (data, thunkAPI) => {
    try {
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const signalRSlice = createSlice({
  name: "mail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateSignalR.fulfilled, (state, action) => {
      state.signalR = action.payload;
    });
  },
});

export const { reset } = signalRSlice.actions;
export default signalRSlice.reducer;
