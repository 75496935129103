import React, { useState } from "react";

const Text = () => {
  const [message, setMessage] = useState("");

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const userId = "31cb40d3-6321-41ea-ad67-f7a3a0137f18";
  const token =
    "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI5Y2I0YWI0My0xY2YzLTRmZmMtODk3Mi0yZjcyODFlMGYzNGQiLCJuYmYiOjE3MTEwMzQzNDQsImV4cCI6MTcxMTYzOTE0NCwiaWF0IjoxNzExMDM0MzQ0LCJpc3MiOiJodHRwczovL3N1Z2FyZGFkZHkuY29tIiwiYXVkIjoiaHR0cHM6Ly9zdWdhcmRhZGR5LmNvbSJ9.NBmCM5yjGxFu2pv-mEqp60PrYA_aAoCIuchASoxtRBAbrBmPvPlYv0frREyNkNUyJGHW19RkCpHj6IlamEBikQ"; // Replace with your actual bearer token

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = fetch(
        `https://api.datingnow-il.com/message?Text=${message}&UserId=${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include Bearer token in the headers
          },
        }
      )
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="max-w-sm mx-auto mt-10">
      <form
        onSubmit={handleSubmit}
        className="flex items-center justify-between bg-gray-100 p-4 rounded-lg shadow-md"
      >
        <input
          type="text"
          value={message}
          onChange={handleChange}
          className="w-full mr-4 py-2 px-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          placeholder="Enter your message..."
        />
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Send
        </button>
      </form>
    </div>
  );
};

export default Text;
