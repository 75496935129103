import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import GeneralNavbar from "../../components/Navbars/GeneralNavbar";
import { Typography } from "antd";
import CountUp from "react-countup";
import Colors from "../../helpers/Colors";
import ReactPlayer from "react-player/lazy";
import { visionImg } from "../../utils";
import { Carousel } from "antd";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import safe from "../../assets/icons/safe.svg";
import result from "../../assets/icons/promise-results.svg";
import secure from "../../assets/icons/secure.svg";
import GeneralFooter from "../../components/Navbars/GeneralFooter";
import { Link, useNavigate } from "react-router-dom";
import LoginModal from "../Auth/LoginModal";
import { useSelector } from "react-redux";
const Home = () => {
  const [slidesToShow, setSlidesToShow] = useState(3);
  const [slidesToShow2, setSlidesToShow2] = useState(2);
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => setOpenModal(false);

  const handleOpen = () => setOpenModal(true);
  const navigate = useNavigate();
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("registration"));
    const statusRegistration = JSON.parse(
      localStorage.getItem("registration1")
    );
    const user = JSON.parse(localStorage.getItem("user"));
    const token = JSON.parse(localStorage.getItem("token"));

    if (user?.status === true || token?.status === true) {
      navigate("/main");
    }
    if (userData) {
      navigate("/complete-reg", {
        state: {
          userData,
        },
      });
    }

    if (statusRegistration) {
      navigate("/verification");
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSlidesToShow(1);
        setSlidesToShow2(1);
      } else {
        setSlidesToShow(3);
        setSlidesToShow2(2);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "none",
          border: "1px solid #fff",
          width: "25px",
          height: "25px",
          borderRadius: "50%",
          marginRight: 20,
          marginTop: -25,
        }}
        onClick={onClick}
      >
        <ArrowForwardIosIcon
          style={{
            color: "#fff",
            fontSize: "17px",
            marginTop: 3,
            marginLeft: 2,
          }}
        />
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "none",
          border: "1px solid #fff",
          width: "25px",
          height: "25px",
          borderRadius: "50%",
          marginLeft: 20,
          marginTop: -25,
        }}
        onClick={onClick}
      >
        <ArrowBackIosNewIcon
          style={{ color: "#fff", fontSize: "17px", marginTop: 3 }}
        />
      </div>
    );
  };

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <>
      <GeneralNavbar />

      <Box className="relative" style={{ overflowX: "hidden" }}>
        <Box className="absolute">
          {openModal && (
            <LoginModal
              handleClose={handleClose}
              handleOpen={handleOpen}
              setOpenModal={setOpenModal}
              openModal={openModal}
            />
          )}
        </Box>
        {/*Banner*/}
        <Box className="homeBanner py-20 text-center flex flex-col items-center justify-center">
          <Typography
            variant="h6"
            className="text-[21px] mt-12 sm:laeading-normal leading-8 text-white"
          >
            {/* The largest and most successful website in Israel */} האתר הגדול
            והמצליח בישראל בו
          </Typography>
          <Typography
            variant="h6"
            className="sm:text-[41px] text-[33px] sm:leading-normal leading-8 text-white sm:-mt-3 mt-2"
          >
            {/* Affluent men and attractive women meet */} גברים אמידים ונשים
            אטרקטיביות נפגשים
          </Typography>

          <Box className="flex space-x-4 mt-9">
            <Box className="w-[15vh]  border-t-2"></Box>
            <Box>
              <Typography className="text-white text-[25px] -mt-4">
                {/* above */} מעל
              </Typography>
            </Box>
            <Box className="w-[15vh]  border-t-2"></Box>
          </Box>

          <Box className="-mt-3">
            <CountUp
              end={154085}
              duration={7}
              className="text-[100px]"
              style={{ color: Colors.primary }}
            />
            <Typography className="text-[23px] text-white -mt-5">
              {/* active members */} חברים פעילים
            </Typography>
          </Box>

          <Box className="flex  flex-col items-center space-y-2 py-2">
            <Link to="/register">
              <button
                className="font-bold py-3 rounded-md px-12 text-white text-[15px]"
                style={{ backgroundColor: Colors.secondary }}
              >
                {/* Free Registeration */} הרשמה חינם
              </button>
            </Link>{" "}
            <button
              className="py-2 rounded-md px-6 text-white text-[15px] bg-none border-none sm:hidden block"
              style={{ backgroundColor: Colors.secondary }}
              onClick={handleOpen}
            >
              {/* Entrance */} כניסה
            </button>
          </Box>
        </Box>

        {/*Yutbube*/}
        <Box className="Ytbg py-16 flex flex-col items-center text-center justify-center">
          <Typography className="py-3 sm:text-[25px] text-[13px]">
            {/* They are talking about Sugar Daddy on channel 10 | 5:30{" "} */}
            מדברים על שוגר דדי בערוץ 105:30
          </Typography>
          <Box className="w-[70vh] h-[42vh] sm:px-0 px-24 ">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=kB8oshsEZ00"
              width="100%"
              height="100%"
              controls
              config={{
                youtube: { playerVars: { controls: 1, modestbranding: 1 } },
              }}
            />
          </Box>
        </Box>

        {/*Vison Images*/}
        <Box className="bg-[#000] py-12 text-center">
          <Box className="sm:w-[85%] w-[95%] m-auto">
            <Typography className="text-[22px] font-semibold text-white mb-2">
              חזון חברת שוגר דדי
            </Typography>
            <Typography className="text-[16px] font-normal text-[#f3f3f3] sm:leading-normal">
              לאפשר לאנשים באשר הם את חופש הבחירה לייצר מערכת יחסים שמתאימה
              עבורם והמבוססת על אינטרסים משותפים, כבוד הדדי, כיף ואושר
            </Typography>

            <Box className="flex flex-wrap items-center justify-center mt-5">
              {visionImg.map((img, i) => {
                return (
                  <img
                    src={img}
                    className="sm:w-[9%] w-[30%] m-1 img-scale"
                    key={i}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>

        {/*Operation*/}
        <Box className="bg-[#312D3C]">
          <Box className="sm:w-[70%] w-[95%] m-auto sm:py-10 py-5">
            <Carousel
              slidesToShow={slidesToShow}
              autoplay
              dots={false}
              arrows
              {...settings}
            >
              <Box className=" sm:p-10 p-5 mb-4 text-center grid place-items-center">
                <Box>
                  <img
                    src={safe}
                    alt=""
                    className="sm:w-[30%] w-[30%] m-auto"
                  />
                  <Typography className="text-white font-semibold mt-2 text-[16px]">
                    {/* Meet Safely */}
                    להיפגש בבטחה
                  </Typography>
                  <Typography className="text-white font-normal mt-2 text-[13px]">
                    {/* Let's get to know the exclusive mechanism developed for
                    SUGAR DADDY to check background to prevent impersonation and
                    fraud in which thousands of website users enjoy safe
                    meetings without worries */}
                    בואו להכיר את המנגנון הבלעדי שפותח עבור SUGAR DADDY לבדיקת
                    רקע למניעת התחזות ומרמה בו נהנים אלפי משתמשי האתר במפגשים
                    בטוחים ללא חששות
                  </Typography>
                </Box>
              </Box>
              <Box className=" sm:p-10 p-5 mb-4 text-center ">
                <Box>
                  <img
                    src={result}
                    alt=""
                    className="sm:w-[30%] w-[30%] m-auto"
                  />
                  <Typography className="text-white font-semibold mt-2 text-[16px]">
                    {/* Commit to results */}
                    מתחייבים לתוצאות
                  </Typography>
                  <Typography className="text-white font-normal mt-2 text-[13px]">
                    {/* The oldest and most reliable website in Israel for 12 years.
                    When we say there are more womwn than men, I promise it. So
                    how do you find a useful date? Just sign up */}
                    האתר הוותיק והאמין בישראל כבר 12 שנה. כאשר אנו אומרים שיש
                    יותר נשים מגברים, אני מבטיחים זאת. אז איך מוצאים דייט מועיל?
                    פשוט נרשמים!
                  </Typography>
                  <button
                    className="font-bold py-1 mt-3 rounded-md px-8 text-white text-[12px]"
                    style={{ backgroundColor: Colors.secondary }}
                  >
                    {/* Free Registeration */} הרשמה חינם
                  </button>{" "}
                </Box>
              </Box>
              <Box className=" sm:p-10 p-5 mb-4 text-center ">
                <Box>
                  <img
                    src={secure}
                    alt=""
                    className="sm:w-[30%] w-[30%] m-auto"
                  />
                  <Typography className="text-white font-semibold mt-2 text-[16px]">
                    {/* Secure site 100% */}
                    100% אתר מאובטח
                  </Typography>
                  <Typography className="text-white font-normal mt-2 text-[13px]">
                    {/* Unlike other sites, we are very sensitive to the security of
                    our users. This means caring about your privacy, encrypting
                    identifying data and securing against intrusions and bots */}
                    בניגוד לאתרים אחרים, אנחנו מאוד רגישים לאבטחת המשתמשים שלנו.
                    משמעות הדבר היא אכפתיות לגבי הפרטיות שלך, הצפנת נתונים מזהים
                    ואבטחה מפני חדירות ותוכנות רובוטיות.
                  </Typography>
                </Box>
              </Box>
            </Carousel>
          </Box>
        </Box>

        {/*Testimonolais*/}
        <Box className="testi1">
          <Box className="sm:w-[60%] w-[95%] m-auto sm:py-8 py-5">
            <Carousel
              slidesToShow={slidesToShow2}
              autoplay
              dots={false}
              arrows
              {...settings}
            >
              <Box className=" sm:p-10 p-5 mb-4 text-center grid place-items-center">
                <Box>
                  <img
                    src={require("../../assets/images/testi1.jpg")}
                    alt=""
                    className="sm:w-[30%] w-[30%] m-auto rounded-full"
                  />

                  <Typography className="text-white font-normal mt-2 text-[13px]">
                    {/* I have always been attracted to charismatic and influential
                    men, I tried alot of websites and thanks to Sugar Daddy I
                    found my one man. A successful man and the sky is the limit */}
                    "תמיד נמשכתי לגברים כריזמטיים ובעלי השפעה, ניסיתי הרבה מאוד
                    אתרים ובזכות שוגר דדי מצאתי את הגבר האחד שלי. גבר מצליח
                    והשמיים הם הגבול"
                  </Typography>
                  <Typography className="text-white font-semibold mt-2 text-[16px]">
                    {/* Danish, student, Tel Aviv */}
                    דנית, סטודנטית, תל אביב
                  </Typography>
                </Box>
              </Box>
              <Box className=" sm:p-10 p-5 mb-4 text-center ">
                <Box>
                  <img
                    src={require("../../assets/images/testi2.jpg")}
                    alt=""
                    className="sm:w-[30%] w-[30%] m-auto rounded-full"
                  />

                  <Typography className="text-white font-normal mt-2 text-[13px]">
                    {/* I have already achieved everything in life - family, home ,
                    work and eveb an exit, not bad at all. As a newly divorced
                    and busy personm who is less connected to night life -
                    thanks to Sugar Daddy site, I came back to life */}
                    "כבר השגתי הכול בחיים - משפחה, בית, עבודה ואפילו אקזיט לא רע
                    בכלל. כגרוש טרי ועסוק, שפחות מתחבר לחיי לילה- בזכות אתר שוגר
                    דדי חזרתי לחיים."
                  </Typography>
                  <Typography className="text-white font-semibold mt-2 text-[16px]">
                    {/* Noam, businessman, Ra'anana */}
                    נועם, איש עסקים, רעננה
                  </Typography>
                </Box>
              </Box>
            </Carousel>
          </Box>
        </Box>

        {/*On Mobile*/}
        <Box className="bg-[#fff] py-12 sm: text-center">
          <Box className="sm:w-[85%] w-[95%] m-auto">
            <Grid container>
              <Grid item xs={12} sm={12} md={6} className="sm:block hidden">
                <img
                  src={require("../../assets/images/mobileAvailable.png")}
                  alt=""
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box className="sm:mt-10 mt-0 mb-10">
                  <Typography className="text-[22px] font-semibold text-dark mb-2">
                    {/* Sugar Daddy also on mobile */} שוגר דדי גם במובייל.
                  </Typography>
                  <Typography className="text-[16px] font-normal text-[#000] sm:leading-normal">
                    {/* Always stay in touch with Sugar Daddy, even on mobile,
                    access the website from any mobile device */}
                    תישאר תמיד בקשר עם שוגר דדי, גם במובייל, כנסו לאתר מכל מכשיר
                    סלולרי
                  </Typography>
                  <button
                    className="font-bold py-2 rounded-md px-9 text-white text-[13px] mt-5"
                    style={{ backgroundColor: Colors.secondary }}
                  >
                    {/* Free Registeration */} הרשמה חינם
                  </button>{" "}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="sm:hidden block">
                <img
                  src={require("../../assets/images/mobileAvailable.png")}
                  alt=""
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/*We care*/}
        <Box className="bg-[#312D3C]">
          <Box className="sm:w-[80%] w-[95%] m-auto sm:py-10 py-5">
            <Grid container>
              <Grid item xs={12} sm={12} md={2} className="sm:block hidden">
                <Box className="border-r border-[#68666e]">
                  <Box className="flex sm:flex-col flex-row">
                    <Box>
                      <img
                        src={require("../../assets/images/weCare1.png")}
                        className="sm:w-[92%] w-[100%]"
                        alt=""
                      />
                    </Box>
                    <Box>
                      <img
                        src={require("../../assets/images/weCare2.png")}
                        className="sm:w-[92%] w-[100%]"
                        alt=""
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={10}>
                <Box className=" p-4 r">
                  <Typography
                    className="font-bold  text-[24px]"
                    style={{ color: Colors.white }}
                  >
                    {/* We Care! */} אכפת לנו!
                  </Typography>
                  <Typography className="text-[14px] text-white mt-3">
                    <span style={{ color: Colors.primary, fontWeight: "bold" }}>
                      אתר שוגר דדי הוא אתר היכרויות
                    </span>{" "}
                    המיועד לקשר המבוסס על אינטרסים משותפים לקשר קבוע ואמיתי ולא
                    לתמיכה פר מפגש (הכרות מזדמנת) משתמש שינצל לרעה את הפלטפורמה
                    שלנו יחסם לאלתר.
                  </Typography>
                  <Typography className="text-[14px] text-white mt-3">
                    <span style={{ color: Colors.primary, fontWeight: "bold" }}>
                      האתר מיועד למשתמשים מעל גיל 18!
                    </span>{" "}
                    אם נתקלתם במשתמש או משתמשת מתחת לגיל זה אנא דווחו לנו
                    מיידית. אנחנו רואים בחומרה נרשמים מתחת לגיל 18 ובמידה
                    וקיימים נמחק אותם מהאתר ללא אפשרות לחזור שוב.
                  </Typography>
                  <Typography className="text-[14px] text-white mt-3">
                    <span style={{ color: Colors.primary, fontWeight: "bold" }}>
                      אנחנו בשוגר דדי נגד זנות.
                    </span>{" "}
                    בכל צורה ובלי יוצא מן הכלל. אנו פועלים בכל האמצעים העומדים
                    לרשותנו - האנושיים והטכנולוגיים לנתר כל פעילות הקשורה בזנות,
                    לדווח ולשתף פעולה עם הרשויות.
                    <br />
                    עזרו לנו להיות חברה טובה יותר ולהוקיע מתוכנו כל גורם המנסה
                    לעשות שימוש לרעה בפלטפורמה שלנו ולדווח לנו על כל מקרה בו אתם
                    חושבים שהצד השני מתנהל בצורה לא תקינה. דווח לנו על ידי כפתור
                    דיווח על כל פעילות מחשידה הקשורה בזנות.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={2} className="sm:hidden block">
                <Box className="border-r border-[#68666e]">
                  <Box className="flex sm:flex-col flex-row">
                    <Box>
                      <img
                        src={require("../../assets/images/weCare1.png")}
                        className="sm:w-[92%] w-[100%]"
                        alt=""
                      />
                    </Box>
                    <Box>
                      <img
                        src={require("../../assets/images/weCare2.png")}
                        className="sm:w-[92%] w-[100%]"
                        alt=""
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <GeneralFooter />
      </Box>
    </>
  );
};

export default Home;
