import React, { useEffect } from "react";
import ProfileNavbar from "../../components/Navbars/ProfileNavbar";
import { Typography } from "antd";
import { Box, Grid } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import { users } from "../../utils";

import UserDetails from "../../components/Profile/UserDetails";
import UserDetailsCard from "../../components/Profile/UserDetailsCard";
import { useDispatch, useSelector } from "react-redux";
import dummyImg from "../../assets/images/vision6.png";
import { Link } from "react-router-dom";
import { getDetails } from "../../features/auth/authSlice";
import { ClipLoader } from "react-spinners";
import Colors from "../../helpers/Colors";

const PersonalProfile = () => {
  const { user, users, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDetails());
  }, []);

  return (
    <>
      {" "}
      <ProfileNavbar />
      {isLoading ? (
        <Box className="flex items-center h-screen flex-col justify-center">
          <ClipLoader
            color={Colors.primary2}
            loading={isLoading}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </Box>
      ) : (
        <Box className="bg-[#eae9e7] sm:h-min-screen h-min-screen ">
          <Box className="sm:w-[94%] m-auto w-[98%] py-8">
            <Grid container className="space-y-4">
              <Grid
                xs={12}
                sm={12}
                md={3}
                className="border sm:px-6 px-0 w-full "
              >
                <UserDetailsCard user={user} />
              </Grid>

              <Grid xs={12} sm={12} md={6}>
                <UserDetails />
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={3}
                className="sm:px-6 px-0 sm:block hidden"
              >
                <Box className="bg-white rounded-md p-3">
                  <Box className="flex items-center justify-between">
                    <Refresh style={{ fontSize: "18px" }} />
                    <Typography
                      style={{ fontSize: "14px", fontWeight: "bold" }}
                    >
                      משתמשים חדשים
                    </Typography>
                  </Box>
                  <Typography className="mt-2">
                    הכל שוגר בייבי שוגר בייב
                  </Typography>
                  <Box className="flex flex-wrap ">
                    {users?.data?.slice(0, 12).map((item, index) => {
                      const img = `https://api.datingnow-il.com/${item?.photoUrl}`;

                      return (
                        <>
                          {item?.data?.photoUrl ? (
                            <Link
                              to={`/ouserprofile/${item?.id}`}
                              className="sm:w-[22%] w-[32%] mx-0.5 m-auto my-2 bg-gray-350"
                            >
                              <img src={img || dummyImg} alt="" key={index} />
                            </Link>
                          ) : (
                            <Link
                              to={`/ouserprofile/${item?.id}`}
                              className="sm:w-[22%] w-[32%] mx-0.5 m-auto my-2 bg-gray-400"
                            >
                              <img src={dummyImg} alt="" key={index} />
                            </Link>
                          )}
                        </>
                      );
                    })}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PersonalProfile;
