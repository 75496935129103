import vision1 from "../assets/images/vision1.jpg";
import vision2 from "../assets/images/vision2.png";
import vision3 from "../assets/images/vision3.jpg";
import vision4 from "../assets/images/vision4.jpg";
import vision5 from "../assets/images/vision5.jpg";
import vision6 from "../assets/images/vision6.png";
import vision7 from "../assets/images/vision7.jpg";
import vision8 from "../assets/images/vision8.jpg";
import vision9 from "../assets/images/vision9.jpg";
import vision10 from "../assets/images/vision10.jpg";
import vision11 from "../assets/images/vision11.jpg";
import vision12 from "../assets/images/vision12.jpg";
import vision13 from "../assets/images/vision13.jpg";
import vision14 from "../assets/images/vision14.jpg";

import user1 from "../assets/images/user1.jpg";
import user2 from "../assets/images/user2.jpg";
import user3 from "../assets/images/user3.jpg";
import user4 from "../assets/images/user4.jpg";
import user5 from "../assets/images/user5.jpg";
import user6 from "../assets/images/user6.jpg";
import user7 from "../assets/images/user7.jpg";
import user8 from "../assets/images/user8.jpg";
import user9 from "../assets/images/user9.jpg";
import user10 from "../assets/images/user10.jpg";
import user11 from "../assets/images/user11.jpg";
import user12 from "../assets/images/user12.jpg";
import user13 from "../assets/images/user13.jpg";
import user14 from "../assets/images/user14.jpg";

import {
  Person,
  PersonAdd,
  StarBorder,
  People,
  StarSharp,
  RemoveCircleRounded,
  MessageRounded,
  Home,
  Search,
  Email,
  Photo,
  ThumbUp,
  Settings,
} from "@mui/icons-material";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import LogoutIcon from "@mui/icons-material/Logout";

export const visionImg = [
  vision1,
  vision2,
  vision3,
  vision4,
  vision5,
  vision6,
  vision7,
  vision8,
  vision9,
  vision10,
  vision11,
  vision12,
  vision13,
  vision14,
];

export const menuItems = [
  {
    icon: <Home style={{ fontSize: "17px", color: "#EBD165" }} />,
    title: "עמוד הבית",
    link: "/main",
  },
  // {
  //   icon: <Search style={{ fontSize: "17px", color: "#EBD165" }} />,
  //   title: "חיפוש סידור",
  //   link: "/main",
  // },
  {
    icon: <Email style={{ fontSize: "17px", color: "#EBD165" }} />,
    title: "הודעות",
    link: "/mailbox/inbox",
  },
  {
    icon: <Person style={{ fontSize: "17px", color: "#EBD165" }} />,
    title: "הפרופיל שלי",
    link: "/userProfile",
  },
  // {
  //   icon: <ThumbUp style={{ fontSize: "17px", color: "#EBD165" }} />,
  //   title: "Like me",
  //   link: "/userProfile/like-me",
  // },
  {
    icon: <Photo style={{ fontSize: "17px", color: "#EBD165" }} />,
    title: " הגלריה שלי ",
    link: "/userProfile",
  },
  {
    icon: <PersonAdd style={{ fontSize: "17px", color: "#EBD165" }} />,
    title: "הוסיפו אותי לחברים",
    link: "/userProfile/friendList",
  },
  {
    icon: <StarBorder style={{ fontSize: "17px", color: "#EBD165" }} />,
    title: "הוסיפו אותי למועדפים",
    link: "/userProfile/favouriteList",
  },
  {
    icon: <People style={{ fontSize: "17px", color: "#EBD165" }} />,
    title: " החברים שלי ",
    link: "/userProfile/friendList",
  },
  {
    icon: <StarSharp style={{ fontSize: "17px", color: "#EBD165" }} />,
    title: " המועדפים שלי ",
    link: "/userProfile/favouriteList",
  },
  {
    icon: (
      <RemoveCircleRounded style={{ fontSize: "17px", color: "#EBD165" }} />
    ),
    title: "הרשימה השחורה שלי",
    link: "/userProfile/blackList",
  },
  {
    icon: <Settings style={{ fontSize: "17px", color: "#e6e6e6" }} />,
    title: "הגדרות",
    link: "/userProfile/settings",
  },
  {
    icon: <ContactSupportIcon style={{ fontSize: "17px", color: "#e6e6e6" }} />,
    title: "תמיכה",
    link: "/userProfile/ticket",
  },
  {
    icon: <LogoutIcon style={{ fontSize: "17px", color: "#e6e6e6" }} />,
    title: "התנתק",
  },
];

export const CompleteRegmenuItems = [
  {
    icon: <ContactSupportIcon style={{ fontSize: "17px", color: "#e6e6e6" }} />,
    title: "תמיכה",
  },
  {
    icon: <LogoutIcon style={{ fontSize: "17px", color: "#e6e6e6" }} />,
    title: "התנתק",
  },
];

export const users = [
  {
    fullName: "אביגיל כהן",
    Image: user1,
    age: 28,
    location: "תל אביב",
  },
  {
    fullName: "יוסף לוי",
    Image: user2,
    age: 35,
    location: "ירושלים",
  },
  {
    fullName: "שרה כהן",
    Image: user3,
    age: 42,
    location: "חיפה",
  },
  {
    fullName: "דניאל כהן",
    Image: user4,
    age: 19,
    location: "אילת",
  },
  {
    fullName: "אוריה כהן",
    Image: user5,
    age: 64,
    location: "באר שבע",
  },
  {
    fullName: "לאה כהן",
    Image: user6,
    age: 51,
    location: "רמת גן",
  },
  {
    fullName: "עידו כהן",
    Image: user7,
    age: 30,
    location: "נתניה",
  },
  {
    fullName: "תמר לוי",
    Image: user8,
    age: 22,
    location: "עפולה",
  },
  {
    fullName: "רונן לוי",
    Image: user9,
    age: 40,
    location: "אשדוד",
  },
  {
    fullName: "אליעזר כהן",
    Image: user10,
    age: 48,
    location: "צפת",
  },
  {
    fullName: "עדי לוי",
    Image: user11,
    age: 26,
    location: "חולון",
  },
  {
    fullName: "שושנה כהן",
    Image: user12,
    age: 33,
    location: "אשקלון",
  },
  {
    fullName: "רונית לוי",
    Image: user13,
    age: 37,
    location: "נהריה",
  },
  {
    fullName: "דוד כהן",
    Image: user14,
    age: 45,
    location: "רחובות",
  },
];

export const months = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

export const days = Array.from({ length: 31 }, (_, index) => index + 1);
const currentYear = new Date().getFullYear();
export const years = Array.from(
  new Array(100),
  (val, index) => currentYear - index
);
