import React, { useState, useEffect, useContext } from "react";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { useDispatch, useSelector } from "react-redux";
import { updateUserWithSignalR } from "../features/auth/authSlice";
import { updateGalleryWithSignalR } from "../features/gallery/gallerySlice";
import { getCities } from "../features/cities/citiesSlice";
import {
  updateFavouriteListSignalR,
  updateFriendsListSignalR,
  updateLikeListSignalR,
  updateRequestsRecievedSignalR,
  updateRequestsSentSignalR,
} from "../features/friends/friendsSlice";
import {
  addMessageNotifications,
  addNotificationFriendAccept,
  addNotificationFriendRequest,
  addNotificationLikes,
} from "../features/notifications/notificationsSlice";
import { updateMessagesBySignalR } from "../features/mail/mailSlice";
import { updateSignalR } from "../features/signalR/signalRSlice";
import { logoutError } from "../features/auth/authService";

const AppContext = React.createContext();

const SignalProvider = ({ children }) => {
  const { token, user } = useSelector((state) => state.auth);
  // const { signalR } = useSelector((state) => state.signalR);

  const [conn, setConn] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCities());
  }, []);

  useEffect(() => {
    if (token?.data?.token && user) {
      const connection = new HubConnectionBuilder()
        .withUrl("https://api.datingnow-il.com/updateHub", {
          accessTokenFactory: () => token?.data?.token,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();
      connection.serverTimeoutInMilliseconds = 300000;
      // connection.keepAliveIntervalInMilliseconds = 300000;

      connection.onreconnected(() => {
        galleryFunctions();
        likeFunctions();
        friendFunctions();
        messageFunctions();
      });

      async function startConnection() {
        try {
          await connection.start();
          console.log("SignalR Connected");

          setConn(connection);
          // dispatch(updateSignalR(connection));
        } catch (err) {
          if (err.statusCode === 401 || err.statusCode === 403) {
            dispatch(logoutError());
            window.location.href = "/login";
          } else {
            // console.log("SignalR Connection Error:", err);
            setTimeout(startConnection, 3000);
          }
        }
      }

      startConnection();
    }
  }, [navigator.onLine, user]);

  useEffect(() => {
    if (conn) {
      try {
        // Register event listener once the connection is established
        conn.on("OnProfileUpdated", (userDto) => {
          dispatch(updateUserWithSignalR(JSON.parse(userDto)));
        });
      } catch (error) {
        console.log("Error registering event listener:", error);
      }
    }
  }, [conn, dispatch]);

  const galleryFunctions = () => {
    if (conn) {
      conn.on("OnGalleryPhotoAdded", (data) => {
        const newPhoto = JSON.parse(data);
        const usersPhoto = JSON.parse(localStorage.getItem("userPhotos")) || [];
        const updatedGallery = [...usersPhoto, ...newPhoto];

        // Deduplicate the gallery by photo id
        const uniqueGallery = updatedGallery.filter(
          (photo, index, self) =>
            index === self.findIndex((p) => p.id === photo.id)
        );

        dispatch(updateGalleryWithSignalR(uniqueGallery));
        localStorage.setItem("userPhotos", JSON.stringify(uniqueGallery));
      });

      conn.on("OnGalleryPhotoDeleted", (photoId) => {
        let usersPhoto = JSON.parse(localStorage.getItem("userPhotos")) || [];
        usersPhoto = usersPhoto.filter((photo) => photo.id !== photoId);

        dispatch(updateGalleryWithSignalR(usersPhoto));
        localStorage.setItem("userPhotos", JSON.stringify(usersPhoto));
      });
    }
  };

  const likeFunctions = () => {
    if (conn) {
      conn.on("OnNewFavorite", (data) => {
        const newFav = JSON.parse(data); // Parse the new like data

        // Get user's likes and notifications from local storage or initialize empty arrays if not present
        const userFavourites =
          JSON.parse(localStorage.getItem("favourites")) || [];

        // Add the new like to the likes list and create a new notification for the like
        const updatedFavourites = [...userFavourites, newFav];

        // Deduplicate likes and notifications by photo id
        const uniqueFavourites = updatedFavourites.filter(
          (fav, index, self) => index === self.findIndex((p) => p.id === fav.id)
        );

        dispatch(updateFavouriteListSignalR(uniqueFavourites));
        localStorage.setItem("favourites", JSON.stringify(uniqueFavourites));
      });

      conn.on("OnFavoriteRemoved", (data) => {
        const removedFav = JSON.parse(data); // Parse the new like data
        const userFavourites =
          JSON.parse(localStorage.getItem("favourites")) || [];

        const updatedFav = userFavourites.filter(
          (fav) => fav.id !== removedFav.id
        );

        dispatch(updateFavouriteListSignalR(updatedFav));
        localStorage.setItem("favourites", JSON.stringify(updatedFav));
      });

      conn.on("OnLike", (data) => {
        const newLike = JSON.parse(data); // Parse the new like data

        // Get user's likes and notifications from local storage or initialize empty arrays if not present
        const userLikes = JSON.parse(localStorage.getItem("likes")) || [];
        const notificationLikes =
          JSON.parse(localStorage.getItem("likeNotifications")) || [];

        // Add the new like to the likes list and create a new notification for the like
        const updatedLikes = [...userLikes, newLike];
        const updatedNotifications = [...notificationLikes, newLike];

        // Deduplicate likes and notifications by photo id
        const uniqueLikes = updatedLikes.filter(
          (like, index, self) =>
            index === self.findIndex((p) => p.id === like.id)
        );
        const uniqueNotifications = updatedNotifications.filter(
          (notification, index, self) =>
            index === self.findIndex((p) => p.id === notification.id)
        );

        dispatch(updateLikeListSignalR(uniqueLikes));
        dispatch(addNotificationLikes(uniqueNotifications));

        localStorage.setItem("likes", JSON.stringify(uniqueLikes));
        localStorage.setItem(
          "likeNotifications",
          JSON.stringify(uniqueNotifications)
        );
      });

      conn.on("OnUnlike", (data) => {
        const unlikedLike = JSON.parse(data);
        const userLikes = JSON.parse(localStorage.getItem("likes")) || [];

        // Filter out the unliked like from the userLikes array
        const updatedLikes = userLikes.filter(
          (like) => like.id !== unlikedLike.id
        );

        dispatch(updateLikeListSignalR(updatedLikes)); // Dispatch the updated likes list
        localStorage.setItem("likes", JSON.stringify(updatedLikes)); // Update the local storage
      });
    }
  };

  const friendFunctions = () => {
    if (conn) {
      conn.on("OnFriendRequestSent", (data) => {
        const newRequestSent = JSON.parse(data); // Parse the new like data

        // Get user's likes and notifications from local storage or initialize empty arrays if not present
        const sentRequest =
          JSON.parse(localStorage.getItem("requestSent")) || [];
        const recievedRequest =
          JSON.parse(localStorage.getItem("requestRecieved")) || [];

        const recievedRequestNotification =
          JSON.parse(localStorage.getItem("friendsRequestNotifications")) || [];

        // Add the new like to the likes list and create a new notification for the like
        const uodatedRequestSent = [...sentRequest, newRequestSent.to];
        const uodatedRequestRecieved = [
          ...recievedRequest,
          newRequestSent.from,
        ];

        const updatedNotifications = [
          ...recievedRequestNotification,
          newRequestSent,
        ];

        // Deduplicate likes and notifications by photo id
        const uniqueRequestsent = uodatedRequestSent.filter(
          (request, index, self) =>
            index === self.findIndex((p) => p.id === request.id)
        );

        const uniqueRequestsRecieved = uodatedRequestRecieved.filter(
          (request, index, self) =>
            index === self.findIndex((p) => p.id === request.id)
        );

        function getUniqueNotifications(notifications) {
          let uniqueNotifications = [];

          notifications.forEach((notification) => {
            // Check if a similar notification exists in the uniqueNotifications array
            let exists = uniqueNotifications.some(
              (existingNotification) =>
                existingNotification.from.id === notification.from.id &&
                existingNotification.to.id === notification.to.id
            );

            // If the notification is not already in the uniqueNotifications array, add it
            if (!exists) {
              uniqueNotifications.push(notification);
            }
          });

          return uniqueNotifications;
        }

        dispatch(updateRequestsRecievedSignalR(uniqueRequestsRecieved));
        dispatch(updateRequestsSentSignalR(uniqueRequestsent));
        dispatch(
          addNotificationFriendRequest(
            getUniqueNotifications(updatedNotifications)
          )
        );

        localStorage.setItem("requestSent", JSON.stringify(uniqueRequestsent));
        localStorage.setItem(
          "requestRecieved",
          JSON.stringify(uniqueRequestsRecieved)
        );
        localStorage.setItem(
          "friendsRequestNotifications",
          JSON.stringify(getUniqueNotifications(updatedNotifications))
        );
      });
      conn.on("OnFriendsDeclined", (data) => {
        const newDeclined = JSON.parse(data);

        const recievedRequest =
          JSON.parse(localStorage.getItem("requestRecieved")) || [];

        const updatedRecievedRequest = recievedRequest.filter(
          (request) => request.id !== newDeclined.from.id
        );

        dispatch(updateRequestsRecievedSignalR(updatedRecievedRequest)); // Dispatch the updated likes list
        localStorage.setItem(
          "requestRecieved",
          JSON.stringify(updatedRecievedRequest)
        ); // Update the local storage
      });
      conn.on("OnFriendsAccepted", (data) => {
        const newAccepted = JSON.parse(data);

        const recievedRequest =
          JSON.parse(localStorage.getItem("requestRecieved")) || [];
        const friends = JSON.parse(localStorage.getItem("friends")) || [];

        const updatedFriends = [...friends, newAccepted.from];

        const updatedRecievedRequest = recievedRequest.filter(
          (request) => request.id !== newAccepted.from.id
        );
        const recievedAcceptedNotification =
          JSON.parse(localStorage.getItem("friendsAcceptedNotifications")) ||
          [];

        function getUniqueNotifications(notifications) {
          let uniqueNotifications = [];

          notifications.forEach((notification) => {
            // Check if a similar notification exists in the uniqueNotifications array
            let exists = uniqueNotifications.some(
              (existingNotification) =>
                existingNotification.from.id === notification.from.id &&
                existingNotification.to.id === notification.to.id
            );

            // If the notification is not already in the uniqueNotifications array, add it
            if (!exists) {
              uniqueNotifications.push(notification);
            }
          });

          return uniqueNotifications;
        }

        const updatedNotifications = [
          ...recievedAcceptedNotification,
          newAccepted,
        ];

        const uniqueFriends = updatedFriends.filter(
          (friends, index, self) =>
            index === self.findIndex((p) => p.id === friends.id)
        );

        dispatch(updateRequestsRecievedSignalR(updatedRecievedRequest));
        dispatch(updateFriendsListSignalR(uniqueFriends));
        dispatch(
          addNotificationFriendAccept(
            getUniqueNotifications(updatedNotifications)
          )
        );
        localStorage.setItem(
          "requestRecieved",
          JSON.stringify(updatedRecievedRequest)
        );
        localStorage.setItem("friends", JSON.stringify(uniqueFriends));
        localStorage.setItem(
          "friendsAcceptedNotifications",
          JSON.stringify(getUniqueNotifications(updatedNotifications))
        );
      });
      conn.on("OnUnfriended", (data) => {
        const newUnfriend = JSON.parse(data);

        const friends = JSON.parse(localStorage.getItem("friends")) || [];

        const updatedFriends = friends.filter(
          (request) => request.id !== newUnfriend.from.id
        );

        dispatch(updateFriendsListSignalR(updatedFriends)); // Dispatch the updated likes list
        localStorage.setItem("friends", JSON.stringify(updatedFriends)); // Update the local storage
      });
    }
  };

  const messageFunctions = () => {
    if (conn) {
      conn.on("OnMessageReceived", (data) => {
        const newMessage = JSON.parse(data); // Parse the new like data

        // Get user's likes and notifications from local storage or initialize empty arrays if not present
        const messages = JSON.parse(localStorage.getItem("messages")) || [];
        const notificationMessage =
          JSON.parse(localStorage.getItem("messageNotifications")) || [];

        // Add the new like to the likes list and create a new notification for the like
        const updatedMessages = [...messages, newMessage];
        const updatedNotifications = [...notificationMessage, newMessage];

        // Deduplicate likes and notifications by photo id
        const uniqueMessages = updatedMessages.filter(
          (message, index, self) =>
            index === self.findIndex((p) => p.createdAt === message.createdAt)
        );
        function getUniqueNotifications(notifications) {
          // Sort notifications by 'createdAt' in descending order
          notifications.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          let uniqueNotifications = [];
          let seenCreatedAt = new Set();

          notifications.forEach((notification) => {
            // Check if the 'createdAt' timestamp has been seen before
            if (!seenCreatedAt.has(notification.createdAt)) {
              // If not, add the notification to the list of unique notifications
              uniqueNotifications.push(notification);
              seenCreatedAt.add(notification.createdAt);
            }
          });

          return uniqueNotifications;
        }

        dispatch(updateMessagesBySignalR(uniqueMessages));
        dispatch(
          addMessageNotifications(getUniqueNotifications(updatedNotifications))
        );

        localStorage.setItem("messages", JSON.stringify(uniqueMessages));
        localStorage.setItem(
          "messageNotifications",
          JSON.stringify(getUniqueNotifications(updatedNotifications))
        );
      });
    }
  };

  galleryFunctions();
  likeFunctions();
  friendFunctions();
  messageFunctions();

  return (
    <AppContext.Provider value={{ conn, messageFunctions }}>
      {children}
    </AppContext.Provider>
  );
};

export const useSignalGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, SignalProvider };
