import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Box, FormControlLabel, Button } from "@mui/material";

import { Checkbox, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { validationSchema } from "../../utils/Schemas";
import Colors from "../../helpers/Colors";
import Loader from "../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { register, reset } from "../../features/auth/authSlice";
import { ClipLoader } from "react-spinners";
import {
  TranslateGender,
  TranslateGenderInterest,
} from "../../utils/Translations";

const months = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const days = Array.from({ length: 31 }, (_, index) =>
  String(index + 1).padStart(2, "0")
);

const currentYear = new Date().getFullYear();
const years = Array.from(new Array(100), (val, index) => currentYear - index);

const RegisterForm = ({ isGenderInterest, gender }) => {
  const [checkBox1Checked, setCheckBox1Checked] = useState(false);
  const [checkBox2Checked, setCheckBox2Checked] = useState(false);
  const [error, setError] = useState("");
  const [hometownId, setHomeTownId] = useState([]);

  const [filteredCities, setFilteredCities] = useState([]);
  const { cities } = useSelector((state) => state.cities);

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    formik.setFieldValue("hometown", searchTerm);

    const filteredCities = cities?.data?.filter(
      (city) =>
        city?.englishName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        city?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCities(filteredCities);
  };

  const handleCitySelect = (city, hebrewName) => {
    formik.setFieldValue("hometown", JSON.stringify(`${city}(${hebrewName})`));
    setHomeTownId(hebrewName);
    setFilteredCities([]); // Clear the filtered cities list
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const formik = useFormik({
    initialValues: {
      email: "",
      nickname: "",
      password: "",
      hometown: "",
      month: "",
      year: "",
      date: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!checkBox1Checked || !checkBox2Checked) {
        setError("אנא בדוק את שני תיבות הסימון לפני שליחת הטופס.");
        return;
      } else {
        setError("");
        const data = {
          nickname: values.nickname,
          email: values.email,
          dateOfBirth: `${formik.values.year}-${formik.values.month}-${formik.values.date}`,
          password: values.password,
          city: hometownId,
          interest:
            TranslateGenderInterest.find(
              (gen) => gen.hebrew === isGenderInterest.trim()
            )?.english || "",
          gender:
            TranslateGender.find((gen) => gen.hebrew === gender.trim())
              ?.english || "",
        };
        dispatch(register(data));
      }
    },
  });

  useEffect(() => {
    if (isError) {
      setError(message);
    }

    if (isSuccess && user?.status == true) {
      console.log(user?.status);
      dispatch(reset());
      navigate("/verification", {
        state: {
          userData: {
            nickname: formik.values.nickname,
            email: formik.values.email,
            dateOfBirth: `${formik.values.year}-${formik.values.date}-${formik.values.month}`,
            password: formik.values.password,
            city: formik.values.hometown,
            interest:
              TranslateGenderInterest.find(
                (gen) => gen.hebrew === isGenderInterest.trim()
              )?.english || "",
            gender:
              TranslateGender.find((gen) => gen.hebrew === gender.trim())
                ?.english || "",
          },
        },
      });
    }
    if (isSuccess && user?.status == false) {
      setError(user?.message);
    }

    dispatch(reset());
  }, [isError, isSuccess, dispatch, message]);

  return (
    <>
      <form onSubmit={formik.handleSubmit} className=" space-y-3">
        {error && (
          <p style={{ color: Colors.primary, fontSize: "11px" }}>{error}</p>
        )}
        <Box className="sm:flex block sm:w-[80%] w-full sm:space-x-2 space-x-0 items-center">
          <Typography className="text-white sm:w-[20%] w-[30%]">
            {/* DOB: */}
            תאריך לידה:
          </Typography>
          <Box className="space-x-4 sm:w-[80%] w-[100%] flex items-center">
            <Box className="w-[100%]  space-x-2">
              <select
                className="sm:w-[26%] w-[30%]"
                name="year"
                id=""
                dir="btt"
                value={formik.values.year}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}>
                <option value="" selected disabled>
                  שנה
                </option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              <select
                className="sm:w-[26%] w-[30%]"
                name="month"
                id=""
                dir="btt"
                value={formik.values.month}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}>
                <option value="" selected disabled>
                  חודש
                </option>
                {months.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </select>
              <select
                className="sm:w-[26%] w-[30%]"
                name="date"
                id="date"
                value={formik.values.date}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}>
                <option value="" selected disabled>
                  יום
                </option>
                {days.map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
            </Box>
            {["year", "date", "month"]
              .map(
                (field) =>
                  formik.touched[field] &&
                  formik.errors[field] && (
                    <span key={field} className="text-[#EBD165] text-[11px]">
                      דרוש
                    </span>
                  )
              )
              .find(Boolean)}
          </Box>
        </Box>
        <Box>
          <Box className="flex space-x-2 items-center">
            <Box className="sm:flex block w-[80%] sm:space-x-2 space-x-0 items-center">
              <Typography className="text-white sm:w-[20%] w-[30%]">
                {/* Email: */}
                אמייל:
              </Typography>
              <input
                type="email"
                name="email"
                className="sm:w-[75%] w-[100%] sm:py-1.5 py-3 border-none px-5 text-[13px]"
                placeholder="בבקשה הזן את האימייל שלך"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Box>
            {formik.touched.email && formik.errors.email && (
              <span className="text-[#EBD165] text-[11px]">
                {formik.errors.email}
              </span>
            )}
          </Box>
          <Typography className="text-white">
            {/* * Filling out a correct email is necessary to complete the
            registration */}{" "}
            * מילוי אימייל תקין נחוץ להשלמת ההרשמה
          </Typography>
        </Box>
        <Box className="">
          <Box className="flex space-x-2 items-center">
            <Box className="sm:flex block w-[80%] sm:space-x-2 space-x-0 items-center">
              <Typography className="text-white sm:w-[20%] w-[30%]">
                {/* Nickname: */}
                כינוי :
              </Typography>
              <input
                type="text"
                name="nickname"
                className="sm:w-[75%] w-[100%] sm:py-1.5 py-3 border-none px-5 text-[13px]"
                placeholder="בעל 5-15 תווים"
                value={formik.values.nickname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Box>
            {formik.touched.nickname && formik.errors.nickname && (
              <span className="text-[#EBD165] text-[11px]">
                {formik.errors.nickname}
              </span>
            )}
          </Box>
        </Box>
        <Box className="">
          <Box className="flex space-x-2 items-center">
            <Box className="sm:flex block w-[80%] sm:space-x-2 space-x-0 items-center">
              <Typography className="text-white sm:w-[20%] w-[30%]">
                {/* Password: */}
                סיסמא :
              </Typography>
              <input
                type="password"
                name="password"
                className="sm:w-[75%] w-[100%] sm:py-1.5 py-3 border-none px-5 text-[13px]"
                placeholder="Please enter your password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Box>
            {formik.touched.password && formik.errors.password && (
              <span className="text-[#EBD165] text-[11px]">
                {formik.errors.password}
              </span>
            )}
          </Box>
        </Box>
        <Box className="relative">
          <Box className="flex space-x-2 items-center">
            <Box className="sm:flex block w-[80%] sm:space-x-2 space-x-0 items-center">
              <Typography className="text-white sm:w-[20%] w-[30%]">
                {/* Hometown: */}
                עיר מגורים :
              </Typography>
              <input
                type="text"
                name="hometown"
                className="sm:w-[75%] w-[100%] sm:py-1.5 py-3 border-none px-5 text-[13px]"
                placeholder="התחל להקליד ..."
                value={formik.values.hometown}
                onChange={handleSearchChange}
              />
            </Box>

            {formik.touched.hometown && formik.errors.hometown && (
              <span className="text-[#EBD165] text-[11px]">
                {formik.errors.hometown}
              </span>
            )}
          </Box>
          <span>* אנא המתינו להשלמה אוטומטית</span>
          {filteredCities.length > 0 && (
            <Box className="bg-white z-10 rounded-md absolute w-[80%] p-2 items-center justify-center flex-col">
              <ul className="space-y-2">
                {filteredCities.map((city, index) => (
                  <li
                    className="text-black capitalize text-[12px]"
                    key={index}
                    onClick={() =>
                      handleCitySelect(city.englishName, city.name)
                    }>
                    {city.englishName} ({city.name})
                  </li>
                ))}
              </ul>
            </Box>
          )}
        </Box>

        <Box>
          <FormControlLabel
            className="text-white text-[10px] space-x-2"
            control={
              <Checkbox
                checked={checkBox1Checked}
                onChange={(e) => setCheckBox1Checked(e.target.checked)}
                className="text-white text-[13px]"
              />
            }
            label={
              <Typography className="text-white text-[10px]">
                {" "}
                {/* I confirm that I have read and agreed to{" "} */}
                אני מאשר שקראתי והסכמתי ל
                <a
                  href="/terms"
                  className="text-white underline"
                  style={{ color: "white", textDecoration: "underline" }}>
                  {/* terms of use */}
                  תנאי השימוש
                </a>{" "}
                {/* I know that it is forbidden to advertise paid sex services and
                that the use of the site is from the age of 30 or older. */}{" "}
                ידוע לי שחל איסור לפרסם שירותי מין בתשלום וכי השימוש באתר הוא
                מגיל 18 ומעלה.
              </Typography>
            }
          />

          <FormControlLabel
            className="text-white text-[10px] space-x-2"
            control={
              <Checkbox
                checked={checkBox2Checked}
                onChange={(e) => setCheckBox2Checked(e.target.checked)}
                className="text-white text-[13px]"
              />
            }
            label={
              <Typography className="text-white text-[10px]">
                {" "}
                {/* I agree to receive advertising material */} אני מאשר קבלת
                חומר פרסומי
              </Typography>
            }
          />
        </Box>

        <button
          disabled={isLoading}
          type="submit"
          className="bg-gray-500 mt-1 hover:text-[#EBD165] text-[11px] w-full hover:bg-[#EBD165] border-none px-10 text-white rounded-md sm:py-1.5 py-2 reg-btn">
          {isLoading ? (
            <ClipLoader
              color={Colors.white}
              loading={isLoading}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            "התחבר"
          )}
        </button>
      </form>
    </>
  );
};

export default RegisterForm;
