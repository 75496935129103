import axios from "axios";

const API_URL = "https://api.datingnow-il.com";

const getGallery = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}/user/gallery/photos`, config);
  if (response.data) {
    localStorage.setItem("userPhotos", JSON.stringify(response.data.data));
  }

  return response.data.data;
};

const addGallery = async (info, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.patch(
    `${API_URL}/user/gallery/upload?Description=${info.data.description}&Privacy=${info.data.privacy}`,
    info.formData,
    config
  );

  return response.data;
};

const deleteGallery = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(
    `${API_URL}/user/gallery/photos/${id}`,
    config
  );

  return response.data;
};

const authService = {
  getGallery,
  addGallery,
  deleteGallery,
};

export default authService;
