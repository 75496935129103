import { Box } from "@mui/material";
import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import { Typography } from "antd";
import { menuItems } from "../../utils";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../features/auth/authSlice";

const ProfileSidebar = ({ isSidebar, isMobileSideBar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <Box className="lg:h-[90vh] h-[100vh] absolute md:w-[30%] lg:w-[17%] w-[79%] top-0 right-0 overflow-y-scroll ">
      {menuItems.map((item, index) => (
        <>
          {item.title == "התנתק" ? (
            <Box key={index} onClick={handleLogout} className="cursor-pointer">
              <Box className="sm:bg-[#fff] bg-[#383737] border-b border-gray-200 flex items-center px-2 py-2.5 space-x-1 justify-end">
                <Typography className="sm:text-[#000] text-white text-[14px] ">
                  {item.title}
                </Typography>
                {item.icon}
              </Box>
            </Box>
          ) : (
            <a key={index} href={item.link}>
              <Box className="sm:bg-[#fff] bg-[#383737] border-b border-gray-200 flex items-center px-2 py-2.5 space-x-1 justify-end">
                <Typography className="sm:text-[#000] text-white text-[14px] ">
                  {item.title}
                </Typography>
                {item.icon}
              </Box>
            </a>
          )}
        </>
      ))}
    </Box>
  );
};

export default ProfileSidebar;
