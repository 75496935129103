import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Typography } from "antd";
import { Close, InfoOutlined } from "@mui/icons-material";
import Colors from "../../helpers/Colors";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptFriend,
  addFavourite,
  blacklistFriend,
  declineFriend,
  getFriends,
  getRequestsRecieved,
  getRequestsSent,
  removeFavourites,
  removeFriend,
  sendRequest,
  unBlockFriend,
} from "../../features/friends/friendsSlice";
import Loader2 from "../Loader2";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { reportUser, reset } from "../../features/tos/tosSlice";

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "#fff",
  boxShadow: 24,
};

const RequestsModal = ({
  openRequestsModal,
  handleCloseRequestsModal,
  actionType,
  user,
}) => {
  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.friends
  );

  const {
    isLoading: loading,
    isError: error,
    isSuccess: success,
    message: msg,
  } = useSelector((state) => state.tos);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [reason, setReason] = React.useState();
  const [isReasonError, setIsReasonError] = React.useState(true);

  const handleRequest = () => {
    dispatch(sendRequest({ userId: user?.data?.id }));
  };
  const handleRemoveFriend = () => {
    dispatch(removeFriend(userId));
  };
  const handleAccept = () => {
    dispatch(acceptFriend({ userId: userId }));
  };

  const handleDecline = () => {
    dispatch(declineFriend(userId));
  };

  const handleUnblock = () => {
    dispatch(unBlockFriend(userId));
  };

  const handleBlacklist = () => {
    dispatch(blacklistFriend({ userId: user?.data?.id }));
  };

  const handleAddFavorite = () => {
    dispatch(addFavourite({ userId: userId }));
  };

  const handleRemoveFavorite = () => {
    dispatch(removeFavourites(userId));
  };

  const handleSendReport = () => {
    dispatch(reportUser({ userId, reason }));
  };

  React.useEffect(() => {
    if (success || error) {
      setTimeout(() => {
        dispatch(reset());
      }, 3000);
    }
  }, [success, error]);

  const handleReason = (value) => {
    if (value.trim().length < 20 || value.trim().length > 1000) {
      setIsReasonError(true);
    } else {
      setIsReasonError(false);
    }
    setReason(value.trim());
  };

  return (
    <div>
      <Modal
        open={openRequestsModal}
        onClose={handleCloseRequestsModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {actionType === "request" ? (
          <Box sx={style} className="h-[25vh] relative">
            <Box className="border  flex items-center justify-between py-2 px-3 bg-white">
              <Typography></Typography>
              <Typography className="font-bold">בקשת חברות</Typography>
              <Close
                onClick={handleCloseRequestsModal}
                style={{
                  border: "1px solid gray",
                  padding: "",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              />
            </Box>
            {isError && (
              <Alert
                icon={<InfoOutlined fontSize="inherit" />}
                severity="error"
              >
                {message}
              </Alert>
            )}
            {isSuccess && message === "sent" && (
              <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                בקשת חברות נשלחה בהצלחה
              </Alert>
            )}
            <Box className="flex absolute bottom-2 right-2 justify-end p-3">
              <button
                disabled={isLoading}
                onClick={handleRequest}
                className="py-2 px-4 rounded-md text-[11px] text-white mt-5"
                style={{ background: Colors.secondary }}
              >
                {isLoading ? <Loader2 /> : "שלח בקשה"}
              </button>
            </Box>
          </Box>
        ) : actionType === "removeFriend" ? (
          <Box sx={style} className="h-[25vh] relative">
            <Box className="border  flex items-center justify-between py-2 px-3 bg-white">
              <Typography></Typography>
              <Typography className="font-bold">הסר חבר</Typography>
              <Close
                onClick={handleCloseRequestsModal}
                style={{
                  border: "1px solid gray",
                  padding: "",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              />
            </Box>
            {isError && (
              <Alert
                icon={<InfoOutlined fontSize="inherit" />}
                severity="error"
              >
                {message}
              </Alert>
            )}
            {isSuccess && message === "removed" && (
              <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                החבר הוסר בהצלחה
              </Alert>
            )}
            <Box className="flex absolute bottom-2 right-2 justify-end p-3">
              <button
                disabled={isLoading}
                onClick={handleRemoveFriend}
                className="py-2 px-4 rounded-md text-[11px] text-white mt-5"
                style={{ background: Colors.secondary }}
              >
                {isLoading ? <Loader2 /> : "הסר חבר"}
              </button>
            </Box>
          </Box>
        ) : actionType === "acceptFriend" ? (
          <>
            <Box sx={style} className="h-[25vh] relative">
              <Box className="border  flex items-center justify-between py-2 px-3 bg-white">
                <Typography></Typography>
                <Typography className="font-bold">קבל חבר</Typography>
                <Close
                  onClick={handleCloseRequestsModal}
                  style={{
                    border: "1px solid gray",
                    padding: "",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
              </Box>
              {isError && (
                <Alert
                  icon={<InfoOutlined fontSize="inherit" />}
                  severity="error"
                >
                  {message}
                </Alert>
              )}
              {message.message === "You are friends already" && (
                <Alert severity="success">כבר חברים</Alert>
              )}
              {message.message === "Success" && (
                <Alert severity="success">בקשת החברות אושרה בהצלחה</Alert>
              )}
              <Box className="flex absolute bottom-2 right-2 justify-end p-3">
                <button
                  disabled={isLoading}
                  onClick={handleAccept}
                  className="py-2 px-4 rounded-md text-[11px] text-white mt-5"
                  style={{ background: Colors.secondary }}
                >
                  {isLoading ? <Loader2 /> : "אישור בקשת חברות"}
                </button>
              </Box>
            </Box>
          </>
        ) : actionType === "declineFriend" ? (
          <>
            <Box sx={style} className="h-[25vh] relative">
              <Box className="border  flex items-center justify-between py-2 px-3 bg-white">
                <Typography></Typography>
                <Typography className="font-bold">דחיית בקשה</Typography>
                <Close
                  onClick={handleCloseRequestsModal}
                  style={{
                    border: "1px solid gray",
                    padding: "",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
              </Box>
              {isError && (
                <Alert
                  icon={<InfoOutlined fontSize="inherit" />}
                  severity="error"
                >
                  {message}
                </Alert>
              )}

              {message === "declined" && (
                <Alert severity="info">בקשת החברות נדחתה בהצלחה</Alert>
              )}
              <Box className="flex absolute bottom-2 right-2 justify-end p-3">
                <button
                  disabled={isLoading}
                  onClick={handleDecline}
                  className="py-2 px-4 rounded-md text-[11px] text-white mt-5"
                  style={{ background: Colors.secondary }}
                >
                  {isLoading ? <Loader2 /> : "דחיית בקשת חברות"}
                </button>
              </Box>
            </Box>
          </>
        ) : actionType === "blackListFriend" ? (
          <>
            <Box sx={style} className="h-[25vh] relative">
              <Box className="border  flex items-center justify-between py-2 px-3 bg-white">
                <Typography></Typography>
                <Typography className="font-bold"> חסימת חבר</Typography>
                <Close
                  onClick={handleCloseRequestsModal}
                  style={{
                    border: "1px solid gray",
                    padding: "",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
              </Box>
              {isError && (
                <Alert
                  icon={<InfoOutlined fontSize="inherit" />}
                  severity="error"
                >
                  {message}
                </Alert>
              )}

              {message === "blacklisted" && (
                <Alert severity="info">החבר נחסם בהצלחה</Alert>
              )}
              <Box className="flex absolute bottom-2 right-2 justify-end p-3">
                <button
                  disabled={isLoading}
                  onClick={handleBlacklist}
                  className="py-2 px-4 rounded-md text-[11px] text-white mt-5"
                  style={{ background: Colors.secondary }}
                >
                  {isLoading ? <Loader2 /> : "חסום משתמש"}
                </button>
              </Box>
            </Box>
          </>
        ) : actionType === "unblock" ? (
          <>
            <Box sx={style} className="h-[25vh] relative">
              <Box className="border  flex items-center justify-between py-2 px-3 bg-white">
                <Typography></Typography>
                <Typography className="font-bold">ביטול חסימת משתמש</Typography>
                <Close
                  onClick={handleCloseRequestsModal}
                  style={{
                    border: "1px solid gray",
                    padding: "",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
              </Box>
              {isError && (
                <Alert
                  icon={<InfoOutlined fontSize="inherit" />}
                  severity="error"
                >
                  {message}
                </Alert>
              )}

              {message === "unblocked" && (
                <Alert severity="info">חבר שהוסר מחסום בהצלחה</Alert>
              )}
              <Box className="flex absolute bottom-2 right-2 justify-end p-3">
                <button
                  disabled={isLoading}
                  onClick={handleUnblock}
                  className="py-2 px-4 rounded-md text-[11px] text-white mt-5"
                  style={{ background: Colors.secondary }}
                >
                  {isLoading ? <Loader2 /> : "לבטל חסימת משתמש"}
                </button>
              </Box>
            </Box>
          </>
        ) : actionType === "addFavourite" ? (
          <>
            <Box sx={style} className="h-[25vh] relative">
              <Box className="border  flex items-center justify-between py-2 px-3 bg-white">
                <Typography></Typography>
                <Typography className="font-bold"> הוספה למועדפים </Typography>
                <Close
                  onClick={handleCloseRequestsModal}
                  style={{
                    border: "1px solid gray",
                    padding: "",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
              </Box>
              {isError && (
                <Alert
                  icon={<InfoOutlined fontSize="inherit" />}
                  severity="error"
                >
                  {message}
                </Alert>
              )}

              {message === "favourite_added" && (
                <Alert severity="success">החבר התווסף למועדפים בהצלחה</Alert>
              )}
              <Box className="flex absolute bottom-2 right-2 justify-end p-3">
                <button
                  disabled={isLoading}
                  onClick={handleAddFavorite}
                  className="py-2 px-4 rounded-md text-[11px] text-white mt-5"
                  style={{ background: Colors.secondary }}
                >
                  {isLoading ? <Loader2 /> : "הוסף משתמש לרשימת המועדפים"}
                </button>
              </Box>
            </Box>
          </>
        ) : actionType === "removeFavourite" ? (
          <>
            <Box sx={style} className="h-[25vh] relative">
              <Box className="border  flex items-center justify-between py-2 px-3 bg-white">
                <Typography></Typography>
                <Typography className="font-bold"> הסר מהמועדפים </Typography>
                <Close
                  onClick={handleCloseRequestsModal}
                  style={{
                    border: "1px solid gray",
                    padding: "",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
              </Box>
              {isError && (
                <Alert
                  icon={<InfoOutlined fontSize="inherit" />}
                  severity="error"
                >
                  {message}
                </Alert>
              )}

              {message === "favourite_removed" && (
                <Alert severity="info">החבר הוסר מרשימת המועדפים בהצלחה</Alert>
              )}
              <Box className="flex absolute bottom-2 right-2 justify-end p-3">
                <button
                  disabled={isLoading}
                  onClick={handleRemoveFavorite}
                  className="py-2 px-4 rounded-md text-[11px] text-white mt-5"
                  style={{ background: Colors.secondary }}
                >
                  {isLoading ? <Loader2 /> : "הסר משתמש מרשימת המועדפים"}
                </button>
              </Box>
            </Box>
          </>
        ) : actionType === "sendReport" ? (
          <>
            <Box sx={style} className="p-2 bg-white relative">
              <Box className="border  flex items-center justify-between py-2 px-3 bg-white">
                <Typography></Typography>
                <Typography className="font-bold"> שלח דו"ד </Typography>
                <Close
                  onClick={handleCloseRequestsModal}
                  style={{
                    border: "1px solid gray",
                    padding: "",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
              </Box>
              {error && (
                <Alert
                  icon={<InfoOutlined fontSize="inherit" />}
                  severity="error"
                >
                  {msg}
                </Alert>
              )}

              {msg === "report sent" && (
                <Alert severity="info">הדיווח נשלח בהצלחה</Alert>
              )}
              <Box>
                <Typography>הסיבה:</Typography>
                <Typography className="text-gray-500 text-[11px] mb-2">
                  הסיבה לדיווח.
                </Typography>
                <textarea
                  cols={7}
                  onChange={(e) => handleReason(e.target.value)}
                  rows={5}
                  className="w-full border-gray-200 outline-none"
                  style={{
                    border: "1px solid gray",
                    borderRadius: "5px",
                    padding: 5,
                  }}
                ></textarea>
              </Box>
              <Box className="flex  bottom-2 right-2 justify-end p-3">
                <button
                  disabled={isReasonError}
                  onClick={handleSendReport}
                  className="py-2 px-4 rounded-md text-[11px] text-white mt-5"
                  style={{
                    background: isReasonError ? Colors.gray : Colors.secondary,
                  }}
                >
                  {loading ? <Loader2 /> : "שלח"}
                </button>
              </Box>
            </Box>
          </>
        ) : (
          <></>
        )}
      </Modal>
    </div>
  );
};

export default RequestsModal;
