import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ticketType } from "../../utils/Translations";
import { useDispatch, useSelector } from "react-redux";
import {
  createAnonymousTicket,
  createTicket,
} from "../../features/tos/tosSlice";
import { Alert, Box } from "@mui/material";
import { CheckCircleOutline, InfoOutlined } from "@mui/icons-material";
import { ClipLoader } from "react-spinners";
import Colors from "../../helpers/Colors";
import { Link } from "react-router-dom";

const CreateAnyTicket = () => {
  const dispatch = useDispatch();

  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.tos
  );

  const initialValues = {
    message: "",
    type: "",
  };

  const validationSchema = Yup.object({
    message: Yup.string()
      .min(1, "מינימום תו אחד")
      .max(2500, "מקסימום 2500 תווים")
      .required("נדרשת הודעה"),
    type: Yup.string().required("נדרשת קטגוריה"),
    email: Yup.string()
      .email("הוזנה כתובת דואר אלקטרוני לא תקינה")
      .required("דרושה כתובת דואר אלקטרוני"),
  });

  const onSubmit = (values) => {
    dispatch(createAnonymousTicket(values));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="max-w-md mx-auto sm:p-8 p-2 ">
      <h1 className="text-2xl font-bold mb-4 text-yellow-600">צור כרטיס</h1>
      {isSuccess && (
        <Alert
          icon={<CheckCircleOutline fontSize="inherit" />}
          severity="success"
        >
          הכרטיס נשלח בהצלחה
        </Alert>
      )}
      {isError && (
        <Alert icon={<InfoOutlined fontSize="inherit" />} severity="success">
          {message}
        </Alert>
      )}
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
            הודעה:
          </label>
          <input
            id="email"
            name="email"
            placeholder="הזן את כתובת האימייל שלך...."
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            style={{ border: "1px solid gray" }}
            className="w-full px-3 py-2 border rounded-lg  focus:ring focus:border-blue-300"
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-500 text-[10px]">
              {formik.errors.email}
            </div>
          ) : null}
        </div>
        <div className="mb-4">
          <label
            htmlFor="message"
            className="block text-gray-700 font-bold mb-2"
          >
            הודעה:
          </label>
          <textarea
            id="message"
            name="message"
            rows="10"
            placeholder="הקלד את הדאגות שלך...."
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={{ border: "1px solid gray" }}
            value={formik.values.message}
            className="w-full px-3 py-2 border rounded-lg  focus:ring focus:border-blue-300"
          />
          {formik.touched.message && formik.errors.message ? (
            <div className="text-red-500 text-[10px]">
              {formik.errors.message}
            </div>
          ) : null}
        </div>
        <div className="mb-4">
          <label htmlFor="type" className="block text-gray-700 font-bold mb-2">
            קטגוריה:
          </label>
          <select
            id="type"
            name="type"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.type}
            className="w-full px-3 py-2  rounded-lg  focus:ring focus:border-blue-300"
            style={{ border: "1px solid gray" }}
          >
            <option value="">בחר סוג</option>
            {ticketType.map((option) => (
              <option key={option.english} value={option.english}>
                {option.hebrew}
              </option>
            ))}
          </select>
          {formik.touched.type && formik.errors.type ? (
            <div className="text-red-500 text-[10px]">{formik.errors.type}</div>
          ) : null}
        </div>
        {isLoading ? (
          <button
            className="px-3 py-1 text-[12px] mt-3 text-white rounded-md"
            style={{ background: Colors.primary2 }}
            disabled
          >
            <ClipLoader
              color={Colors.white}
              loading={isLoading}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </button>
        ) : (
          <Box className="space-x-2">
            <button
              type="submit"
              className="px-3 py-1 text-[12px] mt-3 text-white rounded-md"
              style={{ background: Colors.primary2 }}
            >
              שלח
            </button>
          </Box>
        )}
      </form>
    </div>
  );
};

export default CreateAnyTicket;
