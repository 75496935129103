import axios from "axios";

const API_URL = "https://api.datingnow-il.com";

//Register user
const register = async (userData) => {
  const response = await axios.post(
    `https://api.datingnow-il.com/user/register`,
    userData,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (response.data.status === true) {
    localStorage.setItem("registration1", JSON.stringify(userData));
  }
  return response.data;
};

const resendEmail = async (userData) => {
  const response = await axios.post(
    `https://api.datingnow-il.com/user/email/signup/resend`,
    userData,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

const verify = async (userData) => {
  const response = await axios.post(
    `https://api.datingnow-il.com/user/email/signup/confirm`,
    userData,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (response.data.status === true) {
    localStorage.setItem("registration", JSON.stringify(response.data));
    localStorage.removeItem("registration1");
    return response.data;
  }
};

//logout

const logout = async () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("friends");
  localStorage.removeItem("requestSent");
  localStorage.removeItem("requestRecieved");
  localStorage.removeItem("userPhotos");
  localStorage.removeItem("blacklist");
  localStorage.removeItem("likes");
  localStorage.removeItem("favourites");
  localStorage.removeItem("messages");
  localStorage.removeItem("likeNotifications");
  localStorage.removeItem("friendsRequestNotifications");
};

export const logoutError = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("friends");
  localStorage.removeItem("requestSent");
  localStorage.removeItem("requestRecieved");
  localStorage.removeItem("userPhotos");
  localStorage.removeItem("blacklist");
  localStorage.removeItem("likes");
  localStorage.removeItem("favourites");
  localStorage.removeItem("messages");
  localStorage.removeItem("likeNotifications");
  localStorage.removeItem("friendsRequestNotifications");
};
//login

const login = async (userData) => {
  const response = await axios.post(
    `https://api.datingnow-il.com/user/login`,
    userData
  );

  if (response.data && response.data.status === true) {
    localStorage.setItem("token", JSON.stringify(response.data));
  }
  return response.data;
};

//update

const update = async (userData) => {
  console.log(userData);
  const response = await axios.patch(
    `${API_URL}/user/register/complete`,
    userData
  );

  if (response.data && response.data.status === true) {
    localStorage.removeItem("registration");
  }

  return response.data;
};

const getDetails = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}/user/details`, config);

  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }

  return response.data;
};

const updatePhoto = async (photo, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(`${API_URL}/user/setphoto`, photo, config);

  return response.data;
};

const getAllUsers = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}/user/search`, config);

  if (response.data) {
    localStorage.setItem("users", JSON.stringify(response.data));
  }

  return response.data;
};

const updatePersonalInfo = async (user, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.patch(
    `${API_URL}/user/edit/personal`,
    user,
    config
  );

  return response.data;
};

const updateAccountInfo = async (user, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.patch(
    `${API_URL}/user/edit/account-info`,
    user,
    config
  );

  return response.data;
};

const updateAboutInfo = async (user, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.patch(
    `${API_URL}/user/edit/about`,
    user,
    config
  );

  return response.data;
};

const updatePassword = async (user, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.patch(
    `${API_URL}/user/password/change`,
    user,
    config
  );

  return response.data;
};

const updateEmail = async (user, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.patch(
    `${API_URL}/user/email/change`,
    user,
    config
  );

  return response.data;
};

const forgotPassword = async (user, token) => {
  const response = await axios.post(`${API_URL}/user/password/reset`, user);

  return response.data;
};

const confirmPassword = async (user, token) => {
  const response = await axios.post(
    `${API_URL}/user/password/reset/confirm`,
    user
  );

  return response.data;
};

const confirmEmail = async (user, token) => {
  const response = await axios.patch(
    `${API_URL}/user/email/change/confirm`,
    user
  );

  return response.data;
};

const makePayments = async (cardDetails, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(`${API_URL}/payment`, cardDetails, config);

  return response.data;
};

const authService = {
  register,
  update,
  logout,
  login,
  verify,
  resendEmail,
  getDetails,
  updatePhoto,
  getAllUsers,
  updateAccountInfo,
  updatePersonalInfo,
  updateAboutInfo,
  updatePassword,
  updateEmail,
  forgotPassword,
  confirmPassword,
  confirmEmail,
  makePayments,
};

export default authService;
