import React, { useEffect, useState } from "react";
import GeneralNavbar from "../../components/Navbars/GeneralNavbar";
import { Box, Grid } from "@mui/material";
import { Typography } from "antd";
import Colors from "../../helpers/Colors";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Carousel } from "antd";
import GeneralFooter from "../../components/Navbars/GeneralFooter";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const How_It_Works = () => {
  const [slidesToShow2, setSlidesToShow2] = useState(2);
  const { user, token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("registration"));
    const statusRegistration = JSON.parse(
      localStorage.getItem("registration1")
    );

    if (user?.status === true || token?.status === true) {
      navigate("/main");
    }
    if (userData) {
      navigate("/complete-reg", {
        state: {
          userData,
        },
      });
    }

    if (statusRegistration) {
      navigate("/verification");
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSlidesToShow2(1);
      } else {
        setSlidesToShow2(2);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "none",
          border: "1px solid #fff",
          width: "25px",
          height: "25px",
          borderRadius: "50%",
          marginRight: 20,
          marginTop: -25,
        }}
        onClick={onClick}
      >
        <ArrowForwardIosIcon
          style={{
            color: "#fff",
            fontSize: "17px",
            marginTop: 3,
            marginLeft: 2,
          }}
        />
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "none",
          border: "1px solid #fff",
          width: "25px",
          height: "25px",
          borderRadius: "50%",
          marginLeft: 20,
          marginTop: -25,
        }}
        onClick={onClick}
      >
        <ArrowBackIosNewIcon
          style={{ color: "#fff", fontSize: "17px", marginTop: 3 }}
        />
      </div>
    );
  };

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <>
      <GeneralNavbar />
      <Box>
        <Box className="how_it_works">
          <Box className=" py-24 sm:w-[85%] w-[95%] m-auto">
            <Grid container>
              <Grid item xs={12} sm={12} md={7}></Grid>
              <Grid item xs={12} sm={12} md={5}>
                <Box className="">
                  <Typography
                    className="text-[60px] font-bold  mb-5"
                    style={{ color: Colors.primary, lineHeight: "4rem" }}
                  >
                    מוצאים בשבילכם את הדייט המושלםu
                  </Typography>
                  <Typography
                    className="text-[18px] font-semibold text-white"
                    style={{ lineHeight: "1.5rem" }}
                  >
                    בין אלפי חברי אתר SUGAR DADDY <br />
                    תוכלו לפגוש חברים יפים ומיוחדים <br />
                    המחפשים יחסים בתנאים שלך
                  </Typography>
                  <button
                    className="font-bold py-3 rounded-md mt-8 px-12 text-white text-[15px]"
                    style={{ backgroundColor: Colors.secondary }}
                  >
                    {/* Free Registeration */} הרשמה חינם
                  </button>{" "}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className="howb">
          <Box className=" py-16 sm:w-[85%] w-[95%] m-auto">
            <Grid container spacing={6}>
              <Grid item xs={12} sm={12} md={4}>
                <Box className="text-center flex flex-col items-center justify-center">
                  <Box className="w-[10vh] h-[10vh] flex flex-col items-center justify-center text-center rounded-full border">
                    <Typography className="font-normal text-[35px]">
                      1
                    </Typography>
                  </Box>
                  <Typography
                    className="text-[25px] mt-3"
                    style={{ color: Colors.secondary }}
                  >
                    {/* Joining */} מצטרפים
                  </Typography>
                  <Typography>
                    {/* Join thousands of our friends, endless attractive women and
                    affluent men Registeration is fast and free */}
                    מצטרפים לאלפי החברים שלנו, אינסוף <br />
                    נשים אטרקטיביות וגברים אמידים. <br />
                    ההרשמה מהירה ובחינם
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Box className="text-center flex flex-col items-center justify-center">
                  <Box className="w-[10vh] h-[10vh] flex flex-col items-center justify-center text-center rounded-full border">
                    <Typography className="font-normal text-[35px]">
                      2
                    </Typography>
                  </Box>
                  <Typography
                    className="text-[25px] mt-3"
                    style={{ color: Colors.secondary }}
                  >
                    מחפשים
                  </Typography>
                  <Typography>
                    השלימו את הפרופיל, הוסיפו תמונה <br />
                    להגדלת הסיכוי שלכם חפשו את הדייט <br />
                    המושלם בשבילכם בתנאים שלכם
                    {/* look for the perfect date for you on your terms */}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Box className="text-center flex flex-col items-center justify-center">
                  <Box className="w-[10vh] h-[10vh] flex flex-col items-center justify-center text-center rounded-full border">
                    <Typography className="font-normal text-[35px]">
                      3
                    </Typography>
                  </Box>
                  <Typography
                    className="text-[25px] mt-3"
                    style={{ color: Colors.secondary }}
                  >
                    {/* Meet */} נפגשים
                  </Typography>
                  <Typography>
                    {/* A bar, a romantic restaurant or just spending time hand in
                    hand on the beach, go out and celebrate this evening */}
                    בר, מסעדה רומנטית או סתם <br />
                    בילוי יד ביד על חוף הים צאו <br />
                    לחגוג עוד הערב
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/*what arre*/}
        <Box className="whatare">
          <Box className="sm:w-[60%] w-[95%] m-auto sm:py-8 py-5">
            <Carousel
              slidesToShow={slidesToShow2}
              autoplay
              dots={false}
              arrows
              {...settings}
            >
              <Box className=" sm:p-10 p-5 mb-4 text-center grid place-items-center">
                <Box>
                  <img
                    src={require("../../assets/images/testi-face-3.png")}
                    alt=""
                    className="sm:w-[30%] w-[30%] m-auto rounded-full"
                  />

                  <Typography
                    className="font-semibold mt-2 text-[30px]"
                    style={{ color: Colors.primary }}
                  >
                    מה זה שוגר בייבי?
                  </Typography>

                  <Typography className="text-white font-normal mt-2 text-[13px]">
                    {/* Sugar Babies are spoiled, independent and attractive girls
                    who know what they want. Interesting conversational women
                    who will be happy to go on a business trip with you, see the
                    wonderland and enjoy the good life */}
                    שוגר בייביז הן בנות מפונקות, עצמאיות ואטרקטיביות שיודעות מה
                    הן רוצות. נשות שיחה מעניינות שישמחו לצאת איתך לטיול עסקים,
                    לראות עולם ולהינות מהחיים הטובים.
                  </Typography>
                </Box>
              </Box>
              <Box className=" sm:p-10 p-5 mb-4 text-center ">
                <Box>
                  <img
                    src={require("../../assets/images/testi-face-4.png")}
                    alt=""
                    className="sm:w-[30%] w-[30%] m-auto rounded-full"
                  />

                  <Typography
                    className="font-semibold mt-2 text-[30px]"
                    style={{ color: Colors.primary }}
                  >
                    {/* What is Sugar Daddy? */} מה זה שוגר דדי?
                  </Typography>

                  <Typography className="text-white font-normal mt-2 text-[13px]">
                    לשוגר דדי'ז שלנו אין זמן למשחקים, הם גברים אמידים ומצליחים
                    שיודעים בדיוק מה הם מחפשים. כסף לא משחק אצלם תפקיד והם
                    ידועים בנדבנותם לשוגר ביבי שלהם
                  </Typography>
                </Box>
              </Box>
            </Carousel>
          </Box>
        </Box>

        <Box className=" py-16  text-center sm:w-[55%] w-[95%] m-auto">
          <Typography className="text-[18px]">
            לכל אדם החופש לבחור. <br />
            לכל אדם היכולת בנקודות בחייו, דרך הבחירות שהוא עושה להמריא אל על.{" "}
            <br />
            החופש לבחור בזוגיות בדרך שלך, <br />
            החופש לבחור מי אתה, החופש לבחור בתנאים שלך
          </Typography>
          <button
            className="font-bold py-3 mt-10 rounded-md px-12 text-white text-[15px]"
            style={{ backgroundColor: Colors.secondary }}
          >
            {/* Free Registeration */} הרשמה חינם
          </button>{" "}
        </Box>
      </Box>
      <GeneralFooter />
    </>
  );
};

export default How_It_Works;
