import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import singleUserSlice from "../features/singleUsers/singleUserSlice";
import gallerySlice from "../features/gallery/gallerySlice";
import friendsSlice from "../features/friends/friendsSlice";
import citiesSlice from "../features/cities/citiesSlice";
import tosSlice from "../features/tos/tosSlice";
import notificationsSlice from "../features/notifications/notificationsSlice";
import mailSlice from "../features/mail/mailSlice";
import signalRSlice from "../features/signalR/signalRSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    singleUser: singleUserSlice,
    gallery: gallerySlice,
    friends: friendsSlice,
    cities: citiesSlice,
    tos: tosSlice,
    notifications: notificationsSlice,
    mail: mailSlice,
    // signalR: signalRSlice,
  },
});
