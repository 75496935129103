import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "antd";
import Colors from "../../helpers/Colors";
import { useFormik } from "formik";
import { loginSchema } from "../../utils/Schemas";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, reset } from "../../features/auth/authSlice";
import { ClipLoader } from "react-spinners";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#f1f1f1",
  border: "2px solid #000",
  boxShadow: 24,
};

export default function LoginModal({
  openModal,
  setOpenModal,
  handleOpen,
  handleClose,
}) {
  const dispatch = useDispatch();
  const [Error, setIsError] = React.useState();
  const [loginMode, setLoginMode] = React.useState("email"); // Default mode: email
  const { user, token, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      username: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      if (
        (loginMode === "email" && values.email) ||
        (loginMode === "username" && values.username)
      ) {
        const data = {
          email: loginMode === "email" ? values.email : null,
          password: values.password,
          username: loginMode === "username" ? values.username : null,
        };
        setIsError("");
        dispatch(login(data));
      } else {
        setIsError("שם משתמש או כתובת דוא נדרשים");
      }
    },
  });

  React.useEffect(() => {
    if (isError) {
      setIsError(message);
    }

    if (isSuccess && token?.status == true) {
      dispatch(reset());
      navigate("/main", {});
    }

    if (isSuccess && token?.status == false) {
      setIsError(token?.message);
    }

    if (
      isSuccess &&
      token?.status == false &&
      token?.message == "You must complete the registration process"
    ) {
      localStorage.setItem(
        "registration",
        JSON.stringify({
          email: formik.values.email ? formik.values.email : null,
          username: formik.values.username ? formik.values.username : null,
        })
      );
      navigate("/complete-reg");
    }

    dispatch(reset());
  }, [isError, isSuccess, dispatch, message]);

  const toggleLoginMode = () => {
    setLoginMode((prevMode) => (prevMode === "email" ? "username" : "email"));
    formik.setFieldValue("email", "");
  };
  return (
    <div className="p-3">
      <Modal
        open={openModal}
        className="m-3"
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className=" relative bg-[#eae9e7]">
          <Box className="bg-white flex items-center justify-between px-4 py-1 right-10">
            <Typography className="text-[12px]">חיבור</Typography>
            <CloseIcon
              className="text-[1px]"
              color="gray"
              onClick={handleClose}
              style={{ fontSize: "18px", color: "gray", cursor: "pointer" }}
            />
          </Box>
          <Box className="mt-5 py-2 px-4">
            <Typography className="text-dark">משתמש רשום?</Typography>
            <Typography
              className="font-bold text-[18px]"
              style={{ color: Colors.secondary }}
            >
              התחברות (לקוח קיים)
            </Typography>
            <Typography className="text-[12px] font-semibold">
              אם יש לך מנוי? עשית את זה! הזן את הפרטים.
            </Typography>
            {Error && (
              <Typography className="text-[11px] text-red-500 my-2">
                {Error}
              </Typography>
            )}
            <Box className="flex justify-center mt-2">
              <button
                onClick={toggleLoginMode}
                variant="text"
                size="small"
                style={{ background: Colors.primary }}
                className="w-full py-2 rounded-md text-white text-[12px] "
              >
                {loginMode === "email"
                  ? "התחבר עם שם משתמש"
                  : "התחבר עם כתובת האימייל"}
              </button>
            </Box>
            <Box>
              <form onSubmit={formik.handleSubmit} className="mt-2 space-y-3">
                <Box className="mt-2 space-y-3">
                  {loginMode === "email" && (
                    <Box className="space-y-2">
                      <input
                        type="email"
                        name="email"
                        className="sm:w-[75%] w-[100%] sm:py-1.5 py-3 bg-white px-5 text-[13px]"
                        placeholder="אנא הזן/י את האימייל שלך."
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <span className="text-[#EBD165] text-[11px]">
                          {formik.errors.email}
                        </span>
                      )}
                    </Box>
                  )}
                  {loginMode === "username" && (
                    <Box className="space-y-2">
                      <input
                        type="text"
                        name="username"
                        className="sm:w-[75%] w-[100%] sm:py-1.5 py-3 bg-white px-5 text-[13px]"
                        placeholder="אנא הזן/י את שם המשתמש שלך."
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.username && formik.errors.username && (
                        <span className="text-[#EBD165] text-[11px]">
                          {formik.errors.username}
                        </span>
                      )}
                    </Box>
                  )}
                  <Box className="">
                    <input
                      type="password"
                      name="password"
                      className="sm:w-[75%] w-[100%] sm:py-1.5 py-3 bg-white px-5 text-[13px]"
                      placeholder="אנא הזן/י את הסיסמה שלך"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <span className="text-[#EBD165] text-[11px]">
                        {formik.errors.password}
                      </span>
                    )}
                  </Box>

                  <Link to="/forgot_password">
                    <Typography className="text-[11px]">שכחתי סיסמה</Typography>
                  </Link>

                  <button
                    type="submit"
                    className="bg-[#D71D6D] mt-5 hover:text-[#EBD165] text-[11px]  hover:bg-[#EBD165] border-none px-10 text-white rounded-md sm:py-1.5 py-2 reg-btn"
                  >
                    {isLoading ? (
                      <ClipLoader
                        color={Colors.white}
                        loading={isLoading}
                        size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    ) : (
                      "התחבר"
                    )}
                  </button>

                  <Typography className="text-[11px] -mt-2">
                    אל דאגה, פרטי החשבון שלך לעולם לא יועשה בהם שימוש!
                  </Typography>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
