import axios from "axios";

const API_URL = "https://api.datingnow-il.com";

const getFriends = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}/friend`, config);

  if (response.data) {
    localStorage.setItem("friends", JSON.stringify(response.data.data));
  }
  return response.data.data;
};

const getBlacklist = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}/blacklist`, config);

  if (response.data) {
    localStorage.setItem("blacklist", JSON.stringify(response.data.data));
  }
  return response.data.data;
};

const getFavouriteList = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}/like/favorites`, config);

  if (response.data) {
    localStorage.setItem("favorites", JSON.stringify(response.data.data));
  }
  return response.data.data;
};

const getLikeList = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}/like`, config);

  if (response.data) {
    localStorage.setItem("likes", JSON.stringify(response.data.data));
  }
  return response.data.data;
};

const blacklistFriend = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${API_URL}/blacklist`, data, config);
  return response.data;
};

const unBlockFriend = async (token, userId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(`${API_URL}/blacklist/${userId}`, config);
  return response.data;
};

const requestsSent = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}/friend/request/sent`, config);
  if (response.data) {
    localStorage.setItem("requestSent", JSON.stringify(response.data.data));
  }
  return response.data.data;
};

const requestsRecieved = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${API_URL}/friend/request/received`,
    config
  );
  if (response.data) {
    localStorage.setItem("requestRecieved", JSON.stringify(response.data.data));
  }
  return response.data.data;
};

const sendRequest = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${API_URL}/friend/request`, data, config);
  return response.data;
};

const removeFriend = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(
    `${API_URL}/friend/unfriend/${id}`,
    config
  );
  return response.data;
};

const acceptFriend = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.patch(`${API_URL}/friend/accept`, data, config);

  console.log(response.data);
  return response.data;
};

const declineFriend = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(
    `${API_URL}/friend/decline/${id}`,
    config
  );
  return response.data;
};

const removeFavourites = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(`${API_URL}/like/${id}`, config);
  return response.data;
};

const AddFavourite = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${API_URL}/like`, data, config);
  return response.data;
};

const authService = {
  getFriends,
  requestsSent,
  requestsRecieved,
  sendRequest,
  removeFriend,
  acceptFriend,
  declineFriend,
  getBlacklist,
  blacklistFriend,
  unBlockFriend,
  AddFavourite,
  removeFavourites,
  getLikeList,
  getFavouriteList,
};

export default authService;
