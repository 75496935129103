import React, { useEffect, useState } from "react";
import GeneralNavbar from "../../components/Navbars/GeneralNavbar";
import { Box, Grid } from "@mui/material";
import { Typography } from "antd";
import Colors from "../../helpers/Colors";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Carousel } from "antd";
import GeneralFooter from "../../components/Navbars/GeneralFooter";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CreateAnyTicket from "../../components/Tickets/CreateAnyTicket";

const AnyTickets = () => {
  const [slidesToShow2, setSlidesToShow2] = useState(2);
  const { user, token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("registration"));
    const statusRegistration = JSON.parse(
      localStorage.getItem("registration1")
    );

    if (user?.status === true || token?.status === true) {
      navigate("/main");
    }
    if (userData) {
      navigate("/complete-reg", {
        state: {
          userData,
        },
      });
    }

    if (statusRegistration) {
      navigate("/verification");
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSlidesToShow2(1);
      } else {
        setSlidesToShow2(2);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <GeneralNavbar />
      <Box className="h-min-screen">
        <CreateAnyTicket />
      </Box>
      <GeneralFooter />
    </>
  );
};

export default AnyTickets;
