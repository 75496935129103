import { Box, Grid } from "@mui/material";
import React from "react";
import logo from "../../assets/images/logo.png";
import { Typography } from "antd";
import Colors from "../../helpers/Colors";

const GeneralFooter = () => {
  return (
    <Box>
      <Box className="" style={{ backgroundColor: Colors.black }}>
        <Box className="px-7 py-7 bg-[#000] relative  sm:block flex items-center">
          <Box className="flex sm:flex-row flex-col items-center  justify-between">
            <Box className="sm:w-[29%]  w-[74%] text-center  sm:block hidden">
              <img
                src={logo}
                alt="Logo"
                className="sm:w-[85%] w-[100%] m-auto"
              />
              <Box className="  text-center ">
                <Typography variant="h6" className="text-[13px] text-[#9f9aae]">
                  All rights reserved to Sugar Dadi. <br /> We are not
                  responsible for the content.{" "}
                  <span href="" className="text-white">
                    Terms of Us
                  </span>
                </Typography>
              </Box>
            </Box>
            <Box className="flex sm:w-[30%] w-[100%]  flex-col sm:items-end items-center py-2">
              <Typography
                className="text-[24px] font-bold"
                style={{ color: Colors.primary }}
              >
                Quick Links
              </Typography>
              <Grid
                container
                spacing={2}
                className="flex items-center justify-center "
              >
                <Grid item sm={6}>
                  <ul className="footer-links">
                    <li className="sm:text-right text-center">
                      <a href="/how_it_works">איך זה עובד?</a>
                    </li>
                    <li className="sm:text-right text-center">
                      <a href="/terms">תקנון</a>
                    </li>
                    <li className="sm:text-right text-center">
                      <a href="/tickets">צור קשר </a>
                    </li>
                    <li className="sm:text-right text-center">&nbsp</li>
                  </ul>
                </Grid>
                <Grid item sm={6}>
                  <ul className="footer-links">
                    <li className="sm:text-right text-center">
                      <a href="/about"> אודות שוגר דדי</a>
                    </li>
                    <li className="sm:text-right text-center">
                      <a href="/register">הרשמה חינם</a>
                    </li>
                    {/* <li className="sm:text-right text-center">
                      <a href="">ביטול חברות</a>
                    </li> */}
                    <li className="sm:text-right text-center">
                      <a href="/blog">הבלוג שלנו</a>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Box>
            <Box className="sm:w-[29%] mt-5  w-[74%] text-center  sm:hidden block">
              <img
                src={logo}
                alt="Logo"
                className="sm:w-[85%] w-[100%] m-auto"
              />
              <Box className="  text-center ">
                <Typography variant="h6" className="text-[13px] text-[#9f9aae]">
                  כל הזכויות שמורות לשוגר דדי <br />
                  אנחנו לא אחראים לתוכן.
                  <span href="" className="text-white">
                    תנאי שימוש
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralFooter;
