import axios from "axios";

const API_URL = "https://api.datingnow-il.com";

//Register user
const getCities = async () => {
  const response = await axios.get(`https://api.datingnow-il.com/city`);

  if (response.data) {
    localStorage.setItem("cities", JSON.stringify(response.data));
  }
  return response.data;
};

const authService = {
  getCities,
};

export default authService;
