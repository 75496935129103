import React from "react";
import ProfileNavbar from "../../components/Navbars/ProfileNavbar";
import { Box, Grid } from "@mui/material";
import UserDetailsCard from "../../components/Profile/UserDetailsCard";
import PersonalDetils from "../../components/Profile/update-profile/Personal";
import AccountDetails from "../../components/Profile/update-profile/Account";
import Pestle from "../../components/Profile/update-profile/Pestle";

const UpdatePestleProfile = () => {
  return (
    <>
      <ProfileNavbar />
      <Box className="bg-[#eae9e7] sm:h-min-screen h-screen ">
        <Box className="sm:w-[94%] m-auto w-[98%] py-8">
          <Grid container className="space-y-4">
            <Grid
              xs={12}
              sm={12}
              md={3}
              className="border sm:px-6 px-0 w-full "
            ></Grid>

            <Grid xs={12} sm={12} md={6}>
              <Pestle />
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={3}
              className="sm:px-6 px-0 sm:block hidden"
            >
              <UserDetailsCard />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default UpdatePestleProfile;
