import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import mailService from "./mailService";
import { logoutError } from "../auth/authService";

const messages = JSON.parse(localStorage.getItem("messages"));

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  mails: messages ? messages : [],
  singleMails: [],
};

export const sendMessage = createAsyncThunk(
  "mail/sendMessage",
  async (message, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await mailService.sendMessage(token, message);
      if (data.status === false) {
        return thunkAPI.rejectWithValue(data.message);
      }

      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendMessagePhoto = createAsyncThunk(
  "mail/sendMessagePhoto",
  async (message, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await mailService.sendMessagePhoto(token, message);
      if (data.status === false) {
        return thunkAPI.rejectWithValue(data.message);
      }
      console.log(data);

      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllMessage = createAsyncThunk(
  "mail/getAllMessage",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await mailService.getAllMessage(token);
      if (data.status === false) {
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateMessagesBySignalR = createAsyncThunk(
  "mail/updateMessagesBySignalR",
  async (data, thunkAPI) => {
    try {
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const mailSlice = createSlice({
  name: "mail",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "message sent";
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(sendMessagePhoto.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendMessagePhoto.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "message sent";
      })
      .addCase(sendMessagePhoto.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllMessage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllMessage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.mails = action.payload;
      })
      .addCase(getAllMessage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateMessagesBySignalR.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateMessagesBySignalR.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.mails = action.payload;
      })
      .addCase(updateMessagesBySignalR.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = mailSlice.actions;
export default mailSlice.reducer;
