import React from "react";
import ProfileNavbar from "../../components/Navbars/ProfileNavbar";
import { Box, Grid } from "@mui/material";
import Sidebar from "../../components/Profile/Sidebar";
import { Typography } from "antd";
import CreditCardPayment from "../../components/Profile/CreditCardPayment";

const Contact = () => {
  return (
    <>
      <ProfileNavbar />

      <Box className="sm:w-[95%] m-auto w-[94%] py-12">
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            className="h-[60vh] items-center justify-center flex flex-col">
            <Box className="border bg-gray-200 rounded-md sm:w-[60%] m-auto p-5 shadow-md">
              <Typography className="font-black my-5 text-[25px]">
                הפעל את החשבון הפרימיום שלך, אנא הזן את פרטי הכרטיס שלך
              </Typography>
              {/* <Typography>
                ליצירת קשר וקניית מנוי פרמיום: המנוי הוא לחודש ימים מרגע ביצוע
                התשלום. אנו מקבלים תשלום באמצעות : בנק , ביט , כרטיס אשראי ,
                קריפטו, משיכה ללא כרטיס. מזומן ההפקדה. לרכישת פרמיום באתר תצרו
                קשר במספר בוואטצפ
                <a href="https://wa.me/972532675529">
                  https://wa.me/972532675529
                </a>
                או בטלגרם
                <a href="https://t.me/menaelg">https://t.me/menaelg</a>
              </Typography> */}
              <Box>
                <CreditCardPayment />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            className="sm:px-6 px-0 sm:block hidden">
            <Sidebar />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Contact;
