import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Box from "@mui/material/Box";
import { CustomTabPanel, a11yProps } from "../../../helpers";
import Colors from "../../../helpers/Colors";
import { Avatar } from "@mui/material";
import { Typography } from "antd";
import { AccessTime, LocationOnOutlined, Timer } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function ChatList({
  chats,
  setActiveId,
  activeId,
  setShowChat,
  setActiveUsername,
  messageFunctions,
}) {
  const [value, setValue] = React.useState(0);
  const { messageNotifications } = useSelector((state) => state.notifications);
  const { mails } = useSelector((state) => state.mail);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }} className="sticky top-0 ">
      <Box className="bg-yellow-300  sticky py-4  text-center z-10">
        <Typography className="font-bold text-white">הודעות</Typography>
      </Box>
      <Box className="space-y-2 h-[85vh] overflow-y-scroll px-3 py-5">
        {chats?.map((item, index) => {
          const img = `https://api.datingnow-il.com/${item?.user?.photoUrl}`;
          const notifications = mails.filter(
            (notify) => notify.fromId == item.id
          );

          return (
            <>
              <Box
                className={` cursor-pointer hover:shadow-sm shadow-lg ${
                  activeId === item.id
                    ? "bg-[#fff] border-2 border-[#EBD165]"
                    : "border-2 border-[#e7e7e7] bg-[#e7e7e7]"
                }  px-4 py-2 rounded-md`}
                key={index}
                onClick={() => {
                  setActiveId(item?.id);
                  setShowChat(true);
                  setActiveUsername(item?.user?.username);
                  messageFunctions();
                }}
              >
                <Box className="flex items-center justify-between my-3">
                  <Link to={`/ouserProfile/${item?.id}`}>
                    <Avatar
                      src={img || require("../../../assets/images/vision6.png")}
                      sx={{ width: 70, height: 70 }}
                    />
                  </Link>
                  <Box>
                    <Typography className="font-black text-[12px]">
                      {item?.user?.username}
                    </Typography>
                    <Box>
                      <Box className="flex items-center space-x-2">
                        <Typography className="text-[12px] text-gray-400">
                          {item?.user?.age}
                        </Typography>
                        <Box className="flex items-center">
                          {" "}
                          <span className="text-gray-400">|</span>
                          <LocationOnOutlined
                            style={{
                              color: Colors.primary2,
                              fontSize: "13px",
                            }}
                          />
                          <Typography className="text-gray-400 text-[12px]">
                            ,{item?.user?.city}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="flex items-center space-x-1">
                        <AccessTime style={{ fontSize: "9px" }} />
                        <Typography className="text-gray-400 text-[10px]">
                          נכנס לאחרונה{" "}
                          {new Date(item?.user?.lastSeen).toLocaleTimeString(
                            "en-US",
                            {
                              hour12: false,
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {notifications.length > 0 && (
                  <Box className="border-t  mt-3">
                    <Box
                      className={`${
                        activeId === item.id
                          ? "bg-gray-200 text-[#000]"
                          : "bg-green-800 text-white "
                      } rounded-md text-center w-[40%] mt-2 px-3 py-0.5`}
                    >
                      <Typography
                        className={`text-bold  text-[10px] ${
                          activeId === item.id ? "text-[#000]" : "text-white"
                        }`}
                      >
                        קרא עוד ({notifications.length})
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </>
          );
        })}

        {/* <Box className="flex items-center justify-between bg-[#fff] p-4 rounded-md border-2 border-[#EBD165]">
            <Avatar
              src={require("../../../assets/images/vision6.png")}
              sx={{ width: 70, height: 70 }}
            />
            <Box>
              <Typography className="font-black text-[12px]">
                Prince ALi
              </Typography>
              <Box>
                <Box className="flex items-center space-x-2">
                  <Typography className="text-[12px] text-gray-400">
                    Age 56
                  </Typography>
                  <Box className="flex items-center">
                    {" "}
                    <span className="text-gray-400">|</span>
                    <LocationOnOutlined
                      style={{ color: Colors.primary2, fontSize: "13px" }}
                    />
                    <Typography className="text-gray-400 text-[12px]">
                      ,Tel Aviv
                    </Typography>
                  </Box>
                </Box>
                <Box className="flex items-center space-x-1">
                  <AccessTime style={{ fontSize: "9px" }} />
                  <Typography className="text-gray-400 text-[10px]">
                    Sent 15:05 12/02/23
                  </Typography>
                </Box>
                <Box>
                  <Typography className="font-bold text-[13px]">
                    Hi what you doing?
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box> */}
      </Box>
    </Box>
  );
}
