import React from "react";
import SearchedFriends from "./SearchedFriends";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "antd";
import { getBlacklists } from "../../features/friends/friendsSlice";

const BlackListTab = () => {
  const { blacklist } = useSelector((state) => state.friends);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getBlacklists());
  }, []);
  return (
    <div className="bg-white p-5 rounded-md text-center">
      {blacklist?.length > 0 ? (
        <SearchedFriends users={blacklist} />
      ) : (
        <Typography>אין לך משתמשים חסומים</Typography>
      )}
    </div>
  );
};

export default BlackListTab;
