import React from "react";
import ProfileNavbar from "../../components/Navbars/ProfileNavbar";
import { Box, Grid } from "@mui/material";
import UserDetailsCard from "../../components/Profile/UserDetailsCard";
import PersonalDetils from "../../components/Profile/update-profile/Personal";
import AccountDetails from "../../components/Profile/update-profile/Account";
import Pestle from "../../components/Profile/update-profile/Pestle";
import Spouse from "../../components/Profile/update-profile/Spouse";
import FriendListTab from "../../components/Profile/FriendListTab";
import BlogTab from "../../components/blog/BlogTab";
import SingleBlogTab from "../../components/blog/SingleBlogTab";
import { Typography } from "antd";
import Colors from "../../helpers/Colors";

const SingleBlog = () => {
  return (
    <>
      <ProfileNavbar />
      <Box className="bg-[#eae9e7] h-min-screen ">
        <Box className="sm:w-[94%] m-auto w-[98%] py-8">
          <Grid container className="space-y-4">
            <Grid
              xs={12}
              sm={12}
              md={3}
              className="border sm:px-6 px-0 w-full mt-5"
            >
              <Box className="bg-white p-3 space-y-3">
                <Typography
                  className="text-[13px] underline"
                  style={{ color: Colors.secondary }}
                >
                  7 טיפים לזוגיות להתחלת קשר מוצלחתבמיוחד
                </Typography>
                <Typography
                  className="text-[13px] underline"
                  style={{ color: Colors.secondary }}
                >
                  7 טיפים לזוגיות להתחלת קשר מוצלחתבמיוחד
                </Typography>
                <Typography
                  className="text-[13px] underline"
                  style={{ color: Colors.secondary }}
                >
                  7 טיפים לזוגיות להתחלת קשר מוצלחתבמיוחד
                </Typography>
                <Typography
                  className="text-[13px] underline"
                  style={{ color: Colors.secondary }}
                >
                  7 טיפים לזוגיות להתחלת קשר מוצלחתבמיוחד
                </Typography>
                <Typography
                  className="text-[13px] underline"
                  style={{ color: Colors.secondary }}
                >
                  7 טיפים לזוגיות להתחלת קשר מוצלחתבמיוחד
                </Typography>
                <Typography
                  className="text-[13px] underline"
                  style={{ color: Colors.secondary }}
                >
                  7 טיפים לזוגיות להתחלת קשר מוצלחתבמיוחד
                </Typography>
                <Typography
                  className="text-[13px] underline"
                  style={{ color: Colors.secondary }}
                >
                  7 טיפים לזוגיות להתחלת קשר מוצלחתבמיוחד
                </Typography>
              </Box>
            </Grid>
            <Grid xs={12} sm={12} md={9}>
              <SingleBlogTab />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default SingleBlog;
