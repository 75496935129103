import { Box } from "@mui/material";
import { Typography } from "antd";
import React from "react";
import { Chat } from "@mui/icons-material";
import Colors from "../../helpers/Colors";
import { Link } from "react-router-dom";

const BlogTab = () => {
  return (
    <Box>
      <Box className="bg-white my-2 rounded-md py-5 px-5">
        <Typography className="text-[13px] font-bold">
          7 טיפים לזוגיות להתחלת קשר מוצלחת במיוחד
        </Typography>
        <Typography className="text-[10px] text-[#777]">
          4:49, 30/11/2021
        </Typography>
        <Typography className="text-[12px] my-2">
          כאשר אתם בתחילתה של זוגיות רומנטית בוגרת ומאפשרת, הערבים הם הזמן לחגוג
          בו להתקרב ולבלות ולא סתם להעביר את הזמן זאת הזדמנות אמת להכיר טוב יותר
          וללמוד אחד את השני לעומק לראות צדדים חיובים ומצחיקים ובעיקר להיות
          רומנטיים ויצירתיים. החוכמה היא באמת לבלות זמן איכות אמיתי ומעניין ובלי
          שזה יראה מתאמץ מדי ומלאכותי.
        </Typography>
        <Link to="/blog/7">
          <button className="py-2 px-5 rounded-md border border-[#D71D6D] bg-none text-[#D71D6D]">
            קרא עוד{" "}
          </button>
        </Link>
        <Box className="flex mt-5 items-center space-x-1 justify-end">
          <Chat style={{ color: Colors.secondary }} />
          <Typography
            className="text-[14px] "
            style={{ color: Colors.secondary }}
          >
            30
          </Typography>
        </Box>
      </Box>
      <Box className="bg-white my-2 rounded-md py-5 px-5">
        <Typography className="text-[13px] font-bold">
          7 טיפים לזוגיות להתחלת קשר מוצלחת במיוחד
        </Typography>
        <Typography className="text-[10px] text-[#777]">
          4:49, 30/11/2021
        </Typography>
        <Typography className="text-[12px] my-2">
          כאשר אתם בתחילתה של זוגיות רומנטית בוגרת ומאפשרת, הערבים הם הזמן לחגוג
          בו להתקרב ולבלות ולא סתם להעביר את הזמן זאת הזדמנות אמת להכיר טוב יותר
          וללמוד אחד את השני לעומק לראות צדדים חיובים ומצחיקים ובעיקר להיות
          רומנטיים ויצירתיים. החוכמה היא באמת לבלות זמן איכות אמיתי ומעניין ובלי
          שזה יראה מתאמץ מדי ומלאכותי.
        </Typography>
        <Link to="/blog/7">
          <button className="py-2 px-5 rounded-md border border-[#D71D6D] bg-none text-[#D71D6D]">
            קרא עוד{" "}
          </button>
        </Link>
        <Box className="flex mt-5 items-center space-x-1 justify-end">
          <Chat style={{ color: Colors.secondary }} />
          <Typography
            className="text-[14px] "
            style={{ color: Colors.secondary }}
          >
            30
          </Typography>
        </Box>
      </Box>
      <Box className="bg-white my-2 rounded-md py-5 px-5">
        <Typography className="text-[13px] font-bold">
          7 טיפים לזוגיות להתחלת קשר מוצלחת במיוחד
        </Typography>
        <Typography className="text-[10px] text-[#777]">
          4:49, 30/11/2021
        </Typography>
        <Typography className="text-[12px] my-2">
          כאשר אתם בתחילתה של זוגיות רומנטית בוגרת ומאפשרת, הערבים הם הזמן לחגוג
          בו להתקרב ולבלות ולא סתם להעביר את הזמן זאת הזדמנות אמת להכיר טוב יותר
          וללמוד אחד את השני לעומק לראות צדדים חיובים ומצחיקים ובעיקר להיות
          רומנטיים ויצירתיים. החוכמה היא באמת לבלות זמן איכות אמיתי ומעניין ובלי
          שזה יראה מתאמץ מדי ומלאכותי.
        </Typography>
        <Link to="/blog/7">
          <button className="py-2 px-5 rounded-md border border-[#D71D6D] bg-none text-[#D71D6D]">
            קרא עוד{" "}
          </button>
        </Link>
        <Box className="flex mt-5 items-center space-x-1 justify-end">
          <Chat style={{ color: Colors.secondary }} />
          <Typography
            className="text-[14px] "
            style={{ color: Colors.secondary }}
          >
            30
          </Typography>
        </Box>
      </Box>
      <Box className="bg-white my-2 rounded-md py-5 px-5">
        <Typography className="text-[13px] font-bold">
          7 טיפים לזוגיות להתחלת קשר מוצלחת במיוחד
        </Typography>
        <Typography className="text-[10px] text-[#777]">
          4:49, 30/11/2021
        </Typography>
        <Typography className="text-[12px] my-2">
          כאשר אתם בתחילתה של זוגיות רומנטית בוגרת ומאפשרת, הערבים הם הזמן לחגוג
          בו להתקרב ולבלות ולא סתם להעביר את הזמן זאת הזדמנות אמת להכיר טוב יותר
          וללמוד אחד את השני לעומק לראות צדדים חיובים ומצחיקים ובעיקר להיות
          רומנטיים ויצירתיים. החוכמה היא באמת לבלות זמן איכות אמיתי ומעניין ובלי
          שזה יראה מתאמץ מדי ומלאכותי.
        </Typography>
        <Link to="/blog/7">
          <button className="py-2 px-5 rounded-md border border-[#D71D6D] bg-none text-[#D71D6D]">
            קרא עוד{" "}
          </button>
        </Link>
        <Box className="flex mt-5 items-center space-x-1 justify-end">
          <Chat style={{ color: Colors.secondary }} />
          <Typography
            className="text-[14px] "
            style={{ color: Colors.secondary }}
          >
            30
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BlogTab;
