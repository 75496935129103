import React, { useEffect, useState } from "react";
import AuthNavbar from "../../components/Navbars/AuthNavbar";
import { Box } from "@mui/material";
import { Typography } from "antd";
import Colors from "../../helpers/Colors";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resendEmail } from "../../features/auth/authSlice";
import { ClipLoader } from "react-spinners";

const VerifySent = () => {
  const location = useLocation();

  const [user, setUser] = useState();
  const dispatch = useDispatch();
  const {
    user: userInfo,
    isLoading,
    isSuccess,
  } = useSelector((state) => state.auth);

  const handleSendEmail = () => {
    const data = {
      email: user?.email,
      username: user?.nickname,
    };
    dispatch(resendEmail(data));
  };

  useEffect(() => {
    if (isSuccess) {
      console.log("hee");
    }
  }, [isSuccess]);

  // Now you can access userData and its properties
  const navigate = useNavigate();
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("registration"));
    const statusRegistration = JSON.parse(
      localStorage.getItem("registration1")
    );

    if (userInfo) {
      navigate("/main");
    }
    if (userData) {
      navigate("/complete-reg", {
        state: {
          userData,
        },
      });
    } else {
      navigate("/");
    }

    if (statusRegistration) {
      navigate("/verification");
      setUser(statusRegistration);
    }
  }, []);

  return (
    <>
      <AuthNavbar />
      <Box className="h-min-screen overflow-x scroll verifyScreen py-10">
        <Box className="md:w-[44%] w-[85%] m-auto h-min-screen rounded-sm">
          <Box className="py-2 bg-white text-center">
            <Typography classN4ame="text-center text-[13px] font-bold">
              ההרשמה התבצעה בהצלחה!
            </Typography>
          </Box>
          <Box className="bg-[#eae9e7] text-center p-5">
            <Typography>ברגעים אלו ממש נשלח אליך מייל לכתובת:</Typography>
            <Typography className="underline mt-2">{user?.email}</Typography>
            <Typography className="text-[#403b4d] font-bold text-[30px] leading-8 mt-2">
              בכדי לצפות בפרופיל עליך לאשר את המייל שנשלח אליך
            </Typography>
            <Typography className="text-red-500 mt-5 ">
              שימו לב - יתכן והמייל יגיע לתיקיית הספאם או תיקיות אחרות*
            </Typography>
            <Box className="mt-3 space-y-4">
              {/* <button
                className="py-2 px-5 rounded-md hover:bg-[#D71D6D]"
                style={{ background: Colors.primary }}
              >
                הזנתי כתובת מייל שגויה, ברצוני לתקן. לחץ כאן
              </button> */}
              <button
                onClick={handleSendEmail}
                disabled={isLoading}
                className="py-2 px-5 rounded-md hover:bg-[#D71D6D]"
                style={{ background: Colors.primary }}
              >
                {isLoading ? (
                  <ClipLoader
                    color={Colors.white}
                    loading={isLoading}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  " לא קיבלתי עדיין מייל. לחץ כאן"
                )}
              </button>
            </Box>
            <Box classN4ame="mt-8 space-y-2">
              <Typography>
                אין סוף אפשרויות, בואו לבחור את הסידור המושלם עבורך...{" "}
              </Typography>
              <img
                src={require("../../assets/images/confirm__media.jpg")}
                className="w-[80%] m-auto border border-2 mt-5 border-white"
                alt=""
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VerifySent;
