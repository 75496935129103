import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const likes = JSON.parse(localStorage.getItem("likeNotifications"));
const friendsRequestNotifications = JSON.parse(
  localStorage.getItem("friendsRequestNotifications")
);
const friendsAcceptedNotifications = JSON.parse(
  localStorage.getItem("friendsAcceptedNotifications")
);

const messageNotifications = JSON.parse(
  localStorage.getItem("messageNotifications")
);

const initialState = {
  likeNotifications: likes ? likes : [],
  friendsRequestNotifications: friendsRequestNotifications
    ? friendsRequestNotifications
    : [],
  friendsAcceptedNotifications: friendsAcceptedNotifications
    ? friendsAcceptedNotifications
    : [],
  messageNotifications: messageNotifications ? messageNotifications : [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const addNotificationLikes = createAsyncThunk(
  "notifications/addNotificationLikes",
  async (data, thunkAPI) => {
    try {
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addNotificationFriendRequest = createAsyncThunk(
  "notifications/addNotificationFriendRequest",
  async (data, thunkAPI) => {
    try {
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addNotificationFriendAccept = createAsyncThunk(
  "notifications/addNotificationFriendAccept",
  async (data, thunkAPI) => {
    try {
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addMessageNotifications = createAsyncThunk(
  "notifications/addMessageNotifications",
  async (data, thunkAPI) => {
    try {
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const clearNotificationLikes = createAsyncThunk(
  "notifications/clearNotificationLikes",
  async (_, thunkAPI) => {
    localStorage.removeItem("likeNotifications");
    return null;
  }
);
export const clearNotificationLRequest = createAsyncThunk(
  "notifications/clearNotificationLRequest",
  async (_, thunkAPI) => {
    localStorage.removeItem("friendsRequestNotifications");
    return null;
  }
);
export const clearMessageNotifications = createAsyncThunk(
  "notifications/clearMessageNotifications",
  async (_, thunkAPI) => {
    localStorage.removeItem("messageNotifications");
    return null;
  }
);

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(addNotificationLikes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.likeNotifications = action.payload;
      })
      .addCase(addNotificationFriendRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.friendsRequestNotifications = action.payload;
      })
      .addCase(addNotificationFriendAccept.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.friendsAcceptedNotifications = action.payload;
      })
      .addCase(addMessageNotifications.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.messageNotifications = action.payload;
      })
      .addCase(clearNotificationLikes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.likeNotifications = action.payload;
      })
      .addCase(clearNotificationLRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.friendsRequestNotifications = action.payload;
      });
  },
});

export const { reset } = notificationsSlice.actions;
export default notificationsSlice.reducer;
