import React, { useEffect } from "react";
import GeneralNavbar from "../../components/Navbars/GeneralNavbar";
import { Box, Grid } from "@mui/material";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";

const Terms = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   const userData = JSON.parse(localStorage.getItem("registration"));
  //   const statusRegistration = JSON.parse(
  //     localStorage.getItem("registration1")
  //   );

  //   if (user) {
  //     navigate("/main");
  //   }
  //   if (userData) {
  //     navigate("/complete-reg", {
  //       state: {
  //         userData,
  //       },
  //     });
  //   }

  //   if (statusRegistration) {
  //     navigate("/verification");
  //   }
  // }, []);
  return (
    <>
      <GeneralNavbar />

      <Box className="bg-[#eae9e7] py-3">
        <Box className="sm:w-[85%] w-[95%] m-auto py-8">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={3}>
              <Box className="bg-white rounded-md border">
                <Typography className="p-2.5 border-b  bg-[#414141] text-white">
                  Terms
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <Box className="h-screen bg-white rounded-md p-5">
                <Typography className="font-semibold text-[18px]">
                  Articles of Association of Sugar Daddy Ltd ( hereinafter " the
                  Company ")
                </Typography>
                <ol>
                  <li>
                    1. The website regulations are a binding legal agreement
                    between the company that operates Sugar Daddy (hereinafter "
                    the website ") and the users of the website.
                  </li>{" "}
                  <br />
                  <li>
                    2.for surfing the site is that the surfer must be 18 years
                    old. If you are not an adult, you are required not to browse
                    the site and to leave the site immediately
                  </li>
                  <br />
                  <li>
                    3. These regulations shall apply to and bind the users of
                    the website in any use made by the users of the website (
                    hereinafter : " the user ") , regardless of whether the user
                    browses the website using a desktop computer, mobile phone,
                    smart phone, tablet, and/or any other mechanical and/or
                    optical means of any kind
                  </li>
                  <br />
                  <li>
                    4. If the site asked to review the regulations. If you do
                    not agree with the regulations, you are asked not to
                    continue browsing the site. Browsing the website constitutes
                    explicit consent on the part of the surfer to all provisions
                    of the regulation
                  </li>
                  <br />
                  <li>
                    5. Advertising, and/or offering, and/or soliciting
                    prostitution services on this website is strictly prohibited
                    . An offer, and/or solicitation of prostitution services,
                    constitutes a criminal offense and a violation of the
                    provisions of the Penal Code
                  </li>
                  <br />
                  <li>
                    6. he administrators of the site discover that one of the
                    surfers offers prostitution services, and/or advertises
                    prostitution services, and/or persuades others to provide
                    prostitution services, the administrators of the site will
                    file a complaint with the police and take all the legal
                    measures available to them against the advertiser, including
                    filing civil lawsuits against the advertiser, for any What
                    this implies is in addition to the immediate removal of the
                    content and the surfer's profile from the site, including
                    users who have purchased a subscription to the site, and
                    this is in accordance with the sole professional discretion
                    of the site team. A surfer who purchased a subscription and
                    is removed from the site in accordance with the provisions
                    of this section, will be precluded from raising a claim,
                    and/or demand, and/or a lawsuit against the site operators
                    due to his remova
                  </li>
                  <br />
                  <li>
                    7. Browsing and registering on the website is personal, the
                    person who registers on the website ( hereafter " the
                    registrant ") is prohibited from allowing the use of the
                    website to any third party and third parties are prohibited
                    from browsing the website under the registrant's details. A
                    user on the website is prohibited from transferring, and/or
                    otherwise transferring his account on the website to any
                    person, and/or entity, whether in Israel or abroad.
                  </li>
                  <br />
                  <li>8. The section titles are for convenience only</li>
                  <br />
                  <li>
                    9. The use of the site requires full adherence to the
                    regulation
                  </li>
                  <br />
                  <li>
                    10. The company's liability is limited as detailed in these
                    regulations. The company will not be responsible for actions
                    between surfers on the website and any third parties
                  </li>
                  <br />
                  <li>
                    11. cannot know and verify the motives of those browsing the
                    site. The company has no responsibility to identify the
                    surfers on the website, and/or any responsibility for their
                    motives for browsing the website. The user of the website
                    will not have any claim, and/or demand of any kind against
                    the company in this regard
                  </li>
                  <br />
                  <li>
                    12. The company has no responsibility for the results of
                    browsing the website, and/or for the satisfaction of the
                    website surfers with the results of surfing the website,
                    including the results of the meetings between the surfers on
                    the website.
                  </li>
                  <br />
                  <li>
                    13. The users of the website hereby expressly declare that
                    by browsing the website, they agree that they will not have
                    any claim, and/or demand of any kind against the company and
                    that the company will not have any responsibility of any
                    kind and will not bear any responsibility for any damage,
                    whether direct or indirect, and will not bear Responsible
                    for any expenditure, whether direct or indirect, including
                    NIS in the following subjects:
                  </li>
                  <br />
                  <ul className="px-4">
                    <li className="text-[14px]" style={{ listStyle: "disc" }}>
                      Not finding a partner on the site that matches their
                      requirements.
                    </li>
                    <li className="text-[14px]" style={{ listStyle: "disc" }}>
                      Exercising violence from any couple - physical, and/or
                      verbal between surfers on the site.
                    </li>
                    <li className="text-[14px]" style={{ listStyle: "disc" }}>
                      Illegal activity of any kind carried out by surfers on the
                      site during a meeting between them. Surfers are asked to
                      exercise discretion before meeting with another surfer on
                      the site .
                    </li>
                  </ul>
                </ol>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Terms;
