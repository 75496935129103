import React from "react";

import { Box, Grid } from "@mui/material";
import { Timer } from "@mui/icons-material";
import { Typography } from "antd";
import LikeMeSidebar from "../../../components/LikeMe/LikeMeSidebar";
import ProfileNavbar from "../../../components/Navbars/ProfileNavbar";
import Colors from "../../../helpers/Colors";

const LikeMe = () => {
  return (
    <>
      <ProfileNavbar />
      <Box className="bg-[#eae9e7] h-screen ">
        <Box className="sm:w-[94%] m-auto w-[98%] py-8">
          <Grid container className="space-y-4">
            <Grid
              xs={12}
              sm={12}
              md={9}
              className="border sm:px-6 px-0 w-full sm:mt-14 mt-0"
            >
              <Box className="bg-white  rounded-md p-5">
                <Box className="w-full">
                  <Box className="flex space-x-1 items-center my-2">
                    <Timer style={{ fontSize: "13px", color: "#777" }} />
                    <Typography className="text-[#777] text-[12px]">
                      לפני 3 שעות
                    </Typography>
                  </Box>
                  <img
                    src={require("../../../assets/images/user1.jpg")}
                    alt=""
                    className="sm:w-[30%] w-[100%] rounded-md"
                  />
                  <Typography className="text-[#777]">
                    <span
                      className="underline "
                      style={{ color: Colors.secondary }}
                    >
                      Ornas
                    </span>
                    , 45
                  </Typography>
                  <Typography className="text-[#777] text-[12px]">
                    ישראל, רמת גן
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid xs={12} sm={12} md={3}>
              <LikeMeSidebar />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default LikeMe;
