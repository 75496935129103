import { Alert, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import Colors from "../../helpers/Colors";
import { useDispatch, useSelector } from "react-redux";
import { makePayments, reset } from "../../features/auth/authSlice";
import { ClipLoader } from "react-spinners";
import { Check, InfoOutlined } from "@mui/icons-material";

const CreditCardPayment = () => {
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [state, setState] = useState({
    number: "",
    name: "",
    expiry: "",
    cvc: "",
    focus: "",
    phone: "",
    idNumber: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "number") {
      let formattedValue = value.replace(/\s+/g, "").trim(); // Remove spaces

      if (formattedValue.length > 0) {
        formattedValue = formattedValue.match(/.{1,4}/g).join(" "); // Add space after every 4 characters
      }

      setState((prev) => ({ ...prev, [name]: formattedValue }));
    } else if (name === "cvc") {
      let formattedValue = value.replace(/\s+/g, "").trim(); // Remove spaces

      if (formattedValue.length > 3) {
        formattedValue = formattedValue.slice(0, 3); // Limit to 3 characters for CVV
      }

      setState((prev) => ({ ...prev, [name]: formattedValue }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleInputFocus = (e) => {
    setState((prev) => ({ ...prev, focus: e.target.name }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      number: state.number.toString().trim(),
      fullName: state.name,
      ccv: state.cvc,
      expiry: `${state.expiry}-01`,
      id: state.idNumber,
      phone: state.phone,
    };

    console.group(data);
    dispatch(makePayments(data));
  };

  useEffect(() => {
    if (isSuccess && message == "payment success") {
      setSuccess("התשלום בוצע בהצלחה");
      setTimeout(() => {
        window.location.href = "/main";
        setSuccess("");
        setError("");
      }, 3000);
    }

    if (isError) {
      setError(message);
      setTimeout(() => {
        setError("");
      }, 3000);
    }

    if (isSuccess && isError) {
      dispatch(reset());
    }
  }, [isSuccess, isLoading, isError]);
  return (
    <div>
      {/* <Cards
        number={state.number}
        expiry={state.expiry}
        cvc={state.cvc}
        name={state.name}
        focused={state.focus}
      /> */}
      <div className="mt-3">
        <div className="my-3">
          {error !== "" && (
            <Alert icon={<InfoOutlined fontSize="inherit" />} severity="error">
              {message}
            </Alert>
          )}

          {success !== "" && (
            <Alert icon={<Check fontSize="inherit" />} severity="success">
              {success}
            </Alert>
          )}
        </div>
        <form>
          <div className="mb-3">
            <h5 className="text-[13px] text-gray-500 mb-1">מספר כרטיס</h5>
            <input
              type="text"
              name="number"
              className="form-control p-3 rounded-md w-full outline-none"
              placeholder="מספר כרטיס"
              value={state.number}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              maxLength={19} // Set max length to account for spaces
              required
            />
          </div>
          <div className="mb-3">
            <h5 className="text-[13px] text-gray-500 mb-1">
              {" "}
              שם פרטי ושם משפחה
            </h5>
            <input
              type="text"
              name="name"
              className="form-control p-3 rounded-md w-full outline-none"
              placeholder="שם פרטי ושם משפחה"
              value={state.name}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              required
            />
          </div>

          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <div className="col-6 mb-3">
                <h5 className="text-[13px] text-gray-500 mb-1">תוקף</h5>
                <input
                  type="month"
                  min="YYYY-MM"
                  max="YYYY-MM"
                  name="expiry"
                  className="form-control sm:w-[95%] w-full rounded-md p-3"
                  placeholder="תוקף עד (חודש/שנה)"
                  value={state.expiry}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  maxLength={5} // Set max length for MM/YY format
                  required
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className="col-6 mb-3">
                <h5 className="text-[13px] text-gray-500 mb-1">
                  ספרות בגב הכרטיס
                </h5>
                <input
                  type="text"
                  name="cvc"
                  className="form-control sm:w-[95%] w-full rounded-md p-3"
                  placeholder="ספרות בגב הכרטיס
                  "
                  value={state.cvc}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  maxLength={3} // Set max length for CVV
                  required
                />
              </div>
            </Grid>
          </Grid>
          <div className="mb-3">
            <h5 className="text-[13px] text-gray-500 mb-1"> מספר טלפון</h5>
            <input
              type="text"
              name="phone"
              className="form-control p-3 rounded-md w-full outline-none"
              placeholder="מספר טלפון "
              value={state.phone}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              required
            />
          </div>
          <div className="mb-3">
            <h5 className="text-[13px] text-gray-500 mb-1"> תעודת זהות</h5>
            <input
              type="text"
              name="idNumber"
              className="form-control p-3 rounded-md w-full outline-none"
              placeholder=" תעודת זהות "
              value={state.idNumber}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              required
            />
          </div>

          {state.cvc &&
            state.expiry &&
            state.idNumber &&
            state.name &&
            state.number &&
            state.phone && (
              <div className="d-grid">
                <button
                  onClick={(e) => handleSubmit(e)}
                  className="w-full p-2 text-white rounded-md"
                  style={{ background: Colors.secondary }}>
                  {isLoading ? (
                    <ClipLoader
                      color={Colors.white}
                      loading={isLoading}
                      size={10}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  ) : (
                    "להגיש"
                  )}
                </button>
              </div>
            )}
        </form>
      </div>
    </div>
  );
};

export default CreditCardPayment;
