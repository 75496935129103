import React, { useEffect } from "react";

import { useState } from "react";
import ProfileNavbar from "../../../components/Navbars/ProfileNavbar";
import { Box, Grid } from "@mui/material";
import ChatScreen from "../../../components/Profile/mail/ChatScreen";
import ChatList from "../../../components/Profile/mail/ChatList";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAllMessage } from "../../../features/mail/mailSlice";
import { useMediaQuery } from "react-responsive";
import { useSignalGlobalContext } from "../../../context";

const MailBox = () => {
  const { mails } = useSelector((state) => state.mail);
  const [activeId, setActiveId] = useState(null);
  const [activeUsername, setActiveUsername] = useState(null);
  const [chats, setChats] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const { messageFunctions } = useSignalGlobalContext();

  useEffect(() => {
    setTimeout(() => {
      messageFunctions();
    }, 1000);
  }, []);

  useEffect(() => {
    const chatMap = {};

    mails?.forEach((chat) => {
      const userId = chat.user.id;
      const user = chat.user;
      if (!chatMap[userId]) {
        chatMap[userId] = {
          id: userId,
          user: user,
          messages: [],
        };
      }
      chatMap[userId].messages.push(chat);
    });

    setChats(Object.values(chatMap));
  }, [mails]);

  const location = useLocation();

  const id = location?.state?.activeId;
  const username = location?.state?.activeUsername;

  let filteredChats = chats?.find(
    (chat) => chat.id === activeId || chat.id === id
  );
  const [showChat, setShowChat] = useState(activeId || id ? true : false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllMessage());
  }, []);
  return (
    <>
      <ProfileNavbar />

      {/* mobile */}
      <Box className="md:p-5 p-0 ">
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            className={`${isMobile ? (showChat ? "block" : "hidden") : ""}`}
          >
            <ChatScreen
              filteredChats={filteredChats}
              activeId={activeId ? activeId : id}
              setShowChat={setShowChat}
              messageFunctions={messageFunctions}
              activeUsername={activeUsername ? activeUsername : username}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            className={`${isMobile ? (showChat ? "hidden" : "block") : ""}`}
          >
            <Box className="sm:px-5 px-0 h-screen  sticky top-30">
              <ChatList
                chats={chats}
                setShowChat={setShowChat}
                setActiveUsername={setActiveUsername}
                setActiveId={setActiveId}
                messageFunctions={messageFunctions}
                activeId={activeId || id}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* <Box className="md:p-5 p-0 md:block hidden">
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
           
          >
            <ChatScreen
              filteredChats={filteredChats}
              activeId={activeId ? activeId : id}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            
          >
            <Box className="px-5 h-screen overflow-scroll sticky top-10">
              <ChatList
                chats={chats}
                setActiveId={setActiveId}
                setShowChat={() => console.log()}
                activeId={activeId || id}
              />
            </Box>
          </Grid>
        </Grid>
      </Box> */}
    </>
  );
};

export default MailBox;
