import { Box, Button } from "@mui/material";
import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { LocationOn } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import Colors from "../../helpers/Colors";
import { getDetails, reset, updatePhoto } from "../../features/auth/authSlice";
import { ClipLoader } from "react-spinners";
import dummyImg from "../../assets/images/vision6.png";
import FemaledummyImg from "../../assets/images/user1.png";
import { useNavigate } from "react-router-dom";

const UserDetailsCard = () => {
  const [error, setError] = useState();
  const navigate = useNavigate();

  const { isLoading, isError, isSuccess, message, user } = useSelector(
    (state) => state.auth
  );
  const [city, setCity] = React.useState();
  const { cities } = useSelector((state) => state.cities);

  cities?.data?.forEach((item) => {
    if (item.id === user?.data?.city && !city) {
      setCity(item.englishName);
    }
  });

  const dispatch = useDispatch();
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  // Formik form handling
  const formik = useFormik({
    initialValues: {
      photo: null,
    },
    validationSchema: Yup.object().shape({
      photo: Yup.mixed().required("Photo is required"),
    }),
    onSubmit: (values) => {
      // Handle form submission, send photo to API
      const formData = new FormData();

      formData.append("photo", values.photo);

      dispatch(updatePhoto(formData));
    },
  });

  useEffect(() => {
    if (isError) {
      setError(message);
      setTimeout(() => {
        setError("");
      }, 3000);
    }

    if (isSuccess) {
      setError("");
    }

    // Cleanup function
    return () => {
      // Reset state on component unmount as well
      dispatch(reset());
    };
  }, [isError, isSuccess, dispatch, message]);

  const img = `https://api.datingnow-il.com/${user?.data?.photoUrl}`
    ? `https://api.datingnow-il.com/${user?.data?.photoUrl}`
    : user?.data?.gender === "Male"
    ? dummyImg
    : FemaledummyImg;

  return (
    <Box>
      <Box className="sm:px-0 px-10">
        <Box className="p-3 rounded-md bg-white">
          {user?.data?.photoUrl ? (
            <img
              src={selectedPhoto ? URL.createObjectURL(selectedPhoto) : img}
              alt=""
              className="w-full rounded-md"
            />
          ) : (
            <img
              src={
                selectedPhoto
                  ? URL.createObjectURL(selectedPhoto)
                  : user?.data?.gender === "Male"
                  ? dummyImg
                  : FemaledummyImg
              }
              alt=""
              className="w-full rounded-md"
            />
          )}
        </Box>
        <Typography
          className="underline cursor-pointer"
          onClick={() => {
            // Click handler for updating photo
            // Trigger input file click
            document.getElementById("photoInput").click();
          }}
        >
          עדכון תמונה
        </Typography>
        {error && <Typography className="text-red-500">{error}</Typography>}
        {/* Hidden input file field */}
        <input
          id="photoInput"
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={(event) => {
            formik.setFieldValue("photo", event.currentTarget.files[0]);
            setSelectedPhoto(event.currentTarget.files[0]);
          }}
        />
      </Box>
      <form onSubmit={formik.handleSubmit}>
        {selectedPhoto && (
          <Box className="sm:px-0 px-10">
            <Button
              type="submit"
              disabled={isLoading}
              style={{
                background: Colors.primary2,
                color: "#fff",
                fontSize: "10px",
                marginTop: 3,
              }}
            >
              {isLoading ? (
                <ClipLoader
                  color={Colors.white}
                  loading={isLoading}
                  size={10}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                "סיימת!"
              )}
            </Button>
          </Box>
        )}
        <Box className="text-right mt-5 border sm:px-0 px-5 w-full">
          <Typography className="font-bold capitalize text-[20px]">
            {user?.data?.username}
          </Typography>
          <Box className="flex items-center justify-end space-x-2">
            <Typography> מנוי: חינם |</Typography>
            <Box className="flex items-center space-x-2">
              <Typography style={{ color: "#777", fontSize: "13px" }}>
                אונליין
              </Typography>
              <FiberManualRecordIcon
                style={{
                  fontSize: "14px",
                  color: "green",
                }}
              />
            </Box>
          </Box>
          <Typography style={{ color: "#777", fontSize: "13px" }}>
            גיל {user?.data?.age}
          </Typography>
          <Box className="flex items-center space-x-2 justify-end">
            <Typography style={{ color: "#777", fontSize: "13px" }}>
              {" "}
              {city}
            </Typography>
            <LocationOn style={{ fontSize: "13px", color: "#777" }} />
          </Box>
          {/* Submit button */}
        </Box>
      </form>
    </Box>
  );
};

export default UserDetailsCard;
