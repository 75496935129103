import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import service from "./friendsService";
import { logoutError } from "../auth/authService";

const allFriends = JSON.parse(localStorage.getItem("friends"));
const allFriendsRequest = JSON.parse(localStorage.getItem("requestSent"));
const allFriendsRecieved = JSON.parse(localStorage.getItem("requestRecieved"));
const allBlacklist = JSON.parse(localStorage.getItem("blacklist"));
const allLikes = JSON.parse(localStorage.getItem("likes"));
const allFavourites = JSON.parse(localStorage.getItem("favourites"));

const initialState = {
  friends: allFriends ? allFriends : [],
  requestsSent: allFriendsRequest ? allFriendsRequest : [],
  requestsRecieved: allFriendsRecieved ? allFriendsRecieved : [],
  blacklist: allBlacklist ? allBlacklist : [],
  likes: allLikes ? allLikes : [],
  favourites: allFavourites ? allFavourites : [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getFriends = createAsyncThunk(
  "friends/getFriends",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await service.getFriends(token);
      if (data.status === false) {
        // If status is false, return an error
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getRequestsSent = createAsyncThunk(
  "friends/getRequestsSent",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await service.requestsSent(token);
      if (data.status === false) {
        // If status is false, return an error
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getRequestsRecieved = createAsyncThunk(
  "friends/getRequestsRecieved",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await service.requestsRecieved(token);
      if (data.status === false) {
        // If status is false, return an error
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateRequestsRecievedSignalR = createAsyncThunk(
  "friends/updateRequestsRecievedSignalR",
  async (data, thunkAPI) => {
    try {
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateFriendsListSignalR = createAsyncThunk(
  "friends/updateFriendsListSignalR",
  async (data, thunkAPI) => {
    try {
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateRequestsSentSignalR = createAsyncThunk(
  "friends/updateRequestsSentSignalR",
  async (data, thunkAPI) => {
    try {
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendRequest = createAsyncThunk(
  "friends/sendRequest",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await service.sendRequest(token, id);
      if (data?.status === false) {
        // If status is false, return an error
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const removeFriend = createAsyncThunk(
  "friends/removeFriend",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await service.removeFriend(token, id);
      if (data?.status === false) {
        // If status is false, return an error
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const declineFriend = createAsyncThunk(
  "friends/declineFriend",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await service.declineFriend(token, id);
      if (data?.status === false) {
        // If status is false, return an error
        return thunkAPI.rejectWithValue(data.message);
      }
      console.log(data);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const acceptFriend = createAsyncThunk(
  "friends/acceptFriend",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await service.acceptFriend(token, id);
      if (data?.status === false) {
        // If status is false, return an error
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// blacklist
export const getBlacklists = createAsyncThunk(
  "friends/getBlacklists",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await service.getBlacklist(token);
      if (data.status === false) {
        // If status is false, return an error
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const blacklistFriend = createAsyncThunk(
  "friends/blacklistFriend",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await service.blacklistFriend(token, id);

      console.log(data);
      if (data?.status === false) {
        // If status is false, return an error
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const unBlockFriend = createAsyncThunk(
  "friends/unBlockFriend",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await service.unBlockFriend(token, id);
      if (data?.status === false) {
        // If status is false, return an error
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Likes and Favourites

export const getFavouriteList = createAsyncThunk(
  "friends/getFavouriteList",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await service.getFavouriteList(token);
      if (data.status === false) {
        // If status is false, return an error
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getLikeList = createAsyncThunk(
  "friends/getLikeList",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await service.getLikeList(token);
      if (data.status === false) {
        // If status is false, return an error
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateLikeListSignalR = createAsyncThunk(
  "friends/updateLikeListSignalR",
  async (data, thunkAPI) => {
    try {
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateFavouriteListSignalR = createAsyncThunk(
  "friends/updateFavouriteListSignalR",
  async (data, thunkAPI) => {
    try {
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addFavourite = createAsyncThunk(
  "friends/addFavourite",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await service.AddFavourite(token, id);
      if (data?.status === false) {
        // If status is false, return an error
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const removeFavourites = createAsyncThunk(
  "friends/removeFavourites",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await service.removeFavourites(token, id);
      if (data?.status === false) {
        // If status is false, return an error
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const friends = createSlice({
  name: "friends",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getFriends.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFriends.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.friends = action.payload;
      })

      .addCase(getRequestsSent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRequestsSent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.requestsSent = action.payload;
      })
      .addCase(getRequestsSent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getRequestsRecieved.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRequestsRecieved.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.requestsRecieved = action.payload;
      })
      .addCase(updateRequestsRecievedSignalR.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.requestsRecieved = action.payload;
      })
      .addCase(updateRequestsSentSignalR.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.requestsSent = action.payload;
      })
      .addCase(getRequestsRecieved.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(sendRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "sent";
      })
      .addCase(sendRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(removeFriend.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeFriend.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "removed";
      })
      .addCase(removeFriend.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(declineFriend.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(declineFriend.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "declined";
      })
      .addCase(declineFriend.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(acceptFriend.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(acceptFriend.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(acceptFriend.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getBlacklists.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBlacklists.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.blacklist = action.payload;
      })
      .addCase(getBlacklists.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(blacklistFriend.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(blacklistFriend.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "blacklisted";
      })
      .addCase(blacklistFriend.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(unBlockFriend.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(unBlockFriend.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "unblocked";
      })
      .addCase(unBlockFriend.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getFavouriteList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFavouriteList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.favourites = action.payload;
      })
      .addCase(getFavouriteList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(updateFavouriteListSignalR.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.favourites = action.payload;
      })

      .addCase(getLikeList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLikeList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.likes = action.payload;
      })
      .addCase(getLikeList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(updateLikeListSignalR.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.likes = action.payload;
      })
      .addCase(updateFriendsListSignalR.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.friends = action.payload;
      })

      .addCase(addFavourite.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addFavourite.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "favourite_added";
      })
      .addCase(addFavourite.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(removeFavourites.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeFavourites.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "favourite_removed";
      })
      .addCase(removeFavourites.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = friends.actions;
export default friends.reducer;
