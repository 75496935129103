import { Box, Button } from "@mui/material";
import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Add,
  Block,
  Favorite,
  Flag,
  LocationOn,
  PersonAdd,
  PersonRemove,
} from "@mui/icons-material";

import dummyImg from "../../assets/images/vision6.png";
import dummyImgFemale from "../../assets/images/user1.png";
import { useNavigate, useParams } from "react-router-dom";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Colors from "../../helpers/Colors";
import RequestsModal from "../modal/RequestsModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getFriends,
  getRequestsRecieved,
  getRequestsSent,
  reset,
} from "../../features/friends/friendsSlice";

const OtherUserDetailsCard = ({ user }) => {
  const [error, setError] = useState();
  const { user: MyAccount } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [actionType, setActionType] = useState("");
  const {
    friends,
    requestsSent,
    requestsRecieved,
    blacklist,
    isSuccess,
    isError,
    favourites,
    message,
  } = useSelector((state) => state.friends);
  const dispatch = useDispatch();
  const [openRequestsModal, setOpenRequestsModal] = React.useState(false);
  const [city, setCity] = React.useState();
  const { cities } = useSelector((state) => state.cities);

  cities?.data?.forEach((item) => {
    if (item.id === user?.data?.city && !city) {
      setCity(item.englishName);
    }
  });
  const handleOpenRequestsModal = () => setOpenRequestsModal(true);
  const handleCloseRequestsModal = () => {
    setOpenRequestsModal(false);
    if (isSuccess && !isError) {
      if (
        message == "sent" ||
        message == "removed" ||
        message.message == "Success" ||
        message.message == "You are friends already" ||
        message == "declined"
      ) {
      } else if (message === "blacklisted" || message === "unblocked") {
        navigate(`/userProfile/blackList`);
      } else if (
        message === "favourite_added" ||
        message === "favourite_removed"
      ) {
      }
    }
  };
  const [isFriend, setIsfriend] = useState(null);
  const [isFavourite, setIsfavourite] = useState(null);
  const img = `https://api.datingnow-il.com/${user?.data?.photoUrl}`;

  const handleSendRequest = () => {
    handleOpenRequestsModal();
    setActionType("request");
  };

  const handleRemoveFriend = () => {
    handleOpenRequestsModal();
    setActionType("removeFriend");
  };

  const handleAcceptRequest = () => {
    handleOpenRequestsModal();
    setActionType("acceptFriend");
  };

  const handleDeclineRequest = () => {
    handleOpenRequestsModal();
    setActionType("declineFriend");
  };

  const handleBlackList = () => {
    handleOpenRequestsModal();
    setActionType("blackListFriend");
  };

  const handleUnBlock = () => {
    handleOpenRequestsModal();
    setActionType("unblock");
  };

  const handleFavourite = () => {
    handleOpenRequestsModal();
    setActionType("addFavourite");
  };

  const handleRemoveFavourite = () => {
    handleOpenRequestsModal();
    setActionType("removeFavourite");
  };

  const handleSendReport = () => {
    handleOpenRequestsModal();
    setActionType("sendReport");
  };

  const sendMessage = () => {
    navigate("/mailbox/inbox", {
      state: { activeId: user?.data?.id, activeUsername: user?.data?.username },
    });
  };
  useEffect(() => {
    if (friends.some((item) => item.id === user?.data?.id)) {
      setIsfriend("friend");
    } else if (requestsSent.some((item) => item.id === user?.data?.id)) {
      setIsfriend("sent");
    } else if (requestsRecieved.some((item) => item.id === user?.data?.id)) {
      setIsfriend("received");
    } else if (blacklist.some((item) => item.id === user?.data?.id)) {
      setIsfriend("blacklist");
    } else {
      setIsfriend(null);
    }

    if (favourites.some((item) => item.id === user?.data?.id)) {
      setIsfavourite(true);
    } else {
      setIsfavourite(false);
    }
  }, [friends, blacklist, requestsRecieved, requestsSent, favourites]);

  return (
    <>
      <RequestsModal
        handleCloseRequestsModal={handleCloseRequestsModal}
        openRequestsModal={openRequestsModal}
        actionType={actionType}
        user={user}
      />
      <Box>
        <Box className="sm:px-0 px-10">
          <Box className="p-3 rounded-md bg-white">
            {user?.data?.photoUrl ? (
              <img src={img} alt="" className="w-full rounded-md" />
            ) : (
              <img
                src={user?.data?.gender === "Male" ? dummyImg : dummyImgFemale}
                alt=""
                className="w-full rounded-md"
              />
            )}
          </Box>
        </Box>

        <Box className="flex sm:px-0 px-10 py-2  justify-between w-[100%] border">
          <Box className="mt-2">
            {isFavourite ? (
              <button
                className="flex items-center space-x-2 rounded-md px-2 py-2 "
                style={{ backgroundColor: Colors.primary2 }}
                onClick={handleRemoveFavourite}>
                <FavoriteBorderIcon style={{ color: "#fff" }} />
                <Typography className="text-[12px] text-white">
                  כמוני
                </Typography>
              </button>
            ) : (
              <button
                className="flex items-center space-x-2 rounded-md px-2 py-2 "
                style={{ backgroundColor: Colors.primary2 }}
                onClick={handleFavourite}>
                <FavoriteBorderIcon style={{ color: "#fff" }} />
                <Typography className="text-[12px] text-white">
                  כמוני
                </Typography>
              </button>
            )}
          </Box>
          <Box className=" border sm:px-0 px-5">
            <Typography className="font-bold capitalize text-[20px]">
              {user?.data?.username}
            </Typography>
            <Box className="flex items-center  space-x-2">
              <Typography> מנוי: חינם |</Typography>
            </Box>
            <Box className="flex items-center space-x-2">
              <Typography style={{ color: "#777", fontSize: "13px" }}>
                אונליין
              </Typography>
              {user?.data?.active ? (
                <FiberManualRecordIcon
                  style={{
                    fontSize: "14px",
                    color: "green",
                  }}
                />
              ) : (
                <FiberManualRecordIcon
                  style={{
                    fontSize: "14px",
                    color: "white",
                  }}
                />
              )}
            </Box>
            <Typography style={{ color: "#777", fontSize: "13px" }}>
              גיל {user?.data?.age}
            </Typography>
            <Box className="flex items-center space-x-2 ">
              <Typography style={{ color: "#777", fontSize: "13px" }}>
                {" "}
                {city}
              </Typography>
              <LocationOn style={{ fontSize: "13px", color: "#777" }} />
            </Box>
            {/* Submit button */}
          </Box>
        </Box>
        <Box className="sm:px-0 px-10">
          <button
            onClick={sendMessage}
            disabled={
              MyAccount?.data?.gender == "Male" && !MyAccount?.data?.premium
            }
            className={`${
              MyAccount?.data?.premium || MyAccount?.data?.gender === "Female"
                ? "cursor-pointer"
                : "cursor-not-allowed"
            } py-3 px-2 w-full text-white rounded-md`}
            style={{
              background:
                MyAccount?.data?.premium || MyAccount?.data?.gender === "Female"
                  ? Colors.secondary
                  : "#777777",
            }}>
            {/* Send Message */} שלח הודעה
          </button>
          {!MyAccount?.data?.premium && MyAccount?.data?.gender === "Male" && (
            <span className="text-[10px] text-red-600">
              "רק חשבון פרימיום יכול לשלוח או לקבל הודעות. אנא פנה למנהל
              לתמיכה."
            </span>
          )}
        </Box>

        <Box className="space-y-6 mt-5 sm:px-0 px-10">
          {isFriend === "blacklist" ? (
            <button
              className="flex items-center outline-none mb-2 border-none space-x-2 bg-none cursor-pointer"
              onClick={handleUnBlock}>
              <Block style={{ color: "#777", fontSize: "25px" }} />{" "}
              <Typography style={{ color: Colors.secondary, fontSize: "15px" }}>
                {/* Unblock User */} בטל חסימת משתמש
              </Typography>
            </button>
          ) : (
            <Box>
              {isFriend === "friend" || isFriend === "sent" ? (
                <button
                  className="flex items-center outline-none mb-2 border-none space-x-2 bg-none cursor-pointer"
                  onClick={handleRemoveFriend}>
                  <PersonAdd style={{ color: "#777", fontSize: "25px" }} />{" "}
                  <Typography
                    style={{ color: Colors.secondary, fontSize: "15px" }}>
                    הסר חבר
                  </Typography>
                </button>
              ) : isFriend === "received" ? (
                <>
                  <button
                    className="flex items-center outline-none mb-2 border-none space-x-2 bg-none cursor-pointer"
                    onClick={handleAcceptRequest}>
                    <PersonAdd style={{ color: "#777", fontSize: "25px" }} />{" "}
                    <Typography
                      style={{ color: Colors.secondary, fontSize: "15px" }}>
                      קבל בקשת חברות
                    </Typography>
                  </button>
                  <button
                    className="flex items-center outline-none mb-2 border-none space-x-2 bg-none cursor-pointer"
                    onClick={handleDeclineRequest}>
                    <PersonRemove style={{ color: "#777", fontSize: "25px" }} />{" "}
                    <Typography
                      style={{ color: Colors.secondary, fontSize: "15px" }}>
                      דחה בקשת חברות
                    </Typography>
                  </button>
                </>
              ) : (
                <button
                  className="flex items-center outline-none mb-2 border-none space-x-2 bg-none cursor-pointer"
                  onClick={handleSendRequest}>
                  <PersonAdd style={{ color: "#777", fontSize: "25px" }} />{" "}
                  <Typography
                    style={{ color: Colors.secondary, fontSize: "15px" }}>
                    שלח בקשת חברות
                  </Typography>
                </button>
              )}

              <button
                className="flex items-center outline-none mb-2 border-none space-x-2 bg-none cursor-pointer"
                onClick={handleBlackList}>
                <Block style={{ color: "#777", fontSize: "25px" }} />{" "}
                <Typography
                  style={{ color: Colors.secondary, fontSize: "15px" }}>
                  רשימה שחורה
                </Typography>
              </button>

              {isFavourite ? (
                <button
                  className="flex items-center outline-none mb-2 border-none space-x-2 bg-none cursor-pointer"
                  onClick={handleRemoveFavourite}>
                  <Favorite style={{ color: "#777", fontSize: "25px" }} />{" "}
                  <Typography
                    style={{ color: Colors.secondary, fontSize: "15px" }}>
                    הסר חבר מהמועדפים
                  </Typography>
                </button>
              ) : (
                <button
                  className="flex items-center outline-none mb-2 border-none space-x-2 bg-none cursor-pointer"
                  onClick={handleFavourite}>
                  <Favorite style={{ color: "#777", fontSize: "25px" }} />{" "}
                  <Typography
                    style={{ color: Colors.secondary, fontSize: "15px" }}>
                    הוסף חבר למועדפים
                  </Typography>
                </button>
              )}

              <button
                className="flex items-center outline-none mb-2 border-none space-x-2 bg-none cursor-pointer"
                onClick={handleSendReport}>
                <Flag style={{ color: "#777", fontSize: "25px" }} />{" "}
                <Typography
                  style={{ color: Colors.secondary, fontSize: "15px" }}>
                  דווח על משתמש
                </Typography>
              </button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default OtherUserDetailsCard;
