import { Box } from "@mui/material";
import { Typography } from "antd";
import React from "react";
import {
  Person,
  PersonAdd,
  StarBorder,
  People,
  StarSharp,
  RemoveCircleRounded,
  MessageRounded,
} from "@mui/icons-material";
import Colors from "../../helpers/Colors";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const { user } = useSelector((state) => state.auth);
  const { favourites, friends } = useSelector((state) => state.friends);

  const currentTime = new Date().getHours();
  let greeting;

  if (currentTime >= 5 && currentTime < 12) {
    greeting = "בוקר טוב,";
  } else if (currentTime >= 12 && currentTime < 18) {
    greeting = "צהריים טובים,";
  } else {
    greeting = "ערב טוב,";
  }

  return (
    <Box className="bg-white shadow-lg ">
      <Box>
        <Box className="px-5 py-2 flex justify-between items-center">
          <img
            src={
              user?.data?.gender === "Male"
                ? require("../../assets/icons/user.png")
                : require("../../assets/images/feIMG.jpg")
            }
            alt=""
          />
          <Box>
            <Typography
              className="text-[12px]"
              style={{ color: Colors.primary2 }}
            >
              {greeting}
            </Typography>
            <Typography
              className="text-[17px] capitalize font-black"
              style={{ color: Colors.primary2 }}
            >
              {user?.data?.username}
            </Typography>
          </Box>
        </Box>
        <Link to={`/userProfile`}>
          <Box className="border justify-end px-2 py-2.5 flex hover:bg-gray-100 cursor-pointer items-center space-x-1">
            <Typography>עריכת פרופיל</Typography>
            <Person style={{ color: Colors.primary2, fontSize: "19px" }} />
          </Box>
        </Link>
        <Link to="/userProfile/friendList">
          <Box className="border justify-end px-2 py-2.5 flex hover:bg-gray-100 cursor-pointer items-center space-x-1">
            <Typography>הוסיפו אותי לחברים</Typography>
            <PersonAdd style={{ color: Colors.primary2, fontSize: "19px" }} />
          </Box>
        </Link>
        <Link to="/userProfile/favouriteList">
          <Box className="border justify-end px-2 py-2.5 flex hover:bg-gray-100 cursor-pointer items-center space-x-1">
            <Typography>הוסיפו אותי למועדפים</Typography>
            <StarBorder style={{ color: Colors.primary2, fontSize: "19px" }} />
          </Box>
        </Link>
        <Link to="/userProfile/friendList">
          <Box className="border justify-between px-2 py-2.5 flex items-center space-x-1 hover:bg-gray-100 cursor-pointer">
            <Box>
              <Typography className="text-[12px] text-gray-400">
                ({friends?.length})
              </Typography>
            </Box>

            <Box className="flex items-center space-x-1">
              <Typography>החברים שלי</Typography>
              <People style={{ color: Colors.primary2, fontSize: "19px" }} />
            </Box>
          </Box>
        </Link>
        <Link to="/userProfile/favouriteList">
          <Box className="border justify-between px-2 py-2.5 flex items-center space-x-1 hover:bg-gray-100 cursor-pointer">
            <Box>
              <Typography className="text-[12px] text-gray-400">
                ({favourites?.length})
              </Typography>
            </Box>
            <Box className="flex items-center space-x-1">
              <Typography>המועדפים שלי</Typography>
              <StarSharp style={{ color: Colors.primary2, fontSize: "19px" }} />
            </Box>
          </Box>
        </Link>
        <Link to="/userProfile/blackList">
          <Box className="border justify-end px-2 py-2.5 flex hover:bg-gray-100 cursor-pointer items-center space-x-1">
            <Typography>הרשימה השחורה שלי</Typography>
            <RemoveCircleRounded
              style={{ color: Colors.primary2, fontSize: "19px" }}
            />
          </Box>
        </Link>
        <Link to="/blog">
          <Box className="border justify-between px-2 py-2.5 flex items-center space-x-1 hover:bg-gray-100 cursor-pointer">
            <Box>
              <Typography className="text-[12px] text-gray-400">
                קרא עוד
              </Typography>
            </Box>
            <Box className="flex items-center space-x-1">
              <Typography>הבלוג שלנו</Typography>
              <MessageRounded
                style={{ color: Colors.primary2, fontSize: "19px" }}
              />
            </Box>
          </Box>
        </Link>
      </Box>
      <Box className="p-2">
        <Box className="bg-[#edebe5] text-right p-2 ">
          <Box className="border-b py-2">
            <Typography className="text-[10px]">
              7 טיפים לזוגיות להתחלת קשר מוצלחת במיוחד
            </Typography>

            <Typography className="text-[11px] text-gray-400">
              0 תגובות
            </Typography>
          </Box>
          <Box className="border-b py-2">
            <Typography className="text-[10px]">
              החיפוש אחר האושר לא נגמר לעולם החיים יכולים להיות יפים ונעימים –
              קבלו 7 טיפים לקבל ולשמר את ניצוץ בחיים בכלל ובמערכות יחסים בפרט.
            </Typography>
            <Typography className="text-[11px] text-gray-400">
              3 תגובות
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
