import React, { useEffect, useState } from "react";
import AuthNavbar from "../../components/Navbars/AuthNavbar";
import AuthFooter from "../../components/Navbars/AuthFooter";
import { Box, Grid } from "@mui/material";
import { Typography, Button } from "antd";
import RegisterForm from "./RegisterForm";
import Colors from "../../helpers/Colors";
import { ClipLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Registration = () => {
  const navigate = useNavigate();

  const [gender, setGender] = useState("");
  const [genderKind, setGenderKind] = useState("");
  const [genderInterest, setGenderInterest] = useState("");
  const [isGender, setIsGender] = useState(true);
  const [isGenderKind, setIsGenderKind] = useState(false);
  const [isGenderInterest, setIsGenderInterest] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const { user, token, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("registration"));
    const statusRegistration = JSON.parse(
      localStorage.getItem("registration1")
    );

    if (user?.status === true || token?.status === true) {
      navigate("/main");
    }
    if (userData) {
      navigate("/complete-reg", {
        state: {
          userData,
        },
      });
    }

    if (statusRegistration) {
      navigate("/verification");
    }
  }, []);

  const handleIsFemaleGender = (e) => {
    setGender(" אני");
    setIsGender(false);
    setIsGenderKind(true);
  };

  const handleIsMaleGender = (e) => {
    setGender("גבר");
    setIsGender(false);
    setIsGenderKind(true);
  };

  const handleIsSugarMammaDaddy = () => {
    setGenderKind(gender == "גבר" ? "שוגר דדי" : "שוגר מאמי");
    setIsGender(false);
    setIsGenderKind(false);
    setIsGenderInterest(true);
  };

  const handleIsSugarBaby = () => {
    setGenderKind(gender == "גבר" ? "שוגר בייב" : "שוגר בייבי");
    setIsGender(false);
    setIsGenderKind(false);
    setIsGenderInterest(true);
  };

  const HandleWomenterest = () => {
    setGenderInterest("נשים");
    setIsGender(false);
    setIsGenderKind(false);
    setIsGenderInterest(false);
    setIsForm(true);
  };

  const handleMenterest = () => {
    setGenderInterest("גברים");
    setIsGender(false);
    setIsGenderKind(false);
    setIsGenderInterest(false);
    setIsForm(true);
  };

  const HandleBothInterest = () => {
    setGenderInterest("שניהם");
    setIsGender(false);
    setIsGenderKind(false);
    setIsGenderInterest(false);
    setIsForm(true);
  };

  console.log(gender);
  return (
    <>
      <AuthNavbar />
      {isLoading && (
        <Box className="absolute w-full bg-[#FFFFFF80] h-screen flex items-center justify-center z-100">
          <ClipLoader
            color={Colors.white}
            loading={isLoading}
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </Box>
      )}

      <Box className="sm:h-screen h-screen sm:py-0 py-16  register-bg">
        <Box className="px-6 ">
          <Grid container>
            <Grid item xs={12} sm={12} md={7}></Grid>
            <Grid item xs={12} sm={12} md={5}>
              <Typography className="text-white text-[45px]">
                פתיחת חשבון
              </Typography>
              <Typography
                className="text-[#f9f9f9] font-semibold text-[18px]"
                style={{ lineHeight: "1.5rem" }}
              >
                הצטרפו עכשיו חינם לשוגר דדי
                <br />
                ומצאו את הדייט המושלם!
              </Typography>

              {isGender && (
                <Box className="flex flex-wrap space-x-2 mt-5">
                  <Typography className="text-gray-400 text-[15px]">
                    אני
                  </Typography>
                  <Button
                    className="bg-gray-500 hover:text-red-500  hover:bg-[#EBD165] border-none px-10 text-white  reg-btn"
                    value=" אני"
                    onClick={handleIsFemaleGender}
                  >
                    אישה
                  </Button>
                  <Typography className="text-white  text-[17px]">
                    או
                  </Typography>
                  <Button
                    className="bg-gray-500 px-10 hover:bg-[#EBD165] border-none text-white  reg-btn"
                    value="גבר"
                    onClick={handleIsMaleGender}
                  >
                    גבר
                  </Button>
                  <Box className="h-[49vh]"></Box>
                </Box>
              )}

              {isGenderKind && (
                <Box>
                  <Typography className="text-gray-400 text-[12px]">
                    אני {gender}, מסוג,
                  </Typography>
                  <Box className="flex space-x-2 mt-5">
                    <Button
                      className="bg-gray-500 hover:text-red-500  hover:bg-[#EBD165] border-none px-10 text-white  reg-btn"
                      value=" אני"
                      onClick={handleIsSugarBaby}
                    >
                      {gender == "גבר" ? "שוגר בייב" : "שוגר בייבי"}
                    </Button>
                    <Typography className="text-white  text-[17px]">
                      או
                    </Typography>
                    <Button
                      className="bg-gray-500 px-10 hover:bg-[#EBD165] border-none text-white  reg-btn"
                      value="גבר"
                      onClick={handleIsSugarMammaDaddy}
                    >
                      {gender == "גבר" ? "שוגר דדי" : "שוגר מאמי"}
                    </Button>
                  </Box>
                  <Box className="h-[49vh]"></Box>
                </Box>
              )}

              {isGenderInterest && (
                <Box>
                  <Typography className="text-gray-400 text-[12px]">
                    אני {gender}, מסוג {genderKind}, מתעניין/ת ב
                  </Typography>
                  <Box className="flex space-x-2 mt-5">
                    <Button
                      className="bg-gray-500 hover:text-red-500  hover:bg-[#EBD165] border-none px-9 text-white  reg-btn"
                      value="גברים"
                      onClick={handleMenterest}
                    >
                      גברים
                    </Button>

                    <Button
                      className="bg-gray-500 px-9 hover:bg-[#EBD165] border-none text-white  reg-btn"
                      value="נשים"
                      onClick={HandleWomenterest}
                    >
                      נשים
                    </Button>
                    <Typography className="text-white  text-[17px]">
                      או
                    </Typography>
                    <Button
                      className="bg-gray-500 px-9 hover:bg-[#EBD165] border-none text-white  reg-btn"
                      value="שניהם"
                      onClick={HandleBothInterest}
                    >
                      שניהם
                    </Button>
                  </Box>
                </Box>
              )}

              {isForm && (
                <Box>
                  <Typography className="text-gray-400 text-[12px]">
                    אני {gender}, מסוג {genderKind}, מתעניין/ת ב{genderInterest}
                  </Typography>

                  <RegisterForm
                    isGenderInterest={genderInterest}
                    gender={gender}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <AuthFooter />
    </>
  );
};

export default Registration;
