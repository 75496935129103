import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom"; // Don't forget to import useLocation

const ProtectedRoutes = () => {
  const { user, token } = useSelector((state) => state.auth);

  const location = useLocation();

  return user?.status === true || token ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
