const primary = "#EBD165";
const primary2 = "#ab8920";
const white = "#fff";
const black = "#000";
const secondary = "#D71D6D";
const gray = "#e6e6e6";
const gray2 = "#eae9e7";

const Colors = {
  primary,
  white,
  black,
  secondary,
  primary2,
  gray,
  gray2,
};

export default Colors;
