import { Box } from "@mui/material";
import { Typography } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import Colors from "../../helpers/Colors";

const LikeMeSidebar = () => {
  const location = useLocation();
  return (
    <>
      <Typography className="text-[25px] font-bold text-right">
        Like Me
      </Typography>
      <Box className="bg-white">
        <Link>
          <Box
            className={`${
              location.pathname.includes("play_local") ? "bg-gray-100" : ""
            } border py-2 px-3 text-right`}
          >
            <Typography
              className="text-[16px]"
              style={{ color: Colors.secondary }}
            >
              התחל לשחק
            </Typography>
          </Box>
        </Link>
        <Link>
          <Box
            className={`${
              location.pathname.includes("matches") ? "bg-gray-100" : ""
            } border py-2 px-3 text-right`}
          >
            <Typography
              className="text-[16px]"
              style={{ color: Colors.secondary }}
            >
              ההתאמות שלי
            </Typography>
          </Box>
        </Link>
        <Link>
          <Box
            className={`${
              location.pathname.includes("I-like") ? "bg-gray-100" : ""
            } border py-2 px-3 text-right`}
          >
            <Typography
              className="text-[16px]"
              style={{ color: Colors.secondary }}
            >
              I Like
            </Typography>
          </Box>
        </Link>
        <Link>
          <Box
            className={`${
              location.pathname.includes("like-me") ? "bg-gray-100" : ""
            } border py-2 px-3 text-right`}
          >
            <Typography
              className="text-[16px]"
              style={{ color: Colors.secondary }}
            >
              Like me
            </Typography>
          </Box>
        </Link>
      </Box>
    </>
  );
};

export default LikeMeSidebar;
