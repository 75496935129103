import * as React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Lock } from "@mui/icons-material";
import AddGallery from "../AddGallery";
import { Typography } from "antd";
import Colors from "../../../helpers/Colors";
import { Link, useNavigate } from "react-router-dom";
import { days, months, years } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { reset, updatePersonalInfo } from "../../../features/auth/authSlice";
import { ClipLoader } from "react-spinners";
import { CustomTabPanel, a11yProps } from "../../../helpers";
import { useState } from "react";

const validationSchema = yup.object({
  interest: yup.string().required("נדרשת תחום עניין"),
  nickname: yup.string().required("נדרש שם כינוי"),
  year: yup.string().required("נדרש שנת לידה"),
  month: yup.string().required("נדרש חודש לידה"),
  day: yup.string().required("נדרש יום לידה"),
  city: yup.string().required("נדרשת עיר"),
});

export default function PersonalDetails() {
  const { user, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.auth
  );
  const [filteredCities, setFilteredCities] = React.useState([]);
  const [hometownId, setHomeTownId] = useState([]);

  const { cities } = useSelector((state) => state.cities);

  const [value, setValue] = React.useState(0);
  const [Error, setError] = React.useState();
  const [Success, setSuccess] = React.useState();
  const dispatch = useDispatch();
  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    formik.setFieldValue("city", searchTerm);

    const filteredCities = cities?.data?.filter(
      (city) =>
        city?.englishName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        city?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCities(filteredCities);
  };

  const handleCitySelect = (city, hebrewName) => {
    formik.setFieldValue("hometown", JSON.stringify(`${city}(${hebrewName})`));
    setHomeTownId(hebrewName);
    setFilteredCities([]); // Clear the filtered cities list
  };

  const formik = useFormik({
    initialValues: {
      interest: "",
      nickname: user?.data?.premium === false ? user?.data?.username : "",
      year: "",
      month: "",
      day: "",
      city: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = {
        interest: values.interest,
        nickname: user?.data?.premium ? user?.data?.username : values.nickname,
        dateOfBirth: `${values.year}-${values.month}-${values.day}`,
        city: hometownId,
      };
      dispatch(updatePersonalInfo(data));
    },
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (isError) {
      setError(message);
    }

    if (isSuccess && message === "Personal Info Updated Successfully") {
      setError("");
      setSuccess("מידע אישי עודכן בהצלחה");
      setInterval(() => {
        setSuccess("");
        dispatch(reset());
      }, 3000);
    }

    dispatch(reset());

    // Cleanup function
    return () => {
      // Reset state on component unmount as well
      dispatch(reset());
    };
  }, [isError, isSuccess, dispatch, message]);

  return (
    <Box className="px-1 w-full">
      <Box sx={{ width: "100%" }} className="py-4">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
          className="flex  items-center justify-end"
        >
          <Tabs
            value={value}
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: "#fff",
                background: "#000",
                fontSize: "17px",
                borderRadius: "5px",
                padding: "2px 2px",
              },
              "& .MuiTabs-indicator": { bgcolor: "#000" },
              "& .MuiTab-root": {
                color: "#000",
                fontSize: "17px",
                borderRadius: "5px",
              },
            }}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="פרופיל" {...a11yProps(1)} />
            <Tab label="גלריה" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box className="bg-white sm:p-4 p-2">
            {Error && (
              <Typography className="text-[12px] text-red-500">
                {Error}
              </Typography>
            )}
            {Success && (
              <Typography className="text-[12px] text-green-500">
                {Success}
              </Typography>
            )}
            <form onSubmit={formik.handleSubmit}>
              <Box className="my-3">
                <Box>
                  <Typography className="text-[12px]">מחפש:</Typography>
                  <select
                    name="interest"
                    id="interest"
                    className="w-full border py-2.5 mt-1"
                    style={{ border: "1px solid gray" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.interest}
                  >
                    <option value="" selected disabled>
                      תחומי עניין
                    </option>
                    <option value="Men">גברים</option>
                    <option value="Women">נשים</option>
                    <option value="Both">שניהם</option>
                  </select>
                  {formik.touched.interest && formik.errors.interest ? (
                    <div className="error text-[9px] text-red-500">
                      {formik.errors.interest}
                    </div>
                  ) : null}
                </Box>
              </Box>

              <Box className="my-3">
                <Box>
                  {!user?.data?.premium ? (
                    <Box className="flex space-x-1 items-center">
                      <Lock style={{ fontSize: "12px" }} />
                      <Typography className="text-[12px]">
                        כינוי (שינוי כינוי למנויים מתקדמים בלבד):
                      </Typography>
                    </Box>
                  ) : (
                    <Box className="flex space-x-1 items-center">
                      <Typography className="text-[12px]">כינוי:</Typography>
                    </Box>
                  )}

                  <input
                    type="text"
                    name="nickname"
                    id="nickname"
                    className={`w-full py-2.5 px-2 rounded-lg ${
                      user?.data?.premium === true ? "" : "bg-gray-200"
                    }`}
                    disabled={!user?.data?.premium}
                    placeholder={user?.data?.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nickname}
                    style={{ border: "1px solid gray" }}
                  />
                  {formik.touched.nickname && formik.errors.nickname ? (
                    <div className="error text-[9px] text-red-500">
                      {formik.errors.nickname}
                    </div>
                  ) : null}
                </Box>
              </Box>
              <Box className="my-3">
                <Box className="">
                  <Typography className="text-[12px]">תאריך לידה:</Typography>
                  <Box className="flex space-x-2 items-center">
                    <Box className="w-[31%]">
                      <select
                        className="w-[100%] border py-2 mr-2"
                        style={{ border: "1px solid gray" }}
                        name="year"
                        id="year"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.year}
                      >
                        <option value="">בחר שנה:</option>
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                      {formik.touched.year && formik.errors.year ? (
                        <div className="error text-[9px] text-red-500">
                          {formik.errors.year}
                        </div>
                      ) : null}
                    </Box>
                    <Box className="w-[31%]">
                      <select
                        className="w-[100%] border py-2 mr-2"
                        style={{ border: "1px solid gray" }}
                        name="month"
                        id="month"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.month}
                      >
                        {months.map((month) => (
                          <option key={month.value} value={month.value}>
                            {month.label}
                          </option>
                        ))}
                      </select>
                      {formik.touched.month && formik.errors.month ? (
                        <div className="error text-[9px] text-red-500">
                          {formik.errors.month}
                        </div>
                      ) : null}
                    </Box>
                    <Box className="w-[31%]">
                      <select
                        className="w-[100%] border py-2 mr-2"
                        style={{ border: "1px solid gray" }}
                        name="day"
                        id="day"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.day}
                      >
                        {days.map((day) => (
                          <option key={day} value={day}>
                            {day}
                          </option>
                        ))}
                      </select>
                      {formik.touched.day && formik.errors.day ? (
                        <div className="error text-[9px] text-red-500">
                          {formik.errors.day}
                        </div>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* Other form fields */}
              <Box className="my-3 relative">
                <Box>
                  <Typography className="text-[12px]">
                    עיר מגורים (אנא המתן להשלמה אוטומטית):
                  </Typography>

                  <input
                    name="city"
                    id="city"
                    type="text"
                    className="w-full py-2.5 px-2 rounded-lg"
                    style={{ border: "1px solid gray" }}
                    onChange={handleSearchChange}
                    value={formik.values.city}
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <div className="error text-[9px] text-red-500">
                      {formik.errors.city}
                    </div>
                  ) : null}

                  {filteredCities.length > 0 && (
                    <Box className="bg-white z-10 rounded-md absolute w-[100%] p-2 items-center justify-center flex-col">
                      <ul className="space-y-2 h-[40vh] overflow-y-scroll cursor-pointer ">
                        {filteredCities.map((city, index) => (
                          <li
                            className="text-black capitalize text-[12px]"
                            key={index}
                            onClick={() =>
                              handleCitySelect(city.englishName, city.name)
                            }
                          >
                            {city.englishName} ({city.name})
                          </li>
                        ))}
                      </ul>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="py-2 px-5 rounded-md text-white"
                  style={{ background: Colors.secondary }}
                >
                  {isLoading ? (
                    <ClipLoader
                      color={Colors.white}
                      loading={isLoading}
                      size={10}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  ) : (
                    "שמירה"
                  )}
                </button>
                <Link to={`/userProfile`}>
                  <button
                    className="py-2 px-5 rounded-md bg-none text-white"
                    style={{ color: Colors.secondary }}
                  >
                    חזרה
                  </button>
                </Link>
              </Box>
            </form>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box className="bg-white p-2">
            <AddGallery />
          </Box>
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
