import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import SearchedUsers from "./SearchedUsers";
import { Typography } from "antd";
import Colors from "../../helpers/Colors";
import { CustomTabPanel, a11yProps } from "../../helpers";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";

export default function SearchResults({
  filter,
  searcheadName,
  isOnlineChecked,
}) {
  const { users } = useSelector((state) => state.auth);
  const [value, setValue] = React.useState(0);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(8);
  const initialLimit = 4;
  const loadMoreLimit = 4;
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    setFilteredUsers(getFilteredUsers());
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [filter, searcheadName, isOnlineChecked, filteredUsers]);

  let filteredList = users?.data || [];
  let filteredListCopy = filteredList.slice(); // Make a copy to avoid mutating original data
  const getFilteredUsers = () => {
    if (filter?.ageTo && filter?.ageFrom && filter?.hometownId) {
      filteredListCopy = filteredListCopy.filter(
        (user) =>
          user.city === filter.hometownId &&
          user.age > filter.ageFrom &&
          user.age < filter.ageTo
      );
    } else if (filter?.ageTo && filter?.ageFrom) {
      filteredListCopy = filteredListCopy.filter(
        (user) => user.age > filter.ageFrom && user.age < filter.ageTo
      );
    } else if (filter?.hometownId) {
      filteredListCopy = filteredListCopy.filter(
        (user) => user.city === filter.hometownId
      );
    }

    if (searcheadName) {
      filteredListCopy = filteredListCopy.filter((user) =>
        user.username?.toLowerCase()?.includes(searcheadName.toLowerCase())
      );
    }

    if (isOnlineChecked) {
      filteredListCopy = filteredListCopy.filter((user) => user.active);
    }

    const activeUsers = filteredListCopy.filter((user) => user.active);
    const inactiveUsers = filteredListCopy.filter((user) => !user.active);
    const concatenatedUsers = activeUsers.concat(inactiveUsers);

    return concatenatedUsers.slice(0, limit < 1 ? initialLimit : limit);
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (
      scrollTop + clientHeight >= scrollHeight - 100 &&
      !searcheadName &&
      !filter &&
      !isOnlineChecked
    ) {
      if (!loading && filteredUsers.length < users?.data?.length) {
        setLoading(true);
        setLimit(limit + 4);
        setTimeout(() => {
          const newOffset = offset + loadMoreLimit;
          const newUsers = users?.data?.slice(0, limit + 4);

          setFilteredUsers(newUsers);
          setOffset(newOffset);
          setLoading(false);
        }, 1000);
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const activeUsers = filteredUsers.filter((user) => user.active);
  const inactiveUsers = filteredUsers.filter((user) => !user.active);
  const concatenatedUsers = activeUsers.concat(inactiveUsers);

  return (
    <Box sx={{ width: "100%" }}>
      <Box className="flex items-center space-x-2 justify-between">
        <Box>
          <Typography className="text-[12px]">
            נמצאו{" "}
            <span style={{ color: Colors.primary2 }} className="font-black">
              {filteredUsers.length}
            </span>{" "}
            חברים
          </Typography>
        </Box>
        <Box
          sx={{ borderBottom: 1, borderColor: "#fff" }}
          className="flex w-[85%] items-center justify-end">
          <Tabs
            value={value}
            sx={{
              "& .MuiTab-root.Mui-selected": { color: "#d71d6d" },
              "& .MuiTabs-indicator": { bgcolor: "#d71d6d" },
            }}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab label="כל התוצאות" {...a11yProps(0)} />
            <Tab label="חדשים ביותר" {...a11yProps(1)} />
            <Tab label="נצפים ביותר" {...a11yProps(2)} />
          </Tabs>
        </Box>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {filteredUsers?.length === 0 ? (
          <Typography>המשתמשים לא נמצאו</Typography>
        ) : (
          <SearchedUsers users={concatenatedUsers} />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {filteredUsers?.length === 0 ? (
          <Typography>המשתמשים לא נמצאו</Typography>
        ) : (
          <SearchedUsers users={concatenatedUsers} />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {filteredUsers?.length === 0 ? (
          <Typography>המשתמשים לא נמצאו</Typography>
        ) : (
          <SearchedUsers users={concatenatedUsers} />
        )}
      </CustomTabPanel>
      <Box className="flex items-center justify-center">
        <ClipLoader
          color={Colors.primary}
          loading={loading}
          size={25}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </Box>
    </Box>
  );
}
