import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Typography } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reset, updateAccountInfo } from "../../../features/auth/authSlice";
import { useFormik } from "formik";
import * as yup from "yup";
import AddGallery from "../AddGallery";
import Colors from "../../../helpers/Colors";
import { ClipLoader } from "react-spinners";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const validationSchema = yup.object({
  occupation: yup.string().required("שדה חובה"),
  sponsor: yup.string().required("שדה חובה"),
  relationshipPurpose: yup.string().required("שדה חובה"),
  Availability: yup.string().required("שדה חובה"),
  Income: yup.string().required("שדה חובה"),
  Motto: yup.string().required("שדה חובה"),
  AboutYou: yup.string().required("שדה חובה"),
  hobbies: yup.array().min(1, "בחר לפחות תחביב אחד"),
  languages: yup.array().min(1, "בחר לפחות שפה אחת"),
});

export default function AccountDetails() {
  const [value, setValue] = React.useState(0);
  const { user, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.auth
  );
  const [Error, setError] = React.useState();
  const [Success, setSuccess] = React.useState();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      occupation: user?.data?.occupation || "",
      sponsor: user?.data?.sponsorKind || "",
      relationshipPurpose: user?.data?.relationshipPurpose || "",
      Availability: user?.data?.availability || "",
      Income: user?.data?.income || "",
      Motto: user?.data?.motto || "",
      AboutYou: user?.data?.about || "",
      hobbies: user?.data?.hobbies || [],
      languages: user?.data?.languages || [],
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);

      const data = {
        occupation: values.occupation,
        sponsorKind: values.sponsor,
        relationshipPurpose: values.relationshipPurpose,
        availability: values.availability,
        income: values.Income,
        motto: values.Motto,
        about: values.AboutYou,
        hobbies: values.hobbies,
        languages: values.languages,
      };

      dispatch(updateAccountInfo(data));
    },
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (isError) {
      setError(message);
    }

    if (isSuccess && message === "Account Info Updated Successfully") {
      setError("");
      setSuccess("מידע החשבון עודכן בהצלחה");
      setInterval(() => {
        setSuccess("");
        dispatch(reset());
      }, 3000);
    }

    dispatch(reset());

    // Cleanup function
    return () => {
      // Reset state on component unmount as well
      dispatch(reset());
    };
  }, [isError, isSuccess, dispatch, message]);

  return (
    <Box className="px-1 w-full">
      <Box sx={{ width: "100%" }} className="py-4">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
          className="flex  items-center justify-end"
        >
          <Tabs
            value={value}
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: "#fff",
                background: "#000",
                fontSize: "17px",
                borderRadius: "5px",
                padding: "2px 2px",
              },
              "& .MuiTabs-indicator": { bgcolor: "#000" },
              "& .MuiTab-root": {
                color: "#000",
                fontSize: "17px",
                borderRadius: "5px",
              },
            }}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="פרופיל" />
            <Tab label="גלריה" />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box className="bg-white sm:p-4 p-2">
            {Error && (
              <Typography className="text-[12px] text-red-500">
                {Error}
              </Typography>
            )}
            {Success && (
              <Typography className="text-[12px] text-green-500">
                {Success}
              </Typography>
            )}
            <form onSubmit={formik.handleSubmit} className="mt-2">
              <Box className="space-y-3">
                <div className="relative">
                  <select
                    name="occupation"
                    id=""
                    className="w-full border py-2 text-[13px]"
                    style={{ border: "1px solid gray" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.occupation}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * העיסוק שלי
                    </option>
                    <option value="Advocate">עו״ד</option>
                    <option value="Doctor">רופא</option>
                    <option value="BusinessMan">איש עסקים</option>
                    <option value="RealEstate">נדל״ן</option>
                    <option value="Engineer">מהנדס</option>
                    <option value="InsuranceAgent">סוכן ביטוח</option>
                    <option value="PermanentPersonnel">איש קבע</option>
                    <option value="Sales">מכירות</option>
                    <option value="Politics">פוליטיקה</option>
                    <option value="Professional">בעל מקצוע</option>
                    <option value="StockExchange">בורסה</option>
                    <option value="HiTech">היי-טק</option>
                  </select>
                  {formik.touched.occupation && formik.errors.occupation ? (
                    <span className=" text-[9px] " style={{ color: "red" }}>
                      (*) {formik.errors.occupation}
                    </span>
                  ) : null}
                </div>

                {/* sponsor field */}
                <div className="relative">
                  <select
                    name="sponsor"
                    id=""
                    className="w-full border py-2 text-[13px]"
                    style={{ border: "1px solid gray" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sponsor}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * איזה סוג ספונסר/ית אני?
                    </option>
                    <option value="Basic">
                      צרכים בסיסיים כתשלום שכ״ד וכו׳
                    </option>
                    <option value="BasicAndGifts">
                      צרכים בסיסיים + מתנות{" "}
                    </option>
                    <option value="Philanthropist">נדבני</option>
                    <option value="Extravagant">אורח חיים בזבזני</option>
                  </select>
                  {formik.touched.sponsor && formik.errors.sponsor ? (
                    <span className=" text-[9px] " style={{ color: "red" }}>
                      (*) {formik.errors.sponsor}
                    </span>
                  ) : null}
                </div>

                {/* Relationship purpose */}
                <div className="relative">
                  <select
                    name="relationshipPurpose"
                    id="relationshipPurpose"
                    className="w-full border py-2 text-[13px]"
                    style={{ border: "1px solid gray" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.relationshipPurpose}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * מטרת הקשר
                    </option>
                    <option value="Flowing">זורם/ת. לאן שנגיע-נגיע</option>
                    <option value="Friendship">חברות, מתנות ובילויים </option>
                    <option value="Connection">מוגבל לקשר בלבד</option>
                    <option value="Marriage">קשר למטרת נישואין</option>
                  </select>
                  {formik.touched.relationshipPurpose &&
                  formik.errors.relationshipPurpose ? (
                    <span className=" text-[9px] " style={{ color: "red" }}>
                      (*) {formik.errors.relationshipPurpose}
                    </span>
                  ) : null}
                </div>

                {/* Availabilty  */}
                <div className="relative">
                  <select
                    name="Availability"
                    id=""
                    className="w-full border py-2 text-[13px]"
                    style={{ border: "1px solid gray" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Availability}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * רמת הזמינות שלי
                    </option>
                    <option value="Mornings">בקרים</option>
                    <option value="AllDay">כל היום </option>
                    <option value="Evening">שעות הערב</option>
                    <option value="Weekends">סופ״שים</option>
                  </select>
                  {formik.touched.Availability && formik.errors.Availability ? (
                    <span className=" text-[9px] " style={{ color: "red" }}>
                      (*) {formik.errors.Availability}
                    </span>
                  ) : null}
                </div>

                {/* Income  */}
                <div className="relative">
                  <select
                    name="Income"
                    id=""
                    className="w-full border py-2 text-[13px]"
                    style={{ border: "1px solid gray" }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Income}
                  >
                    <option
                      value=""
                      selected
                      disabled
                      className="text-gray-400"
                    >
                      * ההכנסה שלי
                    </option>
                    <option value="K_10_Or_Less">₪ 10,000 או פחות</option>
                    <option value="K_10_15">₪10,000-15,000 </option>
                    <option value="K_15_30">₪15,000-30,000 </option>
                    <option value="K_30_More">יותר מ- 30,000₪</option>

                    <option value="VeryRich">אני מאוד עשיר/ה</option>
                  </select>
                  {formik.touched.Income && formik.errors.Income ? (
                    <span className=" text-[9px] " style={{ color: "red" }}>
                      (*) {formik.errors.Income}
                    </span>
                  ) : null}
                </div>

                {/* Motto input */}
                <div>
                  <input
                    type="text"
                    name="Motto"
                    className="w-full text-[13px] px-4 py-2 text-[13px] rounded-md outline-none"
                    placeholder="* מוטו בחיים"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Motto}
                    style={{ border: "1px solid gray" }}
                  />
                  {formik.touched.Motto && formik.errors.Motto ? (
                    <span className=" text-[9px] " style={{ color: "red" }}>
                      (*) {formik.errors.Motto}
                    </span>
                  ) : null}
                </div>

                {/* Textarea for AoutYou */}
                <div>
                  <textarea
                    name="AboutYou"
                    id=""
                    cols="30"
                    rows="3"
                    className="px-5 py-2 text-[13px] w-full rounded-md"
                    placeholder="* קצת עלי - מה החבר הכי טוב שלכם יספר עליכם (הרשו לעצמכם להתרברב אנחנו לא נישפוט :-) )"
                    onChange={formik.handleChange}
                    style={{ border: "1px solid gray" }}
                    onBlur={formik.handleBlur}
                    value={formik.values.AboutYou}
                  ></textarea>
                  {formik.touched.AboutYou && formik.errors.AboutYou ? (
                    <span className=" text-[9px] " style={{ color: "red" }}>
                      (*) {formik.errors.AboutYou}
                    </span>
                  ) : null}
                </div>

                {/* Checkboxes for hobbies */}
                <div>
                  <Typography className="text-[15px] font-semibold text-gray-500">
                    * תחביבים:
                  </Typography>
                  <div className="grid grid-cols-1 mt-2 md:grid-cols-2 lg:grid-cols-3 gap-y-2">
                    {[
                      "Trekking",
                      "Internet",
                      "FlightsAndTripsAbroad",
                      "Sport",
                      "SexAndErotica",
                      "Cinema",
                      "Sailing",
                      "Tracks",
                      "Politics",
                      "LuxuryCars",
                      "Clubbing",
                    ].map((hobby, index) => (
                      <label
                        key={index}
                        className="text-[11px] flex items-center space-x-2"
                      >
                        <input
                          type="checkbox"
                          name="hobbies"
                          style={{ accentColor: Colors.primary2, fontSize: 14 }}
                          value={hobby}
                          checked={formik.values.hobbies.includes(hobby)}
                          onChange={formik.handleChange}
                        />
                        <span>{hobby}</span>
                      </label>
                    ))}
                  </div>
                  {formik.touched.hobbies && formik.errors.hobbies ? (
                    <span className=" text-[9px] " style={{ color: "red" }}>
                      (*) {formik.errors.hobbies}
                    </span>
                  ) : null}
                </div>

                {/* Checkboxes for languages */}
                <div>
                  <Typography className="text-[15px] font-semibold text-gray-500">
                    * שפות:
                  </Typography>
                  <div className="grid grid-cols-1 mt-2 md:grid-cols-2 lg:grid-cols-3 gap-y-2">
                    {[
                      "English",
                      "French",
                      "Hebrew",
                      "Russian",
                      "Spanish",
                      "Arabic",
                      "Other",
                    ].map((language, index) => (
                      <label
                        key={index}
                        className="text-[11px] flex items-center space-x-2"
                      >
                        <input
                          type="checkbox"
                          name="languages"
                          style={{ accentColor: Colors.primary2 }}
                          value={language}
                          checked={formik.values.languages.includes(language)}
                          onChange={formik.handleChange}
                        />
                        <span>{language}</span>
                      </label>
                    ))}
                  </div>
                  {formik.touched.languages && formik.errors.languages ? (
                    <span className=" text-[9px] " style={{ color: "red" }}>
                      (*) {formik.errors.languages}
                    </span>
                  ) : null}
                </div>

                {/* Submit button */}
                <Box>
                  <button
                    type="submit"
                    className="py-2 px-5 rounded-md text-white"
                    style={{ background: Colors.secondary }}
                  >
                    {isLoading ? (
                      <ClipLoader
                        color={Colors.white}
                        loading={isLoading}
                        size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    ) : (
                      "שמירה"
                    )}
                  </button>
                  <Link to={`/userProfile`}>
                    <button
                      className="py-2 px-5 rounded-md bg-none text-white"
                      style={{ color: Colors.secondary }}
                    >
                      החזרה
                    </button>
                  </Link>
                </Box>
              </Box>
            </form>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box className="bg-white p-2">
            <AddGallery />
          </Box>
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
