import React, { useEffect, useState } from "react";
import AuthNavbar from "../../components/Navbars/AuthNavbar";
import { Box } from "@mui/material";
import { Typography } from "antd";
import Colors from "../../helpers/Colors";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { reset, verify } from "../../features/auth/authSlice";

const VerifyToken = () => {
  const location = useLocation();
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );
  const [savedUser, setSavedUser] = useState();
  const [error, setError] = useState();

  const { userId, token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      verify({
        userId,
        token: decodeURIComponent(token),
      })
    );
  }, []);

  useEffect(() => {
    if (isError) {
      setError(message);
    }

    if (isSuccess) {
      const userData = JSON.parse(localStorage.getItem("registration"));
      if (userData.status === true) {
        dispatch(reset());
        navigate("/complete-reg", {
          state: {
            Userdata: { userId, token },
          },
        });
      }
    }
    if (isSuccess && user?.status == false) {
      setError(user?.message);
    }

    dispatch(reset());
  }, [isError, isSuccess, dispatch, message]);

  // useEffect(() => {
  //   if (!isLoading) {
  //     const userData = JSON.parse(localStorage.getItem("registration"));
  //     setSavedUser(userData);

  //     // if (user?.status === true || token?.status === true) {
  //     //   navigate("/main");
  //     // }
  //     if (userData) {
  //       navigate("/complete-reg", {
  //         state: {
  //           userData,
  //         },
  //       });
  //     } else {
  //       navigate("/");
  //     }
  //   }
  // }, [isLoading]);

  return (
    <>
      <AuthNavbar />
      <Box className="h-screen overflow-x scroll verifyScreen py-10">
        <Box className="md:w-[44%] w-[85%] m-auto h-min-screen rounded-sm">
          <Box className="py-2 bg-white flex flex-col items-center justify-center h-[40vh] text-center">
            <Box>
              {error ? (
                <Typography className="text-red-500 text-[13px]">
                  {error}
                </Typography>
              ) : (
                <>
                  <ClipLoader
                    color={Colors.secondary}
                    loading={isLoading}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  <Typography classN4ame="text-center text-[13px] font-bold">
                    אימות...
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VerifyToken;
