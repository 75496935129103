import React from "react";
import logo from "../../assets/images/logo.png";
import { Box } from "@mui/material";
import { Typography } from "antd";
import Colors from "../../helpers/Colors";

const AuthFooter = () => {
  return (
    <Box
      className="w-[100%] text-center "
      style={{ backgroundColor: Colors.black }}
    >
      <img src={logo} alt="Logo" className="sm:w-[20%] w-[100%] m-auto" />
      <Box className="  text-center ">
        <Typography variant="h6" className="text-[13px] text-[#9f9aae]">
          כל הזכויות שמורות ל־Sugar Dadi. <br /> אנו לא אחראים על התוכן.{" "}
          <span href="" className="text-white">
            תנאי השימוש
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default AuthFooter;
