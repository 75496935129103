import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService, { logoutError } from "./authService";
const user = JSON.parse(localStorage.getItem("user"));
const token = JSON.parse(localStorage.getItem("token"));
const users = JSON.parse(localStorage.getItem("users"));

const initialState = {
  user: user ? user : null,
  token: token ? token : null,
  users: users ? users : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const register = createAsyncThunk(
  "auth/register",
  async (user, thunkAPI) => {
    console.log(user);
    try {
      const data = await authService.register(user);

      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verify = createAsyncThunk(
  "auth/verify",
  async (userData, thunkAPI) => {
    try {
      const data = await authService.verify(userData);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resendEmail = createAsyncThunk(
  "auth/resendEmail",
  async (userData, thunkAPI) => {
    try {
      const data = await authService.resendEmail(userData);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const makePayments = createAsyncThunk(
  "auth/makePayments",
  async (cardDetails, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await authService.makePayments(cardDetails, token);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDetails = createAsyncThunk(
  "auth/getDetails",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await authService.getDetails(token);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "auth/getAllUsers",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await authService.getAllUsers(token);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePhoto = createAsyncThunk(
  "auth/updatePhoto",
  async (photo, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await authService.updatePhoto(photo, token);

      // Check if status is false
      if (data.status === false) {
        // If status is false, return an error
        return thunkAPI.rejectWithValue(data.message);
      }

      // If status is true, return the data
      return data;
    } catch (error) {
      // Handle other errors
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "auth/updatePassword",
  async (user, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await authService.updatePassword(user, token);
      if (data?.status === false) {
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (user, thunkAPI) => {
    try {
      const data = await authService.forgotPassword(user);
      if (data.status === false) {
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const confirmPassword = createAsyncThunk(
  "auth/confirmPassword",
  async (user, thunkAPI) => {
    try {
      const data = await authService.confirmPassword(user);
      if (data.status === false) {
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const confirmEmail = createAsyncThunk(
  "auth/confirmEmail",
  async (user, thunkAPI) => {
    try {
      const data = await authService.confirmEmail(user);
      if (data.status === false) {
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateEmail = createAsyncThunk(
  "auth/updateEmail",
  async (user, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await authService.updateEmail(user, token);
      if (data.status === false) {
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateUserWithSignalR = createAsyncThunk(
  async (user, thunkAPI) => {
    try {
      return user;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePersonalInfo = createAsyncThunk(
  "auth/updatePersonalInfo",
  async (user, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await authService.updatePersonalInfo(user, token);
      if (data.status === false) {
        return thunkAPI.rejectWithValue(
          data.message || "Failed to update photo"
        );
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateAccountInfo = createAsyncThunk(
  "auth/updateAccountInfo",
  async (user, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await authService.updateAccountInfo(user, token);
      if (data.status === false) {
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateAboutInfo = createAsyncThunk(
  "auth/updateAboutInfo",
  async (user, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await authService.updateAboutInfo(user, token);
      if (data.status === false) {
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
  try {
    const data = await authService.login(user);
    return data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const update = createAsyncThunk("auth/upate", async (user, thunkAPI) => {
  try {
    const data = await authService.update(user);
    return data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const logout = createAsyncThunk("auth/logout", async () => {
  await authService.logout();
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.user = action.payload;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(verify.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "verified";
        state.user = null;
      })
      .addCase(verify.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(resendEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resendEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "";
        state.user = action.payload;
      })
      .addCase(resendEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = null;
        state.token = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(update.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(update.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(updatePersonalInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePersonalInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Personal Info Updated Successfully";
      })
      .addCase(updatePersonalInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateAccountInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAccountInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Account Info Updated Successfully";
      })
      .addCase(updateAccountInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateAboutInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAboutInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "About Info Updated Successfully";
      })
      .addCase(updateAboutInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(getDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "";
        state.users = action.payload;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updatePhoto.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePhoto.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "";
      })
      .addCase(updatePhoto.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(updatePassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Password Updated";
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Password reset sent";
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(confirmPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(confirmPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Password confirmed";
      })
      .addCase(confirmPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(confirmEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(confirmEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Email confirmed";
      })
      .addCase(confirmEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Email Sent";
      })
      .addCase(updateEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateUserWithSignalR.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserWithSignalR.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(updateUserWithSignalR.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(makePayments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(makePayments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "payment success";
      })
      .addCase(makePayments.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.token = null;
        state.users = null;
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
