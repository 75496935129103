import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { Button, Typography } from "antd";
import Colors from "../../helpers/Colors";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "../../features/auth/authSlice";
import { ClipLoader } from "react-spinners";
import { termsOfCondition } from "../../features/tos/tosSlice";

const AcceptPopModal = () => {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const { tos, isError, isSuccess, message } = useSelector(
    (state) => state.tos
  );
  const [Error, setError] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isError) {
      setError(message);
      setLoading(false);
    }

    if (isSuccess && message === "terms accepted") {
      setError("");
      handleClose();
      setLoading(false);
    }

    // Cleanup function
    return () => {
      // Reset state on component unmount as well
      dispatch(reset());
    };
  }, [isError, isSuccess, dispatch, message]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    setLoading(true);
    dispatch(termsOfCondition());
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="terms-and-conditions-title"
      aria-describedby="terms-and-conditions-content"
    >
      <div
        className="sm:w-[30%] w-[80%]"
        style={{
          position: "absolute",
          top: "20%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "8px",
          // maxWidth: "80%",
        }}
      >
        {Error && <Typography className="text-red-500">{Error}</Typography>}
        <Typography
          variant="h6"
          id="terms-and-conditions-title"
          gutterBottom
          className="text-[14px] font-semibold"
        >
          תנאים והגבלות
        </Typography>
        <Typography
          variant="body1"
          id="terms-and-conditions-content"
          className="text-[12px] font-semibold"
          gutterBottom
        >
          קבל את התנאים וההגבלות לשליחה וקבלת הודעות
        </Typography>

        <div className="space-x-4 mt-3" style={{ textAlign: "center" }}>
          <button
            variant="contained"
            size="small"
            style={{ background: Colors.secondary }}
            color="primary"
            className="px-3 py-1 text-[10px] rounded-md text-white font-bold"
            disableElevation
            onClick={handleAgree}
          >
            {loading ? (
              <ClipLoader
                color={Colors.white}
                loading={loading}
                size={10}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              "Agree"
            )}
          </button>
          <button
            onClick={handleClose}
            variant="contained"
            size="small"
            className="px-3 py-1 text-[10px] bg-gray-100 rounded-md text-[#000] font-bold"
            color="primary"
            disableElevation
          >
            Decline
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AcceptPopModal;
