import React, { useEffect, useState } from "react";
import GeneralNavbar from "../../components/Navbars/GeneralNavbar";
import { Box, Grid } from "@mui/material";
import { Carousel, Typography } from "antd";
import CountUp from "react-countup";
import Colors from "../../helpers/Colors";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import babies from "../../assets/icons/sugarbabies.png";
import chat from "../../assets/icons/icon-messages.png";
import activeUsers from "../../assets/icons/icon-active-users.png";
import newUsers from "../../assets/icons/icon-new-users.png";
import GeneralFooter from "../../components/Navbars/GeneralFooter";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const About = () => {
  const [slidesToShow, setSlidesToShow] = useState(4);
  const { user, token } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("registration"));
    const statusRegistration = JSON.parse(
      localStorage.getItem("registration1")
    );

    if (user?.status === true || token?.status === true) {
      navigate("/main");
    }
    if (user?.status === true || token?.status === true) {
      navigate("/main");
    }
    if (userData) {
      navigate("/complete-reg", {
        state: {
          userData,
        },
      });
    }

    if (statusRegistration) {
      navigate("/verification");
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(4);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "none",
          border: "1px solid #fff",
          width: "25px",
          height: "25px",
          borderRadius: "50%",
          marginRight: 20,
          marginTop: -25,
        }}
        onClick={onClick}
      >
        <ArrowForwardIosIcon
          style={{
            color: "#fff",
            fontSize: "17px",
            marginTop: 3,
            marginLeft: 2,
          }}
        />
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "none",
          border: "1px solid #fff",
          width: "25px",
          height: "25px",
          borderRadius: "50%",
          marginLeft: 20,
          marginTop: -25,
        }}
        onClick={onClick}
      >
        <ArrowBackIosNewIcon
          style={{ color: "#fff", fontSize: "17px", marginTop: 3 }}
        />
      </div>
    );
  };

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <>
      <GeneralNavbar />

      <Box>
        <Box className="aboutBanner py-24 text-center flex flex-col items-center justify-center">
          <Box className="flex space-x-4 mt-9">
            <Box className="w-[25vh] border-[#EBD165] border-t-4"></Box>
            <Box>
              <Typography className="text-[#EBD165] mx-4 font-black text-[40px] -mt-7">
                {/* Our Vision */} החזון שלנו
              </Typography>
            </Box>
            <Box className="w-[25vh] border-[#EBD165] border-t-4"></Box>
          </Box>
          <Box className="sm:w-[55%] w-[95%] m-auto">
            <Typography className="text-white mt-3 text-[20px] ">
              {/* To allow people, regardless of who they are, the freedom of choice
              to create a relationship that is suitable for them and based on
              common interests, mutual respect, fun and happines */}
              "לאפשר לאנשים באשר הם את חופש הבחירה <br />
              לייצר מערכת יחסים שמתאימה עבורם <br />
              והמבוססת על אינטרסים משותפים, כבוד הדדי, <br />
              כיף ואושר"
            </Typography>
          </Box>

          <Box className="mt-10">
            <Link to="/register">
              <button
                className="font-bold py-3 rounded-md px-12 text-white text-[15px]"
                style={{ backgroundColor: Colors.secondary }}
              >
                {/* Free Registeration */} הרשמה חינם
              </button>{" "}
            </Link>
          </Box>
        </Box>

        <Box className="abt2">
          <Box className="py-16 sm:w-[85%] w-[95%] m-auto">
            <Grid container>
              <Grid item xs={12} sm={12} md={6}></Grid>
              <Grid item xs={12} sm={12} md={6}>
                <p>
                  בעולם הדייטנג המודרני עם אינסוף אתרים המציעים פחות או יותר את
                  אותו הדבר, אתר שוגר דדי מגדיר מחדש את הגבר העשיר המודרני.
                  <br />
                  "שוגר דדי הוא תמיד מבוגר" – הוא מיתוס בלבד. בעוד גילו של הגבר
                  העשיר הממוצע נע סביב ה 45 יש שלל גברים עשירים מעולמות ההייטק,
                  הפיננסים והנדל"ן שמתחילים להצליח כבר בגיל צעיר.
                  <br />
                  <br />
                  מה שמשותף לכל החברים שמאסו בדרכים המסורתיות ומאחד אותם הוא
                  הצורך לבנות מערכות יחסים בתנאים שלהם, בזמינות שלהם ולהשתחרר
                  מכבלי היחסים המסורתיים.
                  <br />
                  אתר שוגר דדי נחשב כבר מעל עשור הפלטפורמה היחידה המאפשרת את זה.
                  <br />
                  <br />
                  זה לא חדש שהרבה דוגמניות מצליחות מתחברות לגברים מצליחים.
                  <br />
                  טיסות במטוסים פרטיים לאי בודד זה כבר לא בגדר חלום...
                </p>
                <Link to="/register">
                  <button
                    className="font-bold py-3 rounded-md px-9  text-white text-[13px] mt-10"
                    style={{ backgroundColor: Colors.secondary }}
                  >
                    {/* Free Registeration */} הרשמה חינם
                  </button>{" "}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box className="abt-count">
          <Box className="sm:w-[85%] w-[95%] m-auto sm:py-10 py-5">
            <Carousel
              slidesToShow={slidesToShow}
              autoplay
              dots={false}
              arrows
              {...settings}
            >
              <Box className=" sm:p-10 p-5 mb-4 text-center grid place-items-center">
                <Box>
                  <img
                    src={babies}
                    alt=""
                    className="sm:w-[30%] w-[45%] m-auto"
                  />

                  <CountUp
                    end={4}
                    duration={7}
                    className="text-[40px]"
                    style={{ color: Colors.primary }}
                  />

                  <Typography className="text-white font-normal mt-2 text-[13px]">
                    {/* Sugar babies for every sugar daddy */}
                    שוגר בייביז <br />
                    על כל שוגר דדי
                  </Typography>
                </Box>
              </Box>
              <Box className=" sm:p-10 p-5 mb-4 text-center">
                <Box>
                  <img
                    src={chat}
                    alt=""
                    className="sm:w-[30%] w-[45%] m-auto"
                  />
                  <CountUp
                    end={17007998}
                    duration={7}
                    className="text-[40px]"
                    style={{ color: Colors.primary }}
                  />
                  <Typography className="text-white font-normal mt-2 text-[13px]">
                    {/* Messages between friends */}
                    הודעות בין <br />
                    חברים
                  </Typography>
                </Box>
              </Box>
              <Box className=" sm:p-10 p-5 mb-4 text-center ">
                <Box>
                  <img
                    src={activeUsers}
                    alt=""
                    className="sm:w-[30%] w-[45%] m-auto"
                  />
                  <CountUp
                    end={19998}
                    duration={7}
                    className="text-[40px]"
                    style={{ color: Colors.primary }}
                  />
                  <Typography className="text-white font-normal mt-2 text-[13px]">
                    {/* Active users */}
                    משתמשים <br />
                    פעילים
                  </Typography>
                </Box>
              </Box>
              <Box className=" sm:p-10 p-5 mb-4 text-center ">
                <Box>
                  <img
                    src={newUsers}
                    alt=""
                    className="sm:w-[30%] w-[45%] m-auto"
                  />
                  <CountUp
                    end={1200}
                    duration={7}
                    className="text-[40px]"
                    style={{ color: Colors.primary }}
                  />
                  <Typography className="text-white font-normal mt-2 text-[13px]">
                    {/* New joiners per month */}
                    מצטרפים חדשים <br />
                    בחודש
                  </Typography>
                </Box>
              </Box>
            </Carousel>
          </Box>
        </Box>
      </Box>

      <GeneralFooter />
    </>
  );
};

export default About;
