import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Lock } from "@mui/icons-material";
import AddGallery from "./AddGallery";
import { Typography } from "antd";
import Colors from "../../helpers/Colors";
import { Link } from "react-router-dom";
import { CustomTabPanel, a11yProps } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import SearchedFriends from "./SearchedFriends";
import { getFavouriteList } from "../../features/friends/friendsSlice";

export default function FavouriteListTab() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { favourites, likes } = useSelector((state) => state.friends);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getFavouriteList());
  }, []);

  return (
    <Box className="px-1 bg-white w-full">
      <Box sx={{ width: "100%" }} className="py-4">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
          className="flex  items-center justify-end"
        >
          <Tabs
            value={value}
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: "#000",
                background: "#eee",
                fontSize: "13px",
                borderRadius: "5px",
                padding: "2px 2px",
              },
              "& .MuiTabs-indicator": { bgcolor: "#eee" },
              "& .MuiTab-root": {
                color: Colors.secondary,
                fontSize: "13px",
                borderRadius: "5px",
              },
            }}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="המועדפים שלי" {...a11yProps(1)} />
            <Tab label="הם הוסיפו אותי למועדפים  " {...a11yProps(0)} />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          {favourites.length > 0 ? (
            <SearchedFriends users={favourites} type="favourite" />
          ) : (
            <Typography>אין לך חברים שמועדפים</Typography>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {likes.length > 0 ? (
            <SearchedFriends users={likes} type="favourite" />
          ) : (
            <Typography>את/ה לא נוספת/ה לרשימת המועדפים</Typography>
          )}
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
