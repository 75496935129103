import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import logo from "../../assets/images/logo.png";
import Colors from "../../helpers/Colors";
import { Typography } from "antd";
import GroupIcon from "@mui/icons-material/Group";
import EmailIcon from "@mui/icons-material/Email";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import ProfileSidebar from "./ProfileSidebar";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import CompleteRegSideBar from "./CompleteRegSidebar";
import { useSelector } from "react-redux";

const CompleteRegNavbar = () => {
  const [isSideBar, setIsSideBar] = useState(false);
  const [user, setUser] = useState();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("registration"));

    setUser(userData);
  }, []);

  const handleSideBar = () => {
    const menu = document.getElementById("menuRef");
    menu.classList.remove("menubar");
    menu.classList.add("showRight");
    setIsSideBar(!isSideBar);
  };
  const handleSideBarClose = () => {
    const menu = document.getElementById("menuRef");
    menu.classList.remove("showRight");
    menu.classList.add("menubar");
    setIsSideBar(!isSideBar);
  };

  return (
    <Box
      className="sticky top-0  z-30 right-0"
      style={{
        backgroundColor: Colors.black,
        position: "sticky !important",
        top: "0px",
        right: "0px",
        width: "100%",
      }}
    >
      <Box className=" bg-black flex items-center md:justify-normal justify-between">
        <Box className="md:w-[16%] w-[40%] px-2">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </Box>
        <Box className="md:hidden block px-2">
          <img
            src={require("../../assets/icons/vip.png")}
            alt=""
            className="w-[100%] -mt-4"
          />
        </Box>
        <Box className="w-[100%]  md:flex hidden">
          <Box className="md:flex hidden items-center w-[80%] py-2 space-x-2">
            <Box className="border-l border-gray-600 px-2 lg:block hidden">
              <Typography className="text-white text-[17px]">
                כרגע באתר 558 משתמשים אונליין
              </Typography>
            </Box>
          </Box>
          <Box className="bg-[#EBD165] w-[20%] flex ">
            <Box className="">
              <Link to={`/userProfile`}>
                <Box className=" border-r-2 border-gray-100 px-2 h-[9vh] flex items-center space-x-1">
                  <Typography className="text-white text-[14px]">
                    {user?.username || user?.email}
                  </Typography>
                  <img
                    src={require("../../assets/icons/user.png")}
                    alt=""
                    width="25%"
                  />
                </Box>
              </Link>
            </Box>
            <Box className="w-[40%]  cursor-pointer flex items-center justify-center">
              {isSideBar ? (
                <Box onClick={handleSideBarClose}>
                  <CloseIcon
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: "25px",
                    }}
                  />
                </Box>
              ) : (
                <Box onClick={handleSideBar}>
                  <MenuIcon
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: "25px",
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="md:hidden flex justify-between p-3 bg-[#EBD165]">
        <Link to="/userProfile/5">
          <Box className="  px-2  flex items-center space-x-1">
            <img
              src={require("../../assets/icons/user.png")}
              alt=""
              width="25%"
            />
            <Typography className="text-white text-[14px] font-bold">
              {user?.username || user?.email}
            </Typography>
          </Box>
        </Link>

        {/* mobile beneath */}
        <Box className="flex items-center ">
          <Box className="w-[40%]  cursor-pointer border-gray-100 px-2 flex items-center justify-center">
            {isSideBar ? (
              <Box onClick={handleSideBarClose}>
                <CloseIcon
                  style={{
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "25px",
                  }}
                />
              </Box>
            ) : (
              <Box onClick={handleSideBar}>
                <MenuIcon
                  style={{
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "25px",
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {/* Sidebar */}
      <Box className="relative menubar  border" id="menuRef">
        <CompleteRegSideBar isSidebar={isSideBar} />
      </Box>
    </Box>
  );
};

export default CompleteRegNavbar;
