import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Typography } from "antd";
import Colors from "../../helpers/Colors";
import { CustomTabPanel, a11yProps } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import {
  getFriends,
  getRequestsRecieved,
  getRequestsSent,
  reset,
} from "../../features/friends/friendsSlice";
import SearchedFriends from "./SearchedFriends";

export default function FriendListTab() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  const {
    friends,
    requestsSent,
    isLoading,
    isError,
    isSuccess,
    message,
    requestsRecieved,
  } = useSelector((state) => state.friends);

  React.useEffect(() => {
    if (isSuccess) {
      dispatch(reset());
    }
  }, [dispatch, isSuccess]);

  React.useEffect(() => {
    dispatch(getFriends());
    dispatch(getRequestsSent());
    dispatch(getRequestsRecieved());
  }, []);

  return (
    <Box className="px-1 bg-white w-full">
      {isLoading ? (
        <Box className="h-[30vh] flex flex-col items-center justify-center">
          <Loader size={50} />
        </Box>
      ) : (
        <Box sx={{ width: "100%" }} className="py-4">
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
            className="flex  items-center justify-end"
          >
            {isError && (
              <Typography sx={{ fontSize: "13px", color: "red" }}>
                {message}
              </Typography>
            )}
            <Tabs
              value={value}
              sx={{
                "& .MuiTab-root.Mui-selected": {
                  color: "#000",
                  background: "#eee",
                  fontSize: "13px",
                  borderRadius: "5px",
                  padding: "2px 2px",
                },
                "& .MuiTabs-indicator": { bgcolor: "#eee" },
                "& .MuiTab-root": {
                  color: Colors.secondary,
                  fontSize: "13px",
                  borderRadius: "5px",
                },
              }}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="בקשות חברות ששלחתי" {...a11yProps(2)} />
              <Tab label="בקשות חברות" {...a11yProps(1)} />
              <Tab label="רשימת חברים" {...a11yProps(0)} />
            </Tabs>
          </Box>
          {/* requestSent */}
          <CustomTabPanel value={value} index={0}>
            {requestsSent.length > 0 ? (
              <SearchedFriends users={requestsSent} type="sent" />
            ) : (
              <Typography>את/ה לא שלחת בקשות חברות</Typography>
            )}
          </CustomTabPanel>
          {/* FriendsList */}
          <CustomTabPanel value={value} index={2}>
            {friends?.length > 0 ? (
              <SearchedFriends users={friends} />
            ) : (
              <Typography>אין לך חברים</Typography>
            )}
          </CustomTabPanel>
          {/* requestRecieved */}
          <CustomTabPanel value={value} index={1}>
            {requestsRecieved?.length > 0 ? (
              <SearchedFriends users={requestsRecieved} type="recieved" />
            ) : (
              <Typography>אין לך בקשות חברות שהתקבלו</Typography>
            )}
          </CustomTabPanel>
        </Box>
      )}
    </Box>
  );
}
