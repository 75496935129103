import React, { useEffect, useState } from "react";
import AuthNavbar from "../../components/Navbars/AuthNavbar";
import AuthFooter from "../../components/Navbars/AuthFooter";
import { Box, Grid } from "@mui/material";
import { Typography, Button } from "antd";
import RegisterForm from "./RegisterForm";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import Colors from "../../helpers/Colors";
import { confirmPassword, forgotPassword } from "../../features/auth/authSlice";

const EnterNewPassword = () => {
  const [password, setPassword] = useState("");
  const {
    user,
    token: authToken,
    isLoading,
    isSuccess,
    isError,
    message,
  } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userId, token } = useParams();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("registration"));
    const statusRegistration = JSON.parse(
      localStorage.getItem("registration1")
    );

    if (user?.status === true || authToken?.status === true) {
      navigate("/main");
    }
    if (userData) {
      navigate("/complete-reg", {
        state: {
          userData,
        },
      });
    }

    if (statusRegistration) {
      navigate("/verification");
    }
  }, []);

  const handleSubmit = () => {
    dispatch(
      confirmPassword({ password, userId, token: decodeURIComponent(token) })
    );
  };

  useEffect(() => {
    if (isSuccess && message == "Password confirmed") {
      alert("הסיסמה אופסה בהצלחה.");
      navigate("/login");
      setPassword("");
    }

    if (isError) {
      alert("Something went wrong");
    }
  }, [isSuccess, isError, message]);

  return (
    <>
      <AuthNavbar />
      <Box className="sm:h-screen h-screen sm:py-0 py-16 px-5  fp-bg">
        <Grid container>
          <Grid item xs={12} sm={12} md={6}></Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box className="mt-5">
              <Typography className="text-white">
                לשחזר סיסמה, הזן את האימייל שנרשמת עליו!
              </Typography>
              <Box className="mt-12">
                <Typography className="text-white w-[30%]">אמייל:</Typography>
                <input
                  type="email"
                  name="email"
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={(e) => setPassword(e.target.value)}
                  className="sm:w-[75%] w-[100%] mt-2 sm:py-1.5 py-3 border-none px-5 text-[13px]"
                  placeholder="אנא הזן את הסיסמה החדשה שלך...."
                />
              </Box>
            </Box>
            <button
              type="submit"
              onClick={handleSubmit}
              disabled={!password ? true : false || isLoading}
              className="bg-[#D71D6D] mt-3 hover:text-[#EBD165] text-[11px]  hover:bg-[#EBD165] border-none px-6 text-white rounded-md sm:py-1.5 py-2 reg-btn">
              {isLoading ? (
                <ClipLoader
                  color={Colors.white}
                  loading={isLoading}
                  size={10}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                "התחבר"
              )}
            </button>
          </Grid>
        </Grid>
      </Box>
      <AuthFooter />
    </>
  );
};

export default EnterNewPassword;
