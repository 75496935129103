import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "antd";
import GroupIcon from "@mui/icons-material/Group";
import { useDispatch, useSelector } from "react-redux";
import Colors from "../../helpers/Colors";
import {
  clearNotificationLRequest,
  clearNotificationLikes,
} from "../../features/notifications/notificationsSlice";
import { useNavigate } from "react-router-dom";

export default function NotificationMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseLike = () => {
    setAnchorEl(null);
    dispatch(clearNotificationLikes());
    navigate("/userProfile/favouriteList");
  };

  const handleCloseReqest = () => {
    setAnchorEl(null);
    dispatch(clearNotificationLRequest());
    navigate("/userProfile/friendList");
  };

  const handleCloseMsg = () => {
    setAnchorEl(null);

    navigate("/mailbox/inbox");
  };
  const {
    likeNotifications,
    friendsRequestNotifications,
    friendsAcceptedNotifications,
    messageNotifications,
  } = useSelector((state) => state.notifications);
  const { user } = useSelector((state) => state.auth);

  const notificationMatchingUserId = friendsRequestNotifications?.filter(
    (item) => user?.data?.username == item?.to?.username
  );

  const notificationMatchingUserIdAccepted =
    friendsAcceptedNotifications?.filter(
      (item) => user?.data?.username == item?.from?.username
    );

  const msgNotifications = messageNotifications?.filter(
    (item) => user?.data?.id !== item.fromId
  );

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <GroupIcon style={{ color: "#fff", fontSize: "24px" }} />
        <Typography
          className="text-white  rounded-full text-[8px] absolute font-bold top-0.5 right-3 bg-[#D71D6D]"
          style={{ padding: "2px 5px" }}
        >
          {likeNotifications?.length +
            notificationMatchingUserId?.length +
            notificationMatchingUserIdAccepted?.length +
            msgNotifications.length || 0}
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* Friend request */}
        {notificationMatchingUserId?.length > 0 && (
          <MenuItem
            onClick={handleCloseReqest}
            style={{ color: Colors.secondary }}
          >
            <span> בקשות חברות:</span>{" "}
            <span>{notificationMatchingUserId?.length}</span>
          </MenuItem>
        )}

        {likeNotifications?.length > 0 && (
          <MenuItem
            onClick={handleCloseLike}
            className="space-x-2"
            style={{ color: Colors.secondary }}
          >
            <span>כמו אתה:</span> <span>{likeNotifications?.length}</span>
          </MenuItem>
        )}

        {notificationMatchingUserIdAccepted?.length > 0 && (
          <MenuItem
            onClick={handleCloseReqest}
            className="space-x-2"
            style={{ color: Colors.secondary }}
          >
            <span> חברים שהתקבלו:</span>{" "}
            <span>{notificationMatchingUserIdAccepted?.length}</span>
          </MenuItem>
        )}

        {msgNotifications?.length > 0 && (
          <MenuItem
            onClick={handleCloseMsg}
            className="space-x-2"
            style={{ color: Colors.secondary }}
          >
            <span> חברים שהתקבלו:</span> <span>{msgNotifications?.length}</span>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
