import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import service from "./galleryService";
import { logoutError } from "../auth/authService";
const gallery = JSON.parse(localStorage.getItem("userPhotos"));

const initialState = {
  gallery: gallery ? gallery : [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getGallery = createAsyncThunk(
  "gallery/getGallery",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;
      const data = await service.getGallery(token, id);
      if (data.status === false) {
        // If status is false, return an error
        return thunkAPI.rejectWithValue(data.message);
      }
      return data.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addGallery = createAsyncThunk(
  "gallery/addGallery",
  async (info, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;

      const data = await service.addGallery(info, token);

      if (data.status === false) {
        // If status is false, return an error
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteGallery = createAsyncThunk(
  "gallery/deleteGallery",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token.data.token;

      const data = await service.deleteGallery(id, token);
      if (data.status === false) {
        // If status is false, return an error
        return thunkAPI.rejectWithValue(data.message);
      }
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateGalleryWithSignalR = createAsyncThunk(
  "gallery/updateGalleryWithSignalR",
  async (data, thunkAPI) => {
    try {
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (
        error.response &&
        (error.response.status === 403 || error.response.status === 401)
      ) {
        logoutError();
        window.location.href = "/login";
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getGallery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGallery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.gallery = action.payload;
      })
      .addCase(getGallery.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addGallery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addGallery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(addGallery.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateGalleryWithSignalR.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateGalleryWithSignalR.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.gallery = action.payload;
      })
      .addCase(updateGalleryWithSignalR.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteGallery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteGallery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "photo deleted";
      })
      .addCase(deleteGallery.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = gallerySlice.actions;
export default gallerySlice.reducer;
